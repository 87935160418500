<p class="text-center mt-2">
    You are skipping your next delivery <b>{{deliveryDate()}}</b>. 
    If you would like to pause for more than one delivery day, please choose the option to
    <a href="javascript:void(0)" class="link-primary" (click)="confirmModalButtonsAction(false, true)">pause subscriptions</a></p>
<div class="row mt-5 gap-2">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
        <button type="button" class="btn w-100 btn-outline-primary"
            (click)="confirmModalButtonsAction(false)">Cancel</button>
    </div>
    <div class="col">
        <button type="button" class="btn w-100 btn-primary"
            (click)="confirmModalButtonsAction(true)">Skip This Week</button>
    </div>
</div>