import { Component, inject, input, InputSignal, signal, WritableSignal } from '@angular/core';
import { MatRadioModule } from '@angular/material/radio';
import { AvailableDates } from '../modal-content';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-subscription-next-delivery',
  standalone: true,
  imports: [MatRadioModule],
  templateUrl: './subscription-next-delivery.component.html',
  styleUrl: './subscription-next-delivery.component.scss'
})
export class SubscriptionNextDeliveryComponent {

  #activeModal = inject(NgbActiveModal);

  selectedDate: WritableSignal<string> = signal('');
  availableDates = input<AvailableDates[]>([]);

  closeModal(confirm: boolean) {
    this.#activeModal.close({ confirm, date: this.selectedDate() || this.availableDates()[0]?.originalDate || '' })
  }

}
