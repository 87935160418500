@if (products().length) {
  @if (!isMobile()) {
    <div class="row mt-4 animated fadeIn">
      <div class="col table-responsive table-container overflow-y-auto"> 
          <table class="table align-middle table-striped table-sm">
            <thead class="sticky-top">
              <tr>
                <th class="text-body-tertiary border-0" scope="col">
                  <mat-checkbox
                      [checked]="allSelected"
                      (change)="setAll($event.checked)">
                  </mat-checkbox>
                </th>
                <th class="border-0 align-middle w-25 text-body-tertiary" scope="col">Product name</th>
                <th class="border-0 align-middle text-body-tertiary" scope="col">Variant</th>
                <th class="border-0 align-middle text-body-tertiary" scope="col">Quantity</th>
                <th class="border-0 align-middle text-body-tertiary" scope="col">Price</th>
                </tr>
            </thead>
            <tbody class="">
                @for (product of products(); track $index) {
                  @if (!product.isSubscription) {
                    <tr class="fw-semibold" [class.noStock]="!product.hasStock">
                      <td>
                        @if (product.hasStock) {
                          <mat-checkbox 
                            [(ngModel)]="product.selected"
                            (ngModelChange)="updateSelection()">
                          </mat-checkbox>
                        }
                      </td>
                      <td>
                        <b>{{product.name}}</b>
                      </td>
                      <td>
                        <span class="fw-normal"><b>{{product.attributes[0].name? product.attributes[0].name + ':' : ''}}</b> {{product.attributes[0].value.name || ''}}</span>
                      </td>
                      <td>
                        <span class="fw-normal">{{product.quantity.requested}}</span>
                      </td>
                      <td>
                        <b>{{product.hasStock ? (product.unitPrice | currency) : 'Out of stock'}}</b>
                      </td>
                    </tr>
                  }
                }
            </tbody>
          </table>
      </div>
  </div>
  } @else {
    <a 
        href="javascript:void(0)" 
        class="link mt-4 animated fadeInRight"
        (click)="setAll(!allSelected)">
        {{allSelected ? 'Deselect All' : 'Select All'}}
    </a>
    <div class="mt-2 vstack gap-2 animated fadeIn">
      @for (product of products(); track $index) {
        @if (!product.isSubscription) {
          <section 
            class="row align-items-center" 
            [class.bg-body-tertiary]="!product.hasStock"
            >
            <section class="col-7">
              <mat-checkbox 
                [disabled]="!product.hasStock"
                [(ngModel)]="product.selected"
                (ngModelChange)="updateSelection()">
                <section class="vstack">
                  <b>{{product.name}}</b>
                  <small>{{product.attributes[0].value.name || ''}}</small>
                </section>
              </mat-checkbox>
            </section>
            <section class="col-5">
              <section class="hstack">
                <section [class.text-body-tertiary]="!product.hasStock">
                  x{{product.quantity.requested}}
                </section>
                <section class="ms-auto" [class.text-body-tertiary]="!product.hasStock">
                  <span>{{product.hasStock ? (product.unitPrice | currency) : 'Out of stock'}}</span>
                </section>
              </section>
            </section>
          </section>
        }
      }
    </div>
  }
  <div class="d-grid gap-2 col-6 mx-auto mt-4 animated fadeInUp">
    <button 
      type="button"
      (click)="sendItems()"
      class="btn btn-primary d-flex gap-2 align-items-center justify-content-center">
      Add
    </button>
  </div>
}
