import { Injectable, WritableSignal, inject, signal } from "@angular/core";
import { ApiService } from "../../../shared/api.service";
import { filter, from, map, of, tap } from "rxjs";
import { RequestHandlerParams } from "../../../shared/types/api-service.types";
import { LocalStorageService } from "../../../shared/local-storage.service";
import { LOCALSTORAGE_KEYS } from "../../../shared/constants/databases";
import { Session } from "../../../shared/types/session.type";
import { ApiResponse } from "../../../shared/common/types";
import { AvailableDeliveryDates, AvailableDeliveryDatesResponse, DeliveryInformation, FormattedDeliveryInformation, UpdateDeliveryInfoPayload } from "./intarfaces";
import { NotificationService } from "../../../shared/notification/notification.service";
import { formatDateToReadableString } from "../../../shared/utils/formatting";
import { SettingsService } from "../../settings.service";

@Injectable({
  providedIn: 'root'
})
export class DeliveriesService {
  private apiService = inject(ApiService);
  private localStorageService = inject(LocalStorageService);
  private notificationService = inject(NotificationService);
  #settingsService = inject(SettingsService);

  deliveryZoneInfo: WritableSignal<FormattedDeliveryInformation | null> = signal(null);
  availableDeliveryDates = signal<AvailableDeliveryDates[]>([]);
  defaultDeliveryDate = signal('');

  private endpoints = {
    deliveryZone: '/account/delivery-info',
    availableDeliveryDates: '/account/delivery-dates-week'
  }

  getDeliveryZoneInfo() {
    const params: RequestHandlerParams = {
      method: 'GET',
      endpoint: this.endpoints.deliveryZone,
      apiV3: true
    };

    return this.apiService.handleRequest<ApiResponse<DeliveryInformation>>(params).pipe(
      filter((response) => !!response?.data),
      tap((response) => this.setUpDeliveryInfoResponse(response.data)),
    );
  }

  private setUpDeliveryInfoResponse(delivery: DeliveryInformation) {
    const currentSessionInfo: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION);
    if (currentSessionInfo) {
      const newSessionInfo: Session = { ...currentSessionInfo, deliveryInfo: delivery }
      this.localStorageService.set(LOCALSTORAGE_KEYS.SESSION, newSessionInfo);
    }
    let formattedDelivery: FormattedDeliveryInformation = {
      ...delivery,
      orderCutoffExceeded: delivery.orderCutoffExceeded ? {
        ...delivery.orderCutoffExceeded,
        _readableDeliveryDate: formatDateToReadableString(delivery.orderCutoffExceeded.deliveryDate),
      } : null,
      orderRescheduled: delivery.orderRescheduled?.map(o => {
        return {
          ...o,
          _readablePrevDeliveryDate: formatDateToReadableString(o.prevDeliveryDate),
          _readableNewDeliveryDate: formatDateToReadableString(o.newDeliveryDate)
        }
      }) ?? []
    };
    formattedDelivery.deliveryWindowWithoutDay = this.setUpDeliveryWindowWithoutDay(delivery);
    formattedDelivery.readeableDeliveryDate = formatDateToReadableString(formattedDelivery.deliveryDate).readableString;
    this.deliveryZoneInfo.set(formattedDelivery);
    this.defaultDeliveryDate.set(delivery.deliveryDate);
  }

  private setUpDeliveryWindowWithoutDay(deliveryInfo: DeliveryInformation): string {
    const deliveryWindow = deliveryInfo?.deliveryWindow.toLowerCase() || null;
    if (!deliveryWindow) return '';
    const windowWithoutDay = deliveryWindow.substring(deliveryInfo.deliveryDay.length).trim();
    return `${windowWithoutDay.charAt(0).toUpperCase()}${windowWithoutDay.slice(1)}`;
  }

  updateDeliveryInfo(body: UpdateDeliveryInfoPayload) {
    this.#settingsService.setIsWaitingForAPIResponse(true);
    const params: RequestHandlerParams = {
      method: 'PUT',
      endpoint: this.endpoints.deliveryZone,
      apiV3: true,
      body
    };

    return this.apiService.handleRequest<ApiResponse<DeliveryInformation>>(params).pipe(
      tap(() => this.#settingsService.setIsWaitingForAPIResponse(false)),
      filter((response) => !!response?.data),
      tap((response) => {
        this.notificationService.show({ text: response.message, type: 'success' });
        this.setUpDeliveryInfoResponse(response.data);
      }),
    )
  }

  getAvailableDeliveryDates() {

    const params: RequestHandlerParams = {
      method: 'GET',
      endpoint: this.endpoints.availableDeliveryDates,
      apiV3: true
    };

    this.apiService
      .handleRequest<AvailableDeliveryDatesResponse>(params)
      .pipe(
        filter(response => response.data.length > 0),
        map((response): AvailableDeliveryDates[] => {

          return response.data
            .sort((a, b) => +a.date.replaceAll('-', '') < +b.date.replaceAll('-', '') ? -1 : 1)
            .map(({ date, isSkipped, orderId }) => {

              const { dayName, readableString, month, day } = formatDateToReadableString(date)

              return {
                date: `${dayName}, ${readableString}`,
                mobileDate: `${dayName}, ${month} ${day}`,
                originalDate: date,
                isSkipped,
                isSelected: false,
                isAdded: false,
                orderId
              };
            });
        }),
        tap(response => {
          this.availableDeliveryDates.set(response);
        })
      )
      .subscribe();
  }
}
