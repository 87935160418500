@if (card().product) {
<div class="product-card" (click)="subscribeAndcustomizeBox ? flipCardType(cardTypes.subscribe) : null"
  (mouseenter)="showText = true" (mouseleave)="showText = false"
  [class.product-card--subscription]="cardType() == cardTypes.subscribe"
  [class.product-card--add]="cardType() == cardTypes.add"
  [class.product-card--check-address]="cardType() == cardTypes.checkAddress" [class.seasonal-product]="true">

  <div class="product-card__body">

    @switch (cardType()) {
    @case (cardTypes.summary) {
    <section class="animated fadeIn d-flex flex-column flex-grow-1">
      <!-- Card Header -->
      <div class="ps-0 pt-1 bg-transparent" [ngClass]="{'active-filter-blur': isOutOfStock()}">
        <p class="mb-1 text-body-secondary">Summary</p>
        <p class="fw-bold">{{ card().product?.name }}</p>
      </div>

      @if (isOutOfStock() || isOutOfStockPerQuantity()) {
      <app-out-of-stock [isOutOfStockPerQuantity]="isOutOfStockPerQuantity()" [isOutOfStock]="isOutOfStock()"
        [hasNotify]="hasNotify" [isFromSummary]="true" (outFlipCardType)="flipCardType($event)"
        (outModalCheckAddress)="openModalCheckAddress()"></app-out-of-stock>
      }
      <section [class.d-none]="isOutOfStock()">
        <div class="card-body p-0 mt-2">
          <div class="row">
            @if (isGiftCard()) {
            <div class="col-lg-6 col-md-12">
              <p class="m-0 h7-semibold">Amount:</p>
            </div>
            }
            <div [class.col-md-12]="isGiftCard()" [class.col-lg-6]="isGiftCard()" [class.col-12]="!isGiftCard()">
              @if (productAttributes.length) {
              @for (attr of productAttributes; track $index) {
              <mat-form-field class="rounded-selector" subscriptSizing="dynamic">
                <mat-label class="text-capitalize">{{attr.name.toLowerCase()}}</mat-label>
                <mat-select [value]="selectedAttribute()?.value?.id || ''"
                  (selectionChange)="onAttributeChange(attr.id, $event)">
                  @for (item of attr.values; track $index) {
                  <mat-option [value]="item.id">{{item.name}}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
              }
              }
              @if (card().product?.packages?.length) {
              <mat-form-field class="rounded-selector" subscriptSizing="dynamic">
                <mat-label class="text-capitalize">Package</mat-label>
                <mat-select [value]="selectedPackage()?.id || ''" (selectionChange)="onPackageChanged($event.value)">
                  @for (item of card().product?.packages; track $index) {
                  <mat-option [value]="item.id">{{item.name}}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
              }
            </div>

          </div>

          <!-- Quantity buttons -->
          @if (!isGiftCard()) {
          <div class="row row-cols-2 d-flex justify-content-end align-items-center mt-2">
            <div class="col-7">
              <div class="card-price d-flex gap-1 flex-row align-items-center">
                <h5 class="card-title mb-0">{{ (selectedAttributeCardConfig().price) | currency}}</h5>
                @if(selectedAttributeCardConfig().hasDiscount) {
                <small class="text-body-tertiary fw-bold text-decoration-line-through">
                  {{selectedAttributeCardConfig().originalPrice | currency}}
                </small>
                }
              </div>
            </div>
            <div class="col-5 quantity">
              @if (wasValueHigher10) {
              <small class="how-many-label d-none d-sm-block">How many?</small>
              }
              <mat-form-field class="rounded-selector d-flex" subscriptSizing="dynamic">
                <mat-label>Quantity</mat-label>
                @if( wasValueHigher10 || newQuantity() >= 10 || (newQuantity() >= 10 &&
                wasValueHigher10) ){
                <input type="text" matInput appNumberRestriction [minNumber]="1" [maxNumber]="totalStockAvailable()"
                  (change)="onInputQuantityChange($event)" (keyup)="onKeyUp($event)" [value]="newQuantity()">
                }
                @else{
                <mat-select [disabled]="!!productInCart()" [value]="newQuantity()"
                  (selectionChange)="onQuantityChange($event)">
                  @for(q of availableQuantities(); track $index){
                  <mat-option [value]="q.val">{{ q.name }}</mat-option>
                  }
                </mat-select>
                }
              </mat-form-field>
            </div>
          </div>
          }

          <hr class="border-2 border-black mb-0">

          <!-- Total calculates -->
          <div class="row row-cols-2 mt-0 d-flex justify-content-between align-items-center">
            <div class="quantity">
              <h5>Total</h5>
            </div>
            <div class="card-price text-right">
              <h5 class="card-title">{{ summaryTotalPrice() | currency }}</h5>
            </div>
          </div>
        </div>
      </section>

      <!-- Action button -->
      @if (isGiftCard()) {
      <button (click)="purchaseGiftCardClicked()" class="btn btn-primary mt-2">Purchase</button>
      } @else {
      @if (!(!isOutOfStockPerQuantity() && hasNotify)) {
      @if(!customizeBox) {
      <div class="product-card__action">
        <div class="d-grid gap-2">
          @if (signalsStoreService.hasSession()) {
          @if ((selectedAttributeCardConfig().isSubscription)) {
          @if (card().product?.bundle?.items?.length) {
          <div [class.action-col-2]="marketStatus().isOpen && selectedAttributeCardConfig().isALaCarte">
            @if(stockSignal().size) {
            @if (marketStatus().isOpen && selectedAttributeCardConfig().isALaCarte) {
            <button type="button" (click)="flipCardType(cardTypes.add)" [disabled]="isDisabledActionButtons()"
              class="btn btn-outline-secondary d-flex gap-2 align-items-center">
              @if (isMobile()) {
              <i class="fas fa-plus"></i>
              } @else {
              Add
              }
            </button>
            }
            <button type="button" [disabled]="isDisabledSubscribeButton()" (click)="flipCardType(cardTypes.subscribe)"
              class="btn btn-secondary d-flex gap-2 align-items-center">
              {{getButtonText({isBundle: true, isSubscription: true})}}
            </button>
            } @else {
            <p class="placeholder-glow mb-0 w-100">
              <span
                class="placeholder w-100 col-12 bg-secondary rounded btn btn-secondary d-flex gap-2 align-items-center"
                style="height: 36px;"></span>
            </p>
            }
          </div>
          } @else {
          <div class="row">
            @if(stockSignal().size) {
            @if (marketStatus().isOpen && selectedAttributeCardConfig().isALaCarte) {
            <div class="col">
              <button type="button" (click)="flipCardType(cardTypes.add)" [disabled]="isDisabledActionButtons()"
                class="btn btn-outline-secondary d-flex gap-2 align-items-center">
                @if (isMobile()) {
                <i class="fas fa-plus"></i>
                } @else {
                Add
                }
              </button>
            </div>
            }
            <div class="col">
              <button type="button" [disabled]="isDisabledSubscribeButton()" (click)="flipCardType(cardTypes.subscribe)"
                class="btn btn-secondary d-flex gap-2 align-items-center">
                {{getButtonText({isBundle: false, isSubscription: true})}}
              </button>
            </div>
            } @else {
            <p class="placeholder-glow mb-0 w-100">
              <span
                class="placeholder w-100 col-12 bg-secondary rounded btn btn-secondary d-flex gap-2 align-items-center"
                style="height: 36px;"></span>
            </p>
            }
          </div>
          }
          } @else {
          @if(stockSignal().size && selectedAttributeCardConfig().isALaCarte) {
          <button type="button"
            (click)="card().product?.preOrder ? addProductToCart(false, $event) : flipCardType(cardTypes.add)"
            [disabled]="isDisabledActionButtons() || !marketStatus().isOpen"
            class="btn btn-secondary d-flex gap-2 align-items-center"
            [class.btn-outline-secondary]="isPreOrderedVariant()">
            {{getButtonText({isBundle: false, isSubscription: false})}}
          </button>
          } @else {
          <p class="placeholder-glow mb-0 w-100">
            <span
              class="placeholder w-100 col-12 bg-secondary rounded btn btn-secondary d-flex gap-2 align-items-center"
              style="height: 36px;"></span>
          </p>
          }
          }
          } @else {
          <button (click)="openModalCheckAddress()" class="btn btn-secondary d-flex gap-2 align-items-center">
            Add To Delivery
          </button>
          }
        </div>
      </div>
      }
      }
      }
    </section>
    }
    @case (cardTypes.main) {
    <section class="animated fadeIn d-flex flex-column flex-grow-1">

      <div class="product-card__pre-head" [ngClass]="{'card-img active-filter-blur': isOutOfStock()}">
        <!-- Tags Icons -->
        <ul class="product-card__tags">
          @for (tag of card().product?.tags || []; track $index) {
          <li>
            @if (tag.icon) {
            <img src="{{tag.icon}}" [alt]="tag.name" [ngbTooltip]="tag.name" />
            } @else {
            <span class="material-symbols-outlined" [ngbTooltip]="tag.name">
              info
            </span>
            }
          </li>
          }
        </ul>
        <!-- Fav Icon -->
        @if(!isSignupFlow() && signalsStoreService.hasSession()) {
        <label [for]="'isFavorite'+card().product?.id" class="custom-checkbox"
          [ngClass]="{'card-img active-filter-blur': isOutOfStock()}">
          <input type="checkbox" [id]="'isFavorite'+card().product?.id" [disabled]="isDisabledActionButtons()"
            (change)="handleFavoriteChange($event)" [checked]="isFavorite()" />
          <i class="fa-heart fa-regular text-danger"></i>
          <i class="fa-heart fa-solid text-danger"></i>
        </label>
        }
      </div>
      <!-- Card Header - Title and Subtitle -->
      <div class="product-card__head" [ngClass]="{'card-img active-filter-blur': isOutOfStock()}">
        @if(!customizeBox) {
        <a [routerLink]="['/shop/'+card().product?.productUrl]">
          <h3 class="product-card__title" [ngbTooltip]="card().product?.name">{{card().product?.name}}</h3>
        </a>
        } @else {
        <a>
          <h3 class="product-card__title" [ngbTooltip]="card().product?.name">{{card().product?.name}}</h3>
        </a>
        }
        <h5 class="product-card__subtitle">{{ card().product?.producer?.name}}</h5>
      </div>

      <div class="product-card__img-wrap" [ngClass]="{'card-img active-filter-blur': isOutOfStock()}">
        <!-- Sale Badge -->
        @if(selectedAttributeCardConfig().hasDiscount && selectedAttributeCardConfig().isOnSale) {
        <span class="sale-badge">{{isMobile() ? 'Sale' : '% On Sale'}}</span>
        }

        @if(!isSignupFlow()) {
        <a [routerLink]="['/shop/'+card().product?.productUrl]">
          @defer (on viewport; prefetch on immediate) {
          <img [src]="selectedAttributeCardConfig().image || 'assets/images/product-placeholder.webp'"
            (error)="handleImageError($event)" class="product-card__img">
          } @placeholder {
          <div style="height: 150px;"></div>
          }
        </a>
        } @else {
        <a>
          @defer (on viewport; prefetch on immediate) {
          <img [src]="selectedAttributeCardConfig().image || 'assets/images/product-placeholder.webp'"
            (error)="handleImageError($event)" class="product-card__img">
          } @placeholder {
          <div style="height: 150px;"></div>
          }
        </a>
        }

        <!-- Hovered Button over main image -->
        @if (!isSignupFlow()) {
        @if (card().product?.bundle?.items?.length) {
        <div class="info-badge">
          <button class="rolling-info"
            [class.active]="showText || (selectedAttributeCardConfig().isSubscription && existsInOrder().subscription && bundleModifyType === bundleModifyTypes.outOfOrder)"
            (click)="openModalWhatsInside(card().product, $event)">
            @if( selectedAttributeCardConfig().isSubscription && existsInOrder().subscription &&
            bundleModifyType === bundleModifyTypes.outOfOrder) {
            <i class="rolling-info-icon fas fa-check"></i>
            <span class="rolling-info__text" [class.active]="showText">
              Subscribed
            </span>
            }
            @else {
            <i class="rolling-info-icon fas fa-info-circle"></i>
            <span class="rolling-info__text" [class.active]="showText">
              What's inside
            </span>
            }
          </button>
        </div>
        }

        @if (!marketStatus().isOpen && !selectedAttributeCardConfig().isSubscription) {
        <div class="d-grid position-absolute bottom-0 pb-3 w-100">
          <div class="mb-0 w-100 alert py-2 px-3 alert-warning d-flex align-items-center animated fadeIn" role="alert">
            @if (isMobile()) {
            <div class="h8-regular text-center">
              Available <b>on {{marketStatus().openingDay | titlecase}}</b>
            </div>
            } @else {
            <div class="h7-regular">
              You'll be <b>able to add</b> items to your cart on <b>{{marketStatus().openingDay |
                titlecase}}</b> Morning
              <!-- <b>{{marketStatus().openingHour}}</b>-->
            </div>
            }
          </div>
        </div>
        }
        }
      </div>

      @if (isOutOfStock() || isOutOfStockPerQuantity()) {
      <app-out-of-stock [class.oosperQuantity]="isOutOfStockPerQuantity()"
        [isOutOfStockPerQuantity]="isOutOfStockPerQuantity()" [isOutOfStock]="isOutOfStock()" [hasNotify]="hasNotify"
        [isFromSummary]="false" (outFlipCardType)="flipCardType($event)"
        (outModalCheckAddress)="openModalCheckAddress()"></app-out-of-stock>
      }
      <section [class.d-none]="isOutOfStock()">
        <div class="product-card__meta">
          <div class="product-card__meta--item is-col-2">
            <div class="d-grid">
              <div class="d-flex gap-1 d-flex gap-1 flex-row align-items-center">
                @if (!isSignupFlow()) {
                <h5 class="product-card__price">{{ selectedAttributeCardConfig().price | currency }}
                </h5>
                } @else {
                <h5 class="product-card__price">{{ selectedAttributeCardConfig().display }}</h5>
                }
                @if(selectedAttributeCardConfig().hasDiscount) {
                <small
                  class="product-card__price--line-through text-body-tertiary fw-bold text-decoration-line-through">
                  {{selectedAttributeCardConfig().originalPrice | currency}}
                </small>
                }
              </div>
              @if (!isSignupFlow() && selectedAttributeCardConfig().display) {
              <p class="product-card__info">
                {{selectedAttributeCardConfig().display || ''}}
              </p>
              }
            </div>

            <!-- Quantity buttons -->
            <div class="col quantity">
              @if (wasValueHigher10) {
              <small class="how-many-label d-none d-sm-block">How many?</small>
              }
              <mat-form-field class="rounded-selector d-flex" subscriptSizing="dynamic">
                @if( wasValueHigher10 || newQuantity() >= 10 || (newQuantity() >= 10 &&
                wasValueHigher10) ){
                <input type="text" matInput appNumberRestriction [minNumber]="1" [maxNumber]="totalStockAvailable()"
                  (change)="onInputQuantityChange($event)" (keyup)="onKeyUp($event)" [value]="newQuantity()">
                }
                @else{
                <mat-select [value]="newQuantity()" (selectionChange)="onQuantityChange($event)">
                  @for(q of availableQuantities(); track $index){
                  <mat-option [value]="q.val">{{ q.name }}</mat-option>
                  }
                </mat-select>
                }
              </mat-form-field>
            </div>

          </div>

          <div class="row">
            <!-- Product Customizations  -->
            <div class="col-12 d-flex gap-1">
              @if (card().settings?.showAttributes) {
              @if (productAttributes.length) {
              @for (attr of productAttributes; track $index) {
              <mat-form-field class="rounded-selector" subscriptSizing="dynamic">
                <mat-label class="text-capitalize">{{attr.name.toLowerCase()}}</mat-label>
                @if (card().product?.variantId || attr.values.length === 1) {
                <input matInput type="text" readonly="true" [value]="selectedAttribute()?.value.name">
                } @else {
                <mat-select [value]="selectedAttribute()?.value?.id || ''"
                  (selectionChange)="onAttributeChange(attr.id, $event)">
                  @for (item of attr.values; track $index) {
                  <mat-option [value]="item.id">{{item.name}}</mat-option>
                  }
                </mat-select>
                }
              </mat-form-field>
              }
              }
              @if (card().product?.packages?.length) {
              <mat-form-field class="rounded-selector" subscriptSizing="dynamic">
                <mat-label class="text-capitalize">Package</mat-label>
                @if (card().product?.packages?.length === 1) {
                <input matInput type="text" readonly="true" [value]="selectedPackage()?.name">
                } @else {
                <mat-select [value]="selectedPackage()?.id || ''" (selectionChange)="onPackageChanged($event.value)">
                  @for (item of card().product?.packages; track $index) {
                  <mat-option [value]="item.id">{{item.name}}</mat-option>
                  }
                </mat-select>
                }
              </mat-form-field>
              }
              }
            </div>
          </div>
        </div>

        <!-- Action button -->
        @if(!customizeBox) {
        <div class="product-card__action">
          <div class="d-grid gap-2">
            @if (signalsStoreService.hasSession()) {
            @if (selectedAttributeCardConfig().isSubscription) {
            @if (card().product?.bundle?.items?.length) {
            <div [class.action-col-2]="marketStatus().isOpen && selectedAttributeCardConfig().isALaCarte">
              @if(stockSignal().size) {
              @if (marketStatus().isOpen && selectedAttributeCardConfig().isALaCarte) {
              <button type="button" (click)="flipCardType(cardTypes.add)" [disabled]="isDisabledActionButtons()"
                class="btn btn-outline-secondary d-flex gap-2 align-items-center">
                @if (isMobile()) {
                <i class="fas fa-plus"></i>
                } @else {
                Add
                }
              </button>
              }
              <button type="button" [disabled]="isDisabledSubscribeButton()" (click)="flipCardType(cardTypes.subscribe)"
                class="btn btn-secondary d-flex gap-2 align-items-center">
                {{getButtonText({isBundle: true, isSubscription: true})}}
              </button>
              } @else {
              <p class="placeholder-glow mb-0 w-100">
                <span
                  class="placeholder w-100 col-12 bg-secondary rounded btn btn-secondary d-flex gap-2 align-items-center"
                  style="height: 36px;"></span>
              </p>
              }
            </div>
            }
            @else {
            <div [class.action-col-2]="marketStatus().isOpen && selectedAttributeCardConfig().isALaCarte">
              @if(stockSignal().size) {
              @if (marketStatus().isOpen && selectedAttributeCardConfig().isALaCarte) {
              <button type="button" (click)="flipCardType(cardTypes.add)" [disabled]="isDisabledActionButtons()"
                class="btn btn-outline-secondary d-flex gap-2 align-items-center">
                @if (isMobile()) {
                <i class="fas fa-plus"></i>
                } @else {
                Add
                }
              </button>
              }
              <button type="button" [disabled]="isDisabledSubscribeButton()" (click)="flipCardType(cardTypes.subscribe)"
                class="btn btn-secondary d-flex gap-2 align-items-center">
                {{getButtonText({isBundle: false, isSubscription: true})}}
              </button>
              } @else {
              <p class="placeholder-glow mb-0 w-100">
                <span
                  class="placeholder w-100 col-12 bg-secondary rounded btn btn-secondary d-flex gap-2 align-items-center"
                  style="height: 36px;"></span>
              </p>
              }
            </div>
            }
            }
            @else {
            @if(stockSignal().size && selectedAttributeCardConfig().isALaCarte) {
            <button type="button"
              (click)="card().product?.preOrder ? addProductToCart(false, $event) : flipCardType(cardTypes.add)"
              [disabled]="isDisabledActionButtons() || !marketStatus().isOpen"
              class="btn btn-secondary d-flex gap-2 align-items-center"
              [class.btn-outline-secondary]="isPreOrderedVariant()">
              {{getButtonText({isBundle: false, isSubscription: false})}}
            </button>
            } @else {
            <p class="placeholder-glow mb-0 w-100">
              <span
                class="placeholder w-100 col-12 bg-secondary rounded btn btn-secondary d-flex gap-2 align-items-center"
                style="height: 36px;"></span>
            </p>
            }
            }
            }
            @else {
            <button type="button" (click)="openModalCheckAddress()"
              class="btn btn-secondary d-flex gap-2 align-items-center">
              Add To Delivery
            </button>
            }
          </div>
        </div>
        } @else {
        <div class="product-card__action">
          <button type="button" (click)="flipCardType(cardTypes.subscribe, true)"
            class="btn btn-secondary d-flex gap-2 align-items-center">
            <i class="fa fa-pen-to-square"></i>
            Customize box
          </button>
        </div>
        }
      </section>

    </section>
    }
    @case (cardTypes.notify) {
    <section class="animated fadeIn d-flex flex-column flex-grow-1">
      <div class="row">
        <i class="fas fa-times text-end close-mark"
          (click)="flipCardType(card().settings?.isSummary ? cardTypes.summary : cardTypes.main)"></i>
      </div>
      <h4 class="text-center mt-2 px-2">
        Unfortunately, this product is out of stock.
      </h4>
      <p>If you wold like us to notify you when we receive a new shipment, please enter your information below.
      </p>
      <mat-radio-group [(ngModel)]="notifySelectedOption">
        <div class="row row-cols-1">
          <div class="col">
            <div class="d-flex flex-row align-items-center">
              <mat-radio-button
                [value]="{email: signalsStoreService.sessionSignal()?.accountInfo?.email}"></mat-radio-button>
              <label class="email-container" #emailContainer>
                <div class="email-text" #emailText>{{signalsStoreService.sessionSignal()?.accountInfo?.email}}</div>
              </label>
            </div>
          </div>
          <div class="col text-center">
            <b>or</b>
          </div>
          <div class="col">
            <div class="d-flex flex-row align-items-center">
              <mat-radio-button
                [value]="{phone: signalsStoreService.sessionSignal()?.accountInfo?.phone}"></mat-radio-button>
              <label>{{signalsStoreService.sessionSignal()?.accountInfo?.phone || "" | phone}}</label>
            </div>
          </div>
        </div>
      </mat-radio-group>
      <!-- Action button -->
      <div class="card-footer p-0 mt-1 mt-auto">
        <div class="row">
          <div class="col">
            <button type="button"
              (click)="flipCardType(card().settings?.isSummary ? cardTypes.summary : cardTypes.main)"
              class="btn btn-secondary d-flex gap-2 align-items-center">
              Cancel
            </button>
          </div>
          <div class="col">
            <button type="button" (click)="notifyMe()" class="btn btn-primary d-flex gap-2 align-items-center">
              Notify Me!
            </button>
          </div>
        </div>
      </div>
    </section>
    }
    @case (cardTypes.notified) {
    <section class="animated fadeIn d-flex flex-column flex-grow-1">
      <div class="row">
        <i class="fas fa-times text-end close-mark"
          (click)="flipCardType(card().settings?.isSummary ? cardTypes.summary : cardTypes.main)"></i>
      </div>
      <div class="d-flex h-100 justify-content-center align-items-center px-1 flex-column text-center">
        <h4 class="">
          You'll be notified!
        </h4>
        <p>Thank you for your interest in this product. We will let you know when it's back in stock.</p>
      </div>
    </section>
    }
    @case (cardTypes.subscribe) {
    <i class="fas fa-times close-mark"
      (click)="flipCardType(card().settings?.isSummary || false ? cardTypes.summary : cardTypes.main)"></i>
    <section class="animated fadeIn ">

      <div>
        <h4 class="text-center h3-semibold">Product subscription</h4>
        @if (subscribeAndcustomizeBox) {
        <p>How often would you want to receive your farmbox?</p>
        } @else {
        <p>When would you like to receive your subscription item(s)?</p>
        }
        <mat-form-field class="rounded-selector mb-3 d-flex" subscriptSizing="dynamic">
          <mat-label> Frequency </mat-label>
          <mat-select (selectionChange)="onChangeFrequency($event)" [value]="selectedFrequency?.id">
            @for(freq of frequencies(); track $index){
            <mat-option [value]="freq.id">{{ freq.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        @if (!subscribeAndcustomizeBox) {
        @if (!isTemporaryRouteChange()) {
        <mat-checkbox [checked]="true" class="mb-3"
          (change)="initiateSubscriptionImmediatly.set($event.checked)">Initiate my
          subscription immediately</mat-checkbox>
        }
        @if (initiateSubscriptionImmediatly()) {
        <app-empty-message [width100]="true" [mt0]="true"
          [message]="'Next Delivery: ' + deliveriesService.deliveryZoneInfo()?.readeableDeliveryDate"></app-empty-message>
        } @else {
        <mat-form-field class="w-100 mb-3 animated fadeIn">
          <mat-label>Start subscription date</mat-label>
          <input matInput [min]="minDate()" [matDatepicker]="startSubscription" [matDatepickerFilter]="dateFilter"
            readonly (dateChange)="onDateChange($event)" (click)="startSubscription.open()">
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="startSubscription"></mat-datepicker-toggle>
          <mat-datepicker [yPosition]="'below'" #startSubscription disabled="false"></mat-datepicker>
        </mat-form-field>
        }
        }
      </div>
      <div class="card-footer p-0 mt-1 mt-auto">
        <div class="row">
          @if (subscribeAndcustomizeBox) {
          <div class="col">
            <button type="button" (click)="continue()"
              [disabled]="!selectedFrequency || (!this.initiateSubscriptionImmediatly() && !this.selectedDateSubscription)"
              class="btn btn-secondary d-flex gap-2 align-items-center">
              Subscribe & continue
            </button>
          </div>
          } @else {
          <div class="col">
            <button type="button"
              (click)="flipCardType(card().settings?.isSummary || false ? cardTypes.summary : cardTypes.main)"
              class="btn btn-outline-secondary d-flex gap-2 align-items-center">
              Cancel
            </button>
          </div>
          <div class="col">
            <button type="button" (click)="addProductToCart(true, $event)"
              [disabled]="!selectedFrequency || (!this.initiateSubscriptionImmediatly() && !this.selectedDateSubscription)"
              class="btn btn-secondary d-flex gap-2 align-items-center">
              Subscribe
            </button>
          </div>
          }
        </div>
      </div>

    </section>
    }
    @case (cardTypes.add) {

    <i class="fas fa-times close-mark"
      (click)="flipCardType(card().settings?.isSummary || false ? cardTypes.summary : cardTypes.main)"></i>

    <form [formGroup]="frmAdd">
      <section class="animated fadeIn">
        <header class="text-center">
          <h4 class="h4-semibold">
            Add Product
          </h4>
          <span class="h7-semibold">
            Schedule Deliveries
          </span>
        </header>

        @if (deliveryDates().length) {
        @if(deliveryDate.invalid &&
        deliveryDate.errors?.['required'] &&
        (deliveryDate.dirty || deliveryDate.touched)){
        <mat-error>
          <i class="fas fa-xmark"></i> You should select at least one delivery date
        </mat-error>
        }
        @for (dates of deliveryDates(); track $index) {
        <section class="hstack flex-row gap-2 dates">
          <div class="w-75">
            <div>
              <span class="m-0 h8-regular d-none d-sm-block">
                {{dates.date}}
              </span>
              <span class="m-0 h8-regular d-sm-none">
                {{dates.mobileDate}}
              </span>
            </div>
            @if (dates.isSkipped) {
            <div>
              <span class="m-0 h8-bold link-primary text-decoration-underline" (click)="prevUnSkip(dates)">
                Unskip
              </span>
            </div>
            }
          </div>
          <div class="w-25 text-end">
            @if (dates.isSkipped) {
            <span class="m-0 h8-bold">
              Skipped
            </span>
            }

            @if (dates.isAdded) {
            <span class="m-0 h8-bold added-text">
              Added
            </span>
            } @else if (!dates.isSkipped) {
            <mat-checkbox (change)="deliveryDateSelected(dates)" [ngModelOptions]="{standalone: true}"
              [disabled]="dates.isSkipped" [(ngModel)]="dates.isSelected">
            </mat-checkbox>
            }
          </div>
        </section>
        }
        }@else {
        <section class="hstack gap-2">
          <div class="w-100">
            <span class="m-0 h8-regular">
              No delivery dates available at this moment.
            </span>
          </div>
        </section>
        }

        <footer class="card-footer mt-3 p-0">
          <div class="row">
            <div class="col mt-2">
              <button type="button"
                (click)="flipCardType(card().settings?.isSummary || false ? cardTypes.summary : cardTypes.main)"
                class="btn btn-outline-secondary d-flex gap-2 align-items-center">
                Cancel
              </button>
            </div>
            <div class="col mt-2">
              <button type="button" class="btn btn-secondary d-flex gap-2 align-items-center" (click)="prevAdd($event)">
                Add
              </button>
            </div>
          </div>
        </footer>

      </section>
    </form>
    }
    }

    @if (showInfoMessage()) {
    <section class="product-card__added">
      <span class="material-symbols-outlined">
        verified
      </span>
      <h2 class="text-center">
        {{messageAction()}}
      </h2>
    </section>
    }

  </div>

</div>
} @else {
<div class="mb-4 animated fadeInUp fadeIn col">
  <div class="card position-relative p-3 row-gap-3" aria-hidden="true">
    <span class="placeholder bg-secondary col-12"></span>


    <span class="placeholder bg-secondary col-6"></span>
    <span class="placeholder bg-secondary col-6"></span>
    <div class="placeholder bg-secondary col-12" style="height: 100px;"></div>
    <div class="card-body">
      <h5 class="card-title placeholder-glow">
        <span class="placeholder bg-secondary col-6"></span>
      </h5>
      <p class="card-text placeholder-glow">
        <span class="placeholder bg-secondary col-7"></span>
        <span class="placeholder bg-secondary col-4"></span>
        <span class="placeholder bg-secondary col-4"></span>
        <span class="placeholder bg-secondary col-6"></span>
        <span class="placeholder bg-secondary col-8"></span>
      </p>
      <a class="btn btn-secondary disabled placeholder col-12" aria-disabled="true"></a>
    </div>
  </div>
</div>
}