import { Loader } from "@googlemaps/js-api-loader";
import { Session } from "../types/session.type";
import { LOCALSTORAGE_KEYS } from "../constants/databases";
import { ModalContentTypes } from "../constants/modal-content-types";
import { ModalContentService } from "../modal-content/modal-content.service";
import { LocalStorageService } from "../local-storage.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { eCommercePermissions } from "../types/account.types";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import { OrderCutoffExceeded, OrderRescheduled } from '../../settings/account/profile/profile.types';
import { OrderService } from "../order.service";
import { ReadableDate } from "../utils/formatting";
import { FormattedDeliveryInformation } from "../../settings/account/deliveries/intarfaces";


export const handleQuantityChange = (target: any) => {
  const updatedHasSelectedCustomQuantity = target.value == -1;
  const updatedSelectedQuantity = target.value;
  return {
    hasSelectedCustomQuantity: updatedHasSelectedCustomQuantity,
    selectedQuantity: updatedSelectedQuantity
  };
};

export const sanitizeNumericInput = (event: any) => {
  let newValue = event.target.value.replace(/\D/g, ''); // Remove all non-numeric chars
  newValue = Math.min(Math.max(parseInt(newValue, 10) || 0, 1), 999).toString(); // Filter and set value between 0 and 99
  return newValue;
};

export const GoogleMapsUtils = {
  initializeAutocomplete: (loader: Loader, inputElement: HTMLInputElement, onPlaceSelected: (place: google.maps.places.PlaceResult) => void, localStorageService?: any, options?: google.maps.places.AutocompleteOptions) => {
    const optionsByDefault = {
      types: ['address'],
      componentRestrictions: {
        country: 'US',
      },
    };

    loader.importLibrary('places').then(() => {
      const autocomplete = new google.maps.places.Autocomplete(inputElement, options || optionsByDefault);
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        onPlaceSelected(place);
      });
    });
  },
  loadAutoCompleteIfAny: (loader: Loader, onPlaceSelected: (place: google.maps.places.PlaceResult) => void, localStorageService?: any) => {
    loader.importLibrary('places').then(() => {
      if (localStorageService.get(LOCALSTORAGE_KEYS.GOOGLEPLACERESULT)) {
        const place = localStorageService.get(LOCALSTORAGE_KEYS.GOOGLEPLACERESULT) as google.maps.places.PlaceResult
        onPlaceSelected(place);
      }
    });
  }
};

export const arrayToMap = (key: string, array: any[]): Map<number, any> => {
  const map = new Map<number, any>();
  for (const object of array) {
    const valor: any = generateMapKeyFromObject([key], object);
    map.set(valor, object);
  };
  return map;
}

export const arrayToMapMultiKey = (keys: string[], array: any[]): Map<string | number, any> => {
  const map = new Map<string | number, any>();
  for (const object of array) {
    const key = generateMapKeyFromObject(keys, object);
    map.set(key, object);
  };
  return map;
}

export const mapToArray = (map: Map<number | string, any>): any[] => {
  return Array.from(map.values());
}

export const splitByUpperCase = (str: string): string[] => {
  return str.split(/(?=[A-Z])/);
}

export const generateMapKeyFromObject = (keys: string[], object: any): string | number => {
  let keysValue: any[] = [];
  for (const k of keys) {
    const valor: any = k.split('.').reduce((obj, key): any => {
      // Verificar si la clave anterior existe en el objeto
      if (obj && typeof obj === 'object' && key in obj) {
        // if the type is boolean, parse it to int and add "_" as prefix:
        if (typeof obj[key] === 'boolean')
          return (+obj[key]).toString();
        return obj[key]; // Si existe, devolver el valor de la propiedad
      } else {
        return ''; // Si no existe, devolver undefined
      }
    }, object);
    if (valor)
      keysValue.push(valor);
  }

  const key: any = keysValue.join('_');
  if (!isNaN(key)) return +key;
  return key
}

export const generateMapKeyFromValues = (values: any[]): string => {
  const processedValues = values.map(item => {
    if (typeof item === 'boolean') return +item
    return item;
  });
  return processedValues.join('_');
}

/**
 * Validate and return if the current user is suspended and launch the "suspended account" modal if true.
 * @param modalService Modal Content Service
 * @param localStorageService Locastorage Service
 * @returns boolean indicating if the current session user is suspended or not
 */
export const isSuspendedUser = (modalService: any, localStorageService: any): boolean => {
  const session: Session | null = localStorageService.get(LOCALSTORAGE_KEYS.SESSION) || null;
  if (session?.settings.isSuspended)
    modalService.openModal(ModalContentTypes.ACCOUNT_SUSPENDED, { closeable: false, closeOthers: true })

  return session?.settings.isSuspended || false;
}


export const specialNavigatrionParamAndValue = { param: 'utm_medium', value: 'email' }

export const handleImageError = (event: Event) => {
  (event.target as HTMLImageElement).src = 'assets/images/product-placeholder.webp';
}

//For users who don't have a registered payment method but don't need to go through the signup flow.
export const isANoPaymentMethodUser = (localStorageService: LocalStorageService, modalContentService: ModalContentService, activeModal: NgbModal) => {
  const sessionStored: Session | null = localStorageService.get(LOCALSTORAGE_KEYS.SESSION);
  const permissions: eCommercePermissions | null = localStorageService.get(LOCALSTORAGE_KEYS.PERMISSIONS);

  if (!sessionStored?.settings?.hasPaymentMethod && permissions?.settings.paymentMethod.allowed) {
    if (activeModal.hasOpenModals()) return true
    modalContentService.openModal(
      ModalContentTypes.PENDING_PAYMENT_METHOD_NO_SIGNUP,
      { title: 'Payment Method Required', closeable: true },
      undefined
    );

    return true;
  }

  return false;
}

export const isAbandonedUser = (localStorageService: LocalStorageService, modalContentService: ModalContentService, activeModal: NgbModal) => {
  const sessionStored: Session | null = localStorageService.get(LOCALSTORAGE_KEYS.SESSION);
  const permissions: eCommercePermissions | null = localStorageService.get(LOCALSTORAGE_KEYS.PERMISSIONS);

  if (!sessionStored?.settings?.hasPaymentMethod && permissions?.settings.paymentMethod.allowed) {
    if (activeModal.hasOpenModals()) return true
    modalContentService.openModal(
      ModalContentTypes.PENDING_PAYMENT_METHOD,
      { title: 'WELCOME BACK!', closeable: true },
      undefined
    );

    return true;
  }

  return false;
}

export const isNoAddressUser = (localStorageService: LocalStorageService, modalContentService: ModalContentService, activeModal: NgbModal, router: Router) => {
  const sessionStored: Session | null = localStorageService.get(LOCALSTORAGE_KEYS.SESSION);

  if (!!(sessionStored?.settings?.requireAddress) && !(sessionStored?.address?.street)) {
    if (activeModal.hasOpenModals()) return true;
    modalContentService.openModal(
      ModalContentTypes.CONFIRMATION,
      {
        title: 'Welcome!',
        closeable: false,
        textContent: `
          We no longer deliver to pick-up locations. If you would like to place an order, please click the button below. Home delivery is free until October. After October it will be $8/delivery. Thanks for supporting our local producers!
        `,
        confirmButtonText: 'Update Delivery Address'
      }
    ).closed.subscribe(
      (result: { confirm: boolean } | null) => {
        if (result?.confirm) {
          router.navigate(['/settings/account/profile'])
        }
      }
    );

    return true;
  }

  return false;
}


export const isANoDeliveryDayUser = (localStorageService: LocalStorageService, modalContentService: ModalContentService) => {
  const session: Session | null = localStorageService.get(LOCALSTORAGE_KEYS.SESSION);
  if (!session || session.deliveryInfo?.deliveryDayId || !session.address?.zoneId) return false;
  modalContentService.openModal(ModalContentTypes.SELECT_DELIVERY_DAY, {
    title: 'Welcome to Mother Earth Food',
    textContent: 'Before you start shopping on our new site, please select a preferred delivery day for your orders. Remember, this is not set in stone. You have the flexibility to change it at any time!',
    deliveryDaySelection: {
      zoneId: +session.address.zoneId
    },
    closeable: false,
  }, { backdrop: 'static' })
  return true;
}

export const isAddressUpdateRequired = (localStorageService: LocalStorageService, modalContentService: ModalContentService, activeModal: NgbModal, router: Router) => {
  const sessionStored: Session | null = localStorageService.get(LOCALSTORAGE_KEYS.SESSION);

  if (!!(sessionStored?.deliveryInfo?.isAddressUpdateRequired)) {
    if (activeModal.hasOpenModals()) return true;
    modalContentService.openModal(
      ModalContentTypes.CONFIRMATION,
      {
        title: 'Action Required: Update Your Delivery Address',
        closeable: false,
        textContent: `
          We noticed that your delivery address is no longer valid for deliveries. Please update your address to ensure you continue to receive your orders without any interruptions. Thank you!
        `,
        confirmButtonText: 'Update Delivery Address'
      }
    ).closed.subscribe(
      (result: { confirm: boolean } | null) => {
        if (result?.confirm) {
          router.navigate(['/settings/account/profile'])
        }
      }
    );

    return true;
  }

  return false;
}

export const capitalizeWords = (str: string): string => {
  return str.replace(/\b\w/g, char => char.toUpperCase());
}

export const unableOperationMessage = (modalContentService: ModalContentService) => {
  modalContentService.openModal(ModalContentTypes.CONFIRMATION, {
    title: '',
    textContent: `Hi, there is an issue with the task you are trying to complete. Please reach out to <a class="fw-bold" href="mailto:${environment.config.contactEmail}">${environment.config.contactEmail}</a> to let us know what you intended to do, and we will help!`,
    confirmButtonText: 'I Understand'
  })
}

export const openModalTanksForSubmit = (modalContentService: ModalContentService, deliveryInfo: { deliveryDateText: string, cutoffDateText: string, thanksMessage: string }) => {
  modalContentService.openModal(ModalContentTypes.CONFIRMATION, {
    title: 'Thank you for your order!',
    textContent: `
    <div class="px-4 my-4">
      <p class="m-0">${deliveryInfo.thanksMessage}</p>
      <div class="order-info__item animated fadeIn w-100 mt-4">
        <div class="order-info__item-icon">
          <span class="material-symbols-outlined">local_shipping</span>
        </div>
        <div class="order-info__item-value">
          <div class="text-start">
            <h5 class="order-info__item-title">
              ${deliveryInfo.deliveryDateText}
            </h5>
            <p class="order-info__item-text">
              Edit before ${deliveryInfo.cutoffDateText}
            </p>
          </div>
        </div>
      </div>
    </div>
    `,
    confirmButtonText: 'Close'
  })
}

export const formatStringForURL = (input: string): string => {
  if (!input) return '';

  // Step 0: Replace any "&" with "and"
  let formatted = input.replace(/&/g, 'and');

  // Step 1: Replace any character that is not a letter or number with a hyphen
  formatted = formatted.replace(/[^a-zA-Z0-9]/g, '-');

  // Step 2: Replace multiple consecutive hyphens with a single hyphen
  formatted = formatted.replace(/-+/g, '-');

  // Step 3: Remove the hyphen at the end of the string, if it exists
  formatted = formatted.replace(/-$/, '');

  return formatted.toLowerCase();
}


//#region AFTER DELIVERY UPDATES:

export const afterDeliveryUpdates = (data: {
  orderCutoffExceeded: OrderCutoffExceeded | null,
  orderRescheduled: OrderRescheduled[] | null,
  deliveryZoneInfo: FormattedDeliveryInformation | null,
  orderService: OrderService,
  modalService: ModalContentService,
  isUpdatingFromProfile?: boolean
}) => {
  const { orderCutoffExceeded, orderRescheduled, orderService, modalService, isUpdatingFromProfile, deliveryZoneInfo } = data;
  orderService.getOrder(false);
  if (environment.config.flows.settings.deliveryDayUpdates.displayOnlyActiveReescheduledOrder) {
    if (!orderCutoffExceeded?.id) {
      if (!orderRescheduled?.length) return;

      const activeOrderRescheduled = orderRescheduled.find(o => o.newDeliveryDate === deliveryZoneInfo?.deliveryDate);
      if (!activeOrderRescheduled) return;
      validateOrderRescheduled([activeOrderRescheduled], modalService);
    }
    else
      validateOrderCutOffExceeded(orderCutoffExceeded, orderService, modalService, isUpdatingFromProfile);
  } else {
    validateOrderCutOffExceeded(orderCutoffExceeded, orderService, modalService, isUpdatingFromProfile);
    validateOrderRescheduled(orderRescheduled, modalService)
  }
}

const validateOrderCutOffExceeded = (
  orderCutoffExceeded: OrderCutoffExceeded | null,
  orderService: OrderService,
  modalService: ModalContentService,
  isUpdatingFromProfile: boolean = false
) => {

  if (!orderCutoffExceeded?.id) return;

  const textContent = getTextContentForOrderCutOffExeeded(isUpdatingFromProfile, orderCutoffExceeded._readableDeliveryDate);

  modalService.openModal(ModalContentTypes.CONFIRMATION, {
    title: 'Market Closed for New Delivery Date',
    textContent,
    cancelButtonText: 'Yes, keep order',
    confirmButtonText: 'No, cancel order',
    closeable: false
  }, { backdrop: 'static' }).closed.subscribe(
    (res: { confirm: boolean }) => {
      if (res.confirm)
        orderService.cancelOrderById(orderCutoffExceeded.id).subscribe();
    }
  );

}

const getTextContentForOrderCutOffExeeded = (isUpdatingAddress: boolean, readableDeliveryDate: ReadableDate | undefined): string => {
  if (!readableDeliveryDate) return '';
  let message = `It looks like the market is closed for the delivery day you are changing to. Therefore, this change will take effect for next week's order. Would you like to receive a delivery `
  message += isUpdatingAddress ? `at your old address this week <b><i class="text-primary">${readableDeliveryDate?.readableString}</i></b>?` : `to on <b><i class="text-primary">${readableDeliveryDate?.dayName}</i></b> this week?`
  return message;
}

const validateOrderRescheduled = (
  orderRescheduled: OrderRescheduled[] | null,
  modalService: ModalContentService
) => {
  if (!orderRescheduled?.length) return;

  if (environment.config.flows.settings.deliveryDayUpdates.displayOnlyActiveReescheduledOrder) {
    orderRescheduled = [orderRescheduled[0]];
  }

  modalService.openModal(ModalContentTypes.CONFIRMATION, {
    title: 'Delivery Date Adjustments',
    textContent: getOrderRescheduledModalContent(orderRescheduled),
    confirmButtonText: 'I Understand',
    closeable: false
  }, { backdrop: 'static' });
}

const getOrderRescheduledModalContent = (orders: OrderRescheduled[]): string => {


  let listContent = '';

  if (orders.length === 1) {
    const o = orders[0];
    return `
      Due to your delivery day update, your order (<b><i class="text-primary">${o.name}</i></b>) has been rescheduled 
      from <b><i class="text-primary">${o._readablePrevDeliveryDate?.readableString}</i></b>
      to <b><i class="text-primary">${o._readableNewDeliveryDate?.readableString}</i></b>.
    `
  }

  for (const o of orders) {
    listContent += `
      <div class="col-4 text-start p-2 h7-regular fst-italic">
        <section class="border-bottom border-1 rounded-1 p-3 bg-secondary bg-opacity-10 border-primary">
          <h5 class="mb-1 text-primary">${o.name}</h5>
          <p class="mb-1">From: <b class="text-primary">${o._readablePrevDeliveryDate?.readableString}</b></p>
          <p class="mb-1">To: <b class="text-primary">${o._readableNewDeliveryDate?.readableString}</b></p>
        </section>
      </div>
    `
  }

  return `
  Due to your delivery day update, some orders have been rescheduled. The following orders have been moved from their original date to the new delivery date:
  <div class="row mt-3">${listContent}</div>
  `
}

//#endregion