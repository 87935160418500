import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '../../environments/environment';
import * as CryptoJS from 'crypto-js';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  cryptoKey: string;

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    this.cryptoKey = environment.config.crypto.key;
  }

  set(key: string, data: any): void {
    if (!isPlatformBrowser(this.platformId)) return;
    const dataString = JSON.stringify(data)
    const encryptedData = CryptoJS.AES.encrypt(dataString, this.cryptoKey).toString();
    localStorage.setItem(key, !this.cryptoKey ? dataString : encryptedData);
  }

  get<T>(key: string): T | null {
    if (!isPlatformBrowser(this.platformId)) return null;

    const localStoredData = localStorage.getItem(key);
    if (!localStoredData)
      return null;

    const decryptedData = !this.cryptoKey ? localStoredData : CryptoJS.AES.decrypt(localStoredData, this.cryptoKey).toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData) as T;
  }

  remove(key: string): void {
    localStorage.removeItem(key);
  }
}
