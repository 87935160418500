import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, WritableSignal, inject, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { ModalContentData, PauseDates } from '../modal-content';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '../../notification/notification.service';
import { formatDateToYYYYMMDD } from '../../utils/formatting';
import { MatChipsModule } from '@angular/material/chips';
import { LocalStorageService } from '../../local-storage.service';
import { LOCALSTORAGE_KEYS } from '../../constants/databases';
import { Session } from '../../types/session.type';

@Component({
  selector: 'app-pause-subscriptions',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIcon,
    MatDatepickerModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatChipsModule
  ],
  templateUrl: './pause-subscriptions.component.html',
  styleUrl: './pause-subscriptions.component.scss'
})
export class PauseSubscriptionsComponent implements OnInit {

  activeModal = inject(NgbActiveModal);
  notificarionService = inject(NotificationService);
  localStorageService = inject(LocalStorageService);

  @Input()
  set data(data: ModalContentData | undefined) {
    if (data) {
      this.selectedPauseGroup.set(data.pauseSubscription?.current)
      this._data = data;
    }
  }

  get data() {
    return this._data
  }

  private _data!: ModalContentData | undefined;

  minDate = signal(new Date());

  deliveryDay: string = '';

  selectedPauseGroup: WritableSignal<PauseDates | undefined> = signal(undefined)

  pauseDates: PauseDates = {
    startDate: '',
    endDate: ''
  }

  daysSelected: any[] = [];

  ngOnInit(): void {
    const session: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION) || null;
    if (session?.deliveryInfo) {
      this.deliveryDay = session.deliveryInfo.deliveryDay;
      let [year, month_str, day] = session.deliveryInfo.deliveryDate.split('-');
      if (this.data?.pauseSubscription?.minAvailableDate) {
        [year, month_str, day] = this.data?.pauseSubscription?.minAvailableDate.split('-');
      }
      const month = (+month_str) - 1;
      const aux_date = new Date(+year, month, +day);
      this.minDate.set(new Date(aux_date));
    }
  }

  isSelected = (event: any) => {
    if (!this.daysSelected) return;
    const date = `${("00" + (event.getMonth() + 1)).slice(-2)}-${("00" + event.getDate()).slice(-2)}-${event.getFullYear()}`;
    return this.daysSelected.find(x => x == date) ? "selected" : null as any;
  };

  dateFilter = (date: Date | null) => {
    if (!date) {
      return false;
    }

    if (!this.deliveryDay) return true;

    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const index = daysOfWeek.indexOf(this.deliveryDay);
    return date.getDay() === index;
  }

  select(event: any, calendar: any) {
    // If the selected date is already in the list
    const date = `${("00" + (event.getMonth() + 1)).slice(-2)}-${("00" + event.getDate()).slice(-2)}-${event.getFullYear()}`;
    const index = this.daysSelected.findIndex(x => x === date);
    if (index >= 0) {
      // Remove the selection if it's the first or last date
      if (index === 0 || index === this.daysSelected.length - 1) {
        this.daysSelected.splice(index, 1);
        calendar.updateTodaysDate();
      } else {
        this.notificarionService.show({ text: "You can only remove the first or last selection.", type: 'warning' });
      }
    } else {
      // Check if there are previously selected dates and if the new date is consecutive
      if (this.daysSelected.length > 0) {
        const selectedDate = new Date(event.getFullYear(), event.getMonth(), event.getDate(), 0, 0, 0, 0);
        // Set up date format to prevent errors (IOS).

        const lastSelectedDate = new Date(this.setUpDateToYYYYMMDD(this.daysSelected[this.daysSelected.length - 1]));

        const differenceInDays = (selectedDate.getTime() - lastSelectedDate.getTime()) / (1000 * 3600 * 24);
        console.log(differenceInDays, '-', selectedDate.getTime(), '-', lastSelectedDate.getTime());
        if (differenceInDays !== 7) {
          this.notificarionService.show({ text: "You can only select a consecutive date.", type: 'warning' });
          return;
        }
      }
      // Add the new date to the list of selected dates
      this.daysSelected.push(date);
      calendar.updateTodaysDate();
    }
  }

  private setUpDateToYYYYMMDD(dateString: string): string {
    const arrayDate = dateString.split('-');
    return `${arrayDate[2]}-${arrayDate[0]}-${arrayDate[1]}T00:00:00`;
  }

  remove(index: any, calendar: any) {
    this.daysSelected.splice(index, 1);
    calendar.updateTodaysDate();
  }

  validatePauseDate() {
    if (!(this.daysSelected.length)) {
      return this.notificarionService.show({ text: 'You must select at least a date to pause your subscription.', type: 'error' })
    }

    let startDate = new Date(this.setUpDateToYYYYMMDD(this.daysSelected[0])).toISOString().split('T')[0];

    // Get last selected day
    let lastSelectedDate = new Date(this.setUpDateToYYYYMMDD(this.daysSelected[this.daysSelected.length - 1]));
    // Add one day
    lastSelectedDate.setDate(lastSelectedDate.getDate() + 1);
    // Convert date to ISO string without time.|
    let endDate = lastSelectedDate.toISOString().split('T')[0];

    this.selectedPauseGroup.set({
      startDate,
      endDate,
    });

    this.closeModalAndSendData();
  }

  private closeModalAndSendData() {
    const pauses = this.selectedPauseGroup();
    this.activeModal.close(pauses);
  }

}
