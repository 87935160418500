<div class="d-grid mx-auto">
  <div class="col-md-auto">
    <h2 class="modal-title text-center">Are you sure?</h2>
    <p class="text-center">
      You are deleting your driver tip just for this week. If you would like to
      permanently delete your tip select the checkbox
    </p>
  </div>
</div>

<div class="text-center">
  <mat-checkbox [(ngModel)]="applyToAllOrders">
    Apply to all future orders
  </mat-checkbox>
</div>

<div class="modal-action-row has-btn-2">
  <button type="button" class="btn btn-outline-primary" (click)="closeModal()">
    Cancel
  </button>
  <button type="button" class="btn btn-primary" (click)="confirmDelete()">
    Confirm
  </button>
</div>
