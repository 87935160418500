import { Component, computed, inject, input, model } from '@angular/core';
import { ResumeSubscriptions } from '../modal-content';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GroupSubscription, SubscriptionResume } from '../../../settings/account/subscriptions/subscriptions.types';
import { SignalsStoreService } from '../../signals-store.service';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOption, MatSelect } from '@angular/material/select';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { formatDateToReadableString } from '../../utils/formatting';
import { ResolutionService } from '../../resolution.service';

@Component({
  selector: 'app-resume-subscriptions',
  standalone: true,
  imports: [
    MatFormField,
    MatSelect,
    MatOption,
    MatLabel,
    FormsModule,
    ReactiveFormsModule,
    CommonModule
  ],
  templateUrl: './resume-subscriptions.component.html',
  styleUrl: './resume-subscriptions.component.scss'
})
export class ResumeSubscriptionsComponent {

  //#region Services

  #activeModal = inject(NgbActiveModal);

  #signalStoreService = inject(SignalsStoreService);

  #resolutionService = inject(ResolutionService);

  //#endregion

  //#region Inputs / Outputs

  data = input.required<ResumeSubscriptions | undefined>();

  frequencies = computed(() => this.#signalStoreService.frequencies());

  nextDeliveryDate = model<string>('');

  isMobile = computed(() => this.#resolutionService.isMobile());

  //#endregion

  //#region Properties

  subscription = computed(() => this.#groupSubscriptions());

  availableDates = computed(() => this.#setAvailableDates());

  //#endregion

  //#region Methods

  cerrar() {
    this.#activeModal.close();
  }

  #groupSubscriptions() {

    const groups: GroupSubscription[] = [];

    const subscriptions = this.data()?.subscriptions ?? [];

    subscriptions
      .sort((a, b) => a.frequency - b.frequency);

    let frequencyId = 0;

    for (const sub of subscriptions) {

      if (frequencyId !== sub.frequency) {

        frequencyId = sub.frequency;

        groups.push({
          frequency: this.frequencies().find(x => x.id === frequencyId)?.name ?? '',
          frequencyId,
          nextDeliveryDate: '',
          subscriptions: subscriptions
            .filter(x => x.frequency === frequencyId)
            .map<SubscriptionResume>(y => ({ ...y, newDeliveryDate: new FormControl('', [Validators.required]) }))
        });
      }
    }

    return groups;
  }

  #setAvailableDates() {

    if (!this.subscription().length)
      return [];

    return Array
      .from(new Set(this.subscription().map(x => x.subscriptions.map(y => y.dateAvailables).flat())[0]))
      .map(x => {

        const { year, month, day } = formatDateToReadableString(x ?? '');

        return {
          date: `${month} ${day}, ${year}`,
          originalDate: x ?? ''
        };
      }) ?? [];
  }

  changeNextDeliveryFrequency(event: any, subscriptions: SubscriptionResume[]) {
    if (!event) return;
    // Set the selected date in all elements of this group
    subscriptions.forEach(x => x.newDeliveryDate.setValue(event));
    // Clear the global next delivery date selected.
    this.nextDeliveryDate.set('');
  }

  changeNextDeliveryProduct(group: GroupSubscription) {
    // Set frequency next delivery empty when an item of its group change the date.
    group.nextDeliveryDate = '';
    // Clear the global next delivery date selected.
    this.nextDeliveryDate.set('');
  }

  changeNextDeliveryGlobal(event: any) {
    // Apply the date selected to all groups and products.
    this.subscription()
      .forEach(x => {
        x.subscriptions.forEach(y => y.newDeliveryDate.setValue(event))
        x.nextDeliveryDate = event;
      });
  }

  #isValidForm() {
    return this.subscription()
      .every(x => x.subscriptions.every(y => y.newDeliveryDate.valid));
  }

  resumeSubscription() {

    const result: { startDates: { lineId: number, date: string }[] } = {
      startDates: []
    };

    for (const sub of this.subscription()) {

      result.startDates
        .push(...sub.subscriptions.map(x => {

          x.newDeliveryDate.markAllAsTouched();

          return {
            lineId: x.id,
            date: x.newDeliveryDate.value ?? ''
          };
        }));
    }

    if (!this.#isValidForm())
      return;

    this.#activeModal
      .close(result);
  }

  //#endregion
}
