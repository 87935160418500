import { Component, computed, inject, input, InputSignal, signal } from '@angular/core';
import { Product } from '../../../product/product.types';
import { depositConfig } from '../../../product/product.contstants';
import { CommonModule } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pre-order-product',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './pre-order-product.component.html',
  styleUrl: './pre-order-product.component.scss'
})
export class PreOrderProductComponent {

  #activeModal = inject(NgbActiveModal);

  productAvailabilityDisclaimer = signal('');

  data: InputSignal<Partial<Product> | undefined> = input(undefined, {
    transform: (data: Partial<Product> | undefined) => {
      if (!data) return data;
      const name = data.name;
      const variantName = data._variantData?.name && data._variantData?.name !== data.name ? data._variantData?.name : '';
      data.name = variantName ? `${name} | ${variantName}` : name
      data.price = data._variantData?.price || data.price;

      this.productAvailabilityDisclaimer.set(depositConfig.productAvailabilityDisclaimer(data?.preOrder?._readableEndDate?.readableString || ''));

      return data;
    }
  })

  depositConfig = computed(() => {
    const preorder = this.data()?.preOrder;
    if (!preorder) return null;
    return depositConfig[preorder.depositType];
  })

  handleImageError(event: Event) {
    (event.target as HTMLImageElement).src = 'assets/images/product-placeholder.webp';
  }

  confirmPreOrder() {
    this.#activeModal.close({ sendPreOrder: true })
  }

  cancelPreOrder() {
    this.#activeModal.close()
  }

}
