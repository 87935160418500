import { inject, Injectable } from "@angular/core";
import { PurchasePayload } from "./gift-card.types";
import { RequestHandlerParams } from "../../shared/types/api-service.types";
import { ApiService } from "../../shared/api.service";
import { ApiResponse, GlobalRequestStatus } from "../../shared/common/types";
import { filter, map, Observable, tap } from "rxjs";
import { NotificationService } from "../../shared/notification/notification.service";
import { Product } from "../../product/product.types";


@Injectable({ providedIn: 'root' })
export class GiftCardService {

    #apiService = inject(ApiService);
    #notificationService = inject(NotificationService);

    sendPurchase(payload: PurchasePayload): Observable<GlobalRequestStatus> {
        const params: RequestHandlerParams = {
            endpoint: '/gift-card',
            method: 'POST',
            apiV3: true,
            body: payload,
            returnError: true,
            showErrorMessage: true
        };

        return this.#apiService.handleRequest<ApiResponse<any>>(params).pipe(
            tap(res => res.error ? this.#notificationService.show({ text: res.message, type: 'error' }) : null),
            map(res => {
                return { finished: true, error: res.error, success: !res.error } as GlobalRequestStatus
            })
        )
    }

    getGiftCardData() {
        const params: RequestHandlerParams = {
            endpoint: '/gift-card',
            method: 'GET',
            apiV3: true,
            showErrorMessage: true
        };

        return this.#apiService.handleRequest<ApiResponse<Product>>(params).pipe(
            tap(res => res.error ? this.#notificationService.show({ text: res.message, type: 'error' }) : null),
            filter(res => !!!res.error),
            map(res => res.data)
        )
    }
}