import { Injectable, WritableSignal, inject, signal } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { BundleEditionType, FireBaseProductOrder, OrderResponse, ProductOrder, UpdateOrderParams } from './types/order.type';
import { FIREBASE_COLLECTIONS, LOCALSTORAGE_KEYS } from './constants/databases';
import { FirebaseCrudService } from './firebase-crud.service';
import { Session } from './types/session.type';
import { ApiService } from './api.service';
import { RequestHandlerParams } from './types/api-service.types';
import { ApiResponse } from './common/types';
import { filter, finalize, map, Observable, of, tap } from 'rxjs';
import { NotificationService } from './notification/notification.service';
import { DeliveriesService } from '../settings/account/deliveries/deliveries.service';
import { StockService } from '../stock/stock.service';
import { ModalContentService } from './modal-content/modal-content.service';
import { ModalContentTypes } from './constants/modal-content-types';
import { arrayToMap, arrayToMapMultiKey, mapToArray, openModalTanksForSubmit } from './common/utils';
import { DateTime } from 'luxon';
import { KlaviyoService } from './klaviyo/klaviyo.service';
import { KLAVIYOEVENTS } from './klaviyo/events';
import { eCommercePermissions } from './types/account.types';
import { formatDateToReadableString } from './utils/formatting';
import { FirebaseOrder } from './signals-store.service';
import { PreOrderData, PreOrderedProduct, PreOrderPayloadProduct } from '../product/product.types';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  private apiService = inject(ApiService);
  private firebaseCrudService = inject(FirebaseCrudService);
  private localStorageService = inject(LocalStorageService);
  private notificationService = inject(NotificationService);
  private deliveriesService = inject(DeliveriesService);
  private stockService = inject(StockService);
  private modalContentService = inject(ModalContentService);
  private klaviyoService = inject(KlaviyoService);

  endpoints = {
    order: `/order`,
    orders: '/orders',
    coupon: '/account/coupons',
    ecommerceLogs: '/ecommerce-logs',
    preOrder: '/pre-order',
  };

  odooOrder = signal<OrderResponse[]>([]);
  order: WritableSignal<any> = signal(null)
  productOrder: WritableSignal<ProductOrder | any> = signal(null)

  openModalOrderNotSubmitted: WritableSignal<boolean> = signal(false);
  hasProductInCart: WritableSignal<any> = signal(false)

  notSavedCartEventCreated: WritableSignal<boolean> = signal(false);
  configByOrder = signal<Map<string, any>>(new Map());
  hasGetOddooOrders = signal(false);

  constructor() { }

  getOrder(getStock: boolean = true, deliveryDate?: string) {
    const sessionStored: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION);
    const permissions: eCommercePermissions | null = this.localStorageService.get(LOCALSTORAGE_KEYS.PERMISSIONS);
    if (!sessionStored || (!sessionStored.settings?.hasPaymentMethod && permissions?.settings.paymentMethod.allowed)) return;

    // If getStock is true, we need to clear the sotck before any action only for getOrder
    if (getStock) {
      this.stockService.mapStockSignal.set(new Map());
      this.stockService.stockSignal.set(null);
    }

    const params: RequestHandlerParams = {
      endpoint: this.endpoints.orders,
      method: 'GET',
      apiV3: true
    }

    return this.apiService.handleRequest<ApiResponse<any>>(params).pipe(
      tap(response => {
        if (!response) throw new Error('getOrder Error');
        this.odooOrder.set(response?.data ?? []);
      }),
      tap(() => {
        if (getStock && deliveryDate)
          this.getStock(deliveryDate);
      }),
      finalize(() => this.hasGetOddooOrders.set(true))
    ).subscribe();
  }

  getStock(deliveryDate: string) {
    // TODO: Hacerlo por firebase o odoo
    const currentOdooOrder = this.odooOrder().find(x => x.deliveryInfo.deliveryDate === deliveryDate);

    const suggestedProducts = currentOdooOrder?.relatedProducts?.suggested || [];
    const buyAgainProducts = currentOdooOrder?.relatedProducts?.buyAgain || [];
    const favoriteProducts = currentOdooOrder?.relatedProducts?.favorites || [];

    const allRelatedProducts = [...suggestedProducts, ...buyAgainProducts, ...favoriteProducts];

    if (!allRelatedProducts?.length)
      return

    this.stockService.getStock(undefined, allRelatedProducts.map((p: any) => p.id))
  }

  editSubKeys(payload: any, deliveryDate: string, firebaseOrder: any): void | null {
    const sessionStored: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION) || null;
    if (!sessionStored?.accountInfo?.id) return

    let { updateData } = payload;

    // Validate if firebase order will be empty:
    let hasData = false;
    for (const key of Object.keys(updateData)) {
      if (updateData[key].length) {
        hasData = true;
        break;
      } else {
        if (key.includes('common')) {
          if (firebaseOrder?.products?.subscription?.length) {
            hasData = true;
            break
          }
        } else {
          if (firebaseOrder?.products?.common?.length) {
            hasData = true;
            break
          }
        }
      }
    }

    if (hasData) {
      // Setup cutoff date:
      updateData = { ...updateData, ...this.setUpDateForFirebase() }
    } else {
      // Clear cutoff date:
      updateData = { ...updateData, cutoffDate: null }
    }

    const firebasePayload = {
      collection: FIREBASE_COLLECTIONS.ORDERS,
      docId: `${sessionStored.accountInfo.id.toString()}-${deliveryDate.replaceAll('-', '')}`,
      updateData
    }

    this.firebaseCrudService.updateSubkeys(firebasePayload);
  }

  editSubKey(payload: any, deliveryDate: string, editCutOffKey = true, clearCutoff = false, clearEventCreated = false) {
    const sessionStored: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION) || null;
    if (!sessionStored?.accountInfo?.id) return

    const { subKeyPath, newValue } = payload;
    let updateData: any = {};
    updateData[subKeyPath] = newValue;

    // Setup cutoff date:
    if (editCutOffKey) {
      if (clearCutoff)
        updateData = { ...updateData, cutoffDate: null }
      else
        updateData = { ...updateData, ...this.setUpDateForFirebase() }
    }

    if (clearEventCreated) {
      updateData = { ...updateData, eventCreated: false }
    }

    const eventCreated = this.notSavedCartEventCreated();

    // If the firebase order does not have "eventCreate" as "true", it should be sent as "false":
    if (!eventCreated) {
      updateData = { ...updateData, eventCreated: false }
    }

    const firebasePayload = {
      collection: FIREBASE_COLLECTIONS.ORDERS,
      docId: `${sessionStored.accountInfo.id.toString()}-${deliveryDate.replaceAll('-', '')}`,
      updateData
    };

    return this.firebaseCrudService.updateSubkeys(firebasePayload);
  }

  setUpDateForFirebase() {
    const deliveryInfo = this.deliveriesService.deliveryZoneInfo();
    if (!deliveryInfo) return {};

    let cutoffObject = { date: deliveryInfo.cutoffDate, time: deliveryInfo.cutoffTime }
    if (deliveryInfo.order) {
      cutoffObject.date = deliveryInfo.order.cutoffDate;
      cutoffObject.time = deliveryInfo.order.cutoffTime;
    }

    // Combina las dos cadenas en una sola
    const dateTimeStr = `${cutoffObject.date} ${cutoffObject.time}`;

    // Extraer partes de la fecha y la hora
    const [datePart, time, period] = dateTimeStr.split(/[\s]+/);
    const [hours, minutes] = time.split(':').map(Number);
    let adjustedHours = hours;

    if (period.toLowerCase() === "p.m." && hours !== 12) {
      adjustedHours = hours + 12;
    } else if (period.toLowerCase() === "a.m." && hours === 12) {
      adjustedHours = 0;
    }

    // Crear una cadena en formato ISO y convertirla a UTC
    const formattedDateTimeStr = `${datePart}T${adjustedHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00Z`;

    const utcDate = DateTime.fromISO(formattedDateTimeStr, { zone: 'UTC' });

    // Convertir a milisegundos desde epoch
    const utcMillis = utcDate.toMillis();

    return { cutoffDate: utcMillis };
  }

  /**
   * Updates or creates an order in Navego.
   *
   * @param {Object} params - The parameters for updating or creating the order.
   * @param {FirebaseOrder} [params.order] - The order object containing the order details.
   * @param {WritableSignal<any>} [params.firebaseOrderSignal] - Optional signal to update the order in Firebase.
   * @param {number} [params.orderId] - Optional ID of the order to update. If not provided, a new order will be created.
   * @param {boolean} [params.getStock=true] - Flag indicating whether to retrieve stock information.
   * @param {boolean} [params.showDefaultMessage=true] - Flag indicating whether to show a default message upon completion.
   * @param {WritableSignal<string>} [params.coupon] - Optional coupon code to apply to the order.
   * @param {boolean} [params.updateFirebaseOrder=true] - Flag indicating whether to update the order in Firebase after processing the response.
   *
   * @throws Will throw an error if the session is not found or is invalid.
   *
   * @returns {Observable<ApiResponse<any>>} - An observable that emits the API response.
   */
  updateOrder(
    {
      order,
      firebaseOrderSignal,
      orderId,
      getStock = true,
      showDefaultMessage = true,
      coupon,
      deliveryInfo,
      isUpdatingBundle = false,
    }: UpdateOrderParams,
    deliveryDate: string): Observable<ApiResponse<any>> {

    try {

      const sessionStored: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION);
      if (!sessionStored || !Object.keys(sessionStored).length)
        throw new Error("ERROR: SESSION NOT FOUNDED");

      const { donation = null, tip = null } = order?.paymentDetails || {}
      const { commonProducts, subscriptionProducts } = this.setUpOrderProducts(order);

      const logBodyRequest = {
        paymentDetails: {
          donation,
          tip,
          coupon: coupon ? coupon() : null
        },
        products: [
          ...commonProducts,
          ...subscriptionProducts
        ]
      }

      const log = {
        name: isUpdatingBundle ? 'Bundle Saved' : 'Order Submitted/Updated',
        content: this.generateOrderHtmlTableFromJson(logBodyRequest)
      };

      const bodyRequest: any = {
        paymentDetails: {
          donation,
          tip
        },
        products: [
          ...commonProducts.map((c: any) => {
            delete c.name;
            return c
          }),
          ...subscriptionProducts.map((s: any) => {
            delete s.name;
            s.subscription = {
              startDate: s.subscription.startDate,
              frequencyId: s.subscription.frequencyId
            };
            return s;
          })
        ],
        log
      };

      if (coupon)
        bodyRequest.paymentDetails.coupon = coupon();

      const endpoint = orderId ? `${this.endpoints.order}/${orderId}` : this.endpoints.order;
      const method = orderId ? 'PATCH' : 'POST';

      if (!orderId)
        bodyRequest.deliveryDate = deliveryDate;

      const params: RequestHandlerParams = {
        endpoint,
        method,
        body: bodyRequest,
        apiV3: true
      }

      return this.apiService.handleRequest<ApiResponse<any>>(params).pipe(
        tap((response: any) => this.updateOrderResponseHandler(response, firebaseOrderSignal, showDefaultMessage, getStock, 'orderProducts', coupon, deliveryInfo, isUpdatingBundle, deliveryDate)),
      )

    } catch (error) {
      return of<ApiResponse<any>>();
    }
  }

  /**
   * Generates order as an HTML table from the given JSON object for log registration.
   *
   * @param {Object} jsonData - The JSON object containing payment details and products.
   * @returns {string} - The generated HTML table as a string.
   */
  private generateOrderHtmlTableFromJson(jsonData: any): string {
    const { paymentDetails, products } = jsonData;

    // Start building the HTML table
    let html = '<table class="table table-bordered o_table">';

    // Add payment details
    html += '<tr><th colspan="2" style="font-weight: bolder; text-align: center">Payment Details</th></tr>';
    for (const [key, value] of Object.entries(paymentDetails)) {
      if (typeof value === 'object' && value !== null) {
        html += `<tr><td style="text-transform:capitalize">${key}</td><td>${paymentDetails[key].isRecurrent ? 'Recurrent:' : ''} <b>$ ${paymentDetails[key].amount}</b></td></tr>`;
      } else {
        html += `<tr><td style="text-transform:capitalize">${key}</td><td>${value || ''}</td></tr>`;
      }
    }

    // Add product details
    html += '<tr><th colspan="2" style="font-weight: bolder; text-align: center">Products</th></tr>';
    products.forEach((product: any, index: number) => {
      html += `<tr><td colspan="2" style="font-weight: bolder">${product.name}</td></tr>`;
      for (const [key, value] of Object.entries(product)) {
        if (['id', 'packageId', 'variantId', 'name'].includes(key)) continue;
        if (key === 'bundle' && Array.isArray(value)) {
          html += `<tr><td>Bundle</td><td>`;
          value.forEach((bundleItem: any, bundleIndex: number) => {
            for (const [bundleKey, bundleValue] of Object.entries(bundleItem)) {
              if (['id', 'packageId'].includes(bundleKey)) continue;
              html += `${bundleKey}: ${bundleValue}<br>`;
            }
            html += '<br>';
          });
          html += '</td></tr>';
        } else if (typeof value === 'object' && value !== null) {
          const val: any = value;
          html += `<tr><td style="text-transform:capitalize">${key}</td><td>${value ? `Subscribed ${val.frequencyName}${val.startDate ? ` from ${val.startDate}` : ''}` : 'Non-applicable'}</td></tr>`;
          for (const [subKey, subValue] of Object.entries(value)) {
            html += `${subKey}: ${subValue}<br>`;
          }
          html += '</td></tr>';
        } else {
          html += `<tr><td style="text-transform:capitalize">${key}</td><td>${value || (key === 'subscription' ? 'Non-applicable' : '')}</td></tr>`;
        }
      }
    });
    // Close the HTML table
    html += '</table>';

    return html;
  }

  /**
 * Generates product as an HTML table from the given JSON object for log registration.
 *
 * @param {Object} jsonData - The JSON object containing the bundle and product details.
 * @returns {string} - The generated HTML table as a string.
 */
  private generateProductHtmlTableFromJson(jsonData: any): string {
    const { updatedAt, bundle, category, id, img, isASubscription, isFromCard, name, package: pkg, presentation, price, productUrl, quantity, size, subCategory, subscription, taxes, totalPrice, variant } = jsonData;

    // Start building the HTML table
    let html = '<table class="table table-bordered o_table">';

    // Add general details
    html += '<tr><th colspan="2" class="text-center" style="font-weight: bolder">General Details</th></tr>';
    html += `<tr><td>Product Name</td><td>${name}</td></tr>`;
    html += `<tr><td>Quantity</td><td>${quantity}</td></tr>`;
    html += `<tr><td>Size</td><td>${size}</td></tr>`;
    html += `<tr><td style="text-transform:capitalize">Subscription</td><td>${subscription ? `Subscribed ${subscription.frequencyName}${subscription.startDate ? ` from ${subscription.startDate}` : ''}` : 'Non-applicable'}</td></tr>`;

    // Add bundle items
    if (bundle?.items?.length) {
      html += '<tr><th colspan="2" class="text-center" style="font-weight: bolder">Bundle Items</th></tr>';
      bundle.items.forEach((item: any, index: number) => {
        html += `<tr><td style="text-transform:capitalize">${item.name}</td><td>${item.quantity}</td></tr>`;
      });
    }

    // Add variant details
    html += '<tr><th colspan="2" class="text-center" style="font-weight: bolder">Variant Details</th></tr>';
    for (const [key, value] of Object.entries(variant)) {
      if (!(['attribute'].includes(key))) continue;
      const val: any = value;
      html += `<tr><td style="text-transform:capitalize">${key}</td><td>${val ? `${val.name}: ${val.value.name}` : ''}</td></tr>`;
    }

    // Close the HTML table
    html += '</table>';

    return html;
  }

  /**
   * Handles the response from the updateOrder API call.
   *
   * @param {ApiResponse<any>} response - The response object from the API call.
   * @param {WritableSignal<any>} [firebaseOrderSignal] - Optional signal to reset the order in Firebase.
   * @param {boolean} [showDefaultMessage=true] - Flag indicating whether to show a default success message.
   * @param {boolean} [getStock=true] - Flag indicating whether to retrieve stock information.
   * @param {string} [subKeyPath='orderProducts.products'] - Path to the sub-key to be edited in Firebase.
   * @param {WritableSignal<string>} [coupon] - Optional coupon code signal to reset.
   *
   * Resets the coupon signal if provided.
   * Closes the modal indicating order not submitted.
   * Opens a modal if the previous order is locked by cut-off date.
   * Edits the specified sub-key path in Firebase.
   * Shows a success notification if showDefaultMessage is true.
   * Resets the Firebase order signal if provided.
   * Sets the order data in the odooOrder signal.
   * Retrieves stock information if getStock is true.
   * Validates the out-of-stock products in the response.
   */
  private updateOrderResponseHandler(
    response: ApiResponse<any>,
    firebaseOrderSignal?: WritableSignal<FirebaseOrder[]>,
    showDefaultMessage: boolean = true,
    getStock: boolean = true,
    subKeyPath: string = 'orderProducts.products',
    coupon?: WritableSignal<string>,
    deliveryInfo?: { deliveryDateText: string, cutoffDateText: string, thanksMessage: string },
    isUpdatingBundle: boolean = false,
    deliveryDate = '') {

    // Register klaviyo event:
    const session = this.getSession();
    if (session && session.deliveryInfo?.deliveryDate && !isUpdatingBundle) {
      const formattedDate = formatDateToReadableString(session.deliveryInfo?.deliveryDate).mmddyyyyFormat.replace(/\//g, "-");
      this.klaviyoService.trackEvent(KLAVIYOEVENTS.OrderSubmitted, {
        'Delivery Date': formattedDate,
        'Cut-Off Day and Time': `${session.deliveryInfo?.cutoffDay} ${session.deliveryInfo?.cutoffTime}`
      })
    };

    if (coupon) coupon.set('');

    if (deliveryInfo?.cutoffDateText && deliveryInfo?.deliveryDateText) {
      openModalTanksForSubmit(this.modalContentService, deliveryInfo);
    }

    this.openModalOrderNotSubmitted.set(false);

    if (response?.data?.previousOrder?.isLockedByCutOffDate)
      return this.openModalPastCutOff(deliveryDate);

    if (!isUpdatingBundle) {
      const payload = {
        subKeyPath,
        newValue: null
      }

      this.editSubKey(payload, deliveryDate, true, true, true);
    }

    if (showDefaultMessage)
      this.notificationService.show({ text: 'Order updated successfully', type: 'success' });

    if (firebaseOrderSignal) {

      const index = firebaseOrderSignal()
        .findIndex(x => x.deliveryDate === deliveryDate);

      if (index !== -1) {
        firebaseOrderSignal.update(values => {
          values.splice(index, 1);
          return [...values];
        });
      }
    }

    this.odooOrder.update(val => {

      let id = '';

      if (response.data.order)
        id = response.data.order.deliveryInfo.deliveryDate;
      else
        id = response.data.deliveryInfo.deliveryDate;

      const odooIndex = val
        .findIndex(x => x.deliveryInfo.deliveryDate === id);

      if (odooIndex !== -1)
        val[odooIndex] = response?.data?.order ?? response?.data;
      else
        val.push(response?.data);

      return [...val];
    });

    if (getStock)
      this.getStock(deliveryDate);

    const currentOdooOrder: any = this.odooOrder().find(x => x.deliveryInfo.deliveryDate === deliveryDate);

    this.validateOutOfStockResponse(currentOdooOrder?.outOfStockProducts);

    this.#validateUnavailableProducts(currentOdooOrder?.notAvailableProducts);
  }

  private setUpOrderProducts(order: any) {

    const setUpProducts = (order: any, subscription: boolean = false) => {
      const type = subscription ? 'subscription' : 'common';
      return !order?.products?.[type]?.length ? [] :
        order.products[type]
          .sort((a: any, b: any) => (b.updatedAt || 0) - (a.updatedAt || 0))
          .map((product: FireBaseProductOrder) => {
            const productBundle = !product?.bundle || !product?.bundle?.items?.length ? null :
              product.bundle.items?.filter((bundleProdut: any) => !bundleProdut.isRemoved).map((bundleProduct: any) => ({
                id: bundleProduct.id,
                name: bundleProduct.name,
                quantity: bundleProduct.quantity,
                packageId: bundleProduct.packageId || null
              }))
            const productSubscription = !subscription ? null : !product?.subscription ? null : {
              startDate: product?.subscription?.startDate ? product.subscription.startDate : null,
              frequencyId: product?.subscription?.frequency.id,
              frequencyName: product?.subscription?.frequency.name,
            };
            return {
              bundle: productBundle,
              id: product.id,
              name: product.name,
              quantity: product.quantity,
              packageId: product?.package?.id ?? null,
              subscription: productSubscription,
              variantId: product?.variant?.id || null,
            }
          });
    }

    const commonProducts = setUpProducts(order);
    const subscriptionProducts = setUpProducts(order, true)

    return {
      commonProducts,
      subscriptionProducts
    }
  }

  private validateOutOfStockResponse(products: { name: string, variantName: string, productExists: boolean }[]) {
    if (!products?.length) return;
    const outOfStock = products.map(p => {
      return {
        productName: p.name,
        productVariant: p.variantName,
        outOfStock: !p.productExists,
        limitExceeded: p.productExists
      };
    });

    const len = products.length;

    const productsOOS = products.filter(p => !p.productExists).length;
    const productsLR = products.filter(p => p.productExists).length;
    const both = !!productsOOS && !!productsLR;

    this.modalContentService.openModal(ModalContentTypes.BUY_AGAIN_WARNING, {
      title: `Update To Product Availability`,
      textContent: ` Hi, unfortunately, we don't have enough inventory for all of the items in your cart. Please review the table below to see what we've updated.`,
      orderHistoryWarning: { products: outOfStock, showThirdColumn: true, fromOrder: true }
    })
  }

  #validateUnavailableProducts(products: { name: string, variantName: string, productExists: boolean }[]) {
    if (!products?.length) return;
    const unavailable = products.map(p => {
      return {
        productName: p.name,
        productVariant: p.variantName,
        outOfStock: true,
        limitExceeded: false
      };
    });

    this.modalContentService.openModal(ModalContentTypes.BUY_AGAIN_WARNING, {
      title: `Update To Product Availability`,
      textContent: `Hi, unfortunately, the following product(s) are no longer available for purchase. Please review the table below to see what we've updated.`,
      orderHistoryWarning: { products: unavailable, showThirdColumn: true, fromOrder: true }
    })
  }

  private getFirebaseOrder(deliveryDate: string): any {
    let products = { common: [], subscription: [] }
    let paymentDetails = { tip: null, donation: null }

    const config = this.configByOrder().get(deliveryDate);

    if (config?.notSavedOrder) {
      products = config.notSavedOrder.products ?? null
      paymentDetails = config.notSavedOrder.paymentDetails ?? null
    }

    const orderProducts = {
      paymentDetails,
      products
    }

    return orderProducts;
  }

  addProductToFirebaseOrder(productFromCard: any, showSucceddMessage = false, overwriteOdooQuantity = false, deliveryDate: string) {

    if (!productFromCard)
      throw new Error(`ERROR: product(${productFromCard}) not received`);

    const sessionStored = this.getSession();
    const productKeyType = productFromCard.isASubscription ? 'subscription' : 'common';
    // Retrieves orders from Odoo and Firebase
    const odooOrder = this.odooOrder().find(x => x.deliveryInfo.deliveryDate === deliveryDate);
    const firebaseOrder = this.getFirebaseOrder(deliveryDate);
    const mapKey = productFromCard.variant.id;

    // Updates the product quantity in Firebase order if it exists
    if (!this.updateProductFromFirebase(firebaseOrder, productKeyType, mapKey, productFromCard) &&
      !this.updateProductFromOdoo(odooOrder, firebaseOrder, productKeyType, mapKey, productFromCard, overwriteOdooQuantity)) {
      // If product is not found in existing orders, add it to Firebase order
      firebaseOrder.products[productKeyType].push(productFromCard);
      // ... and track the klaviyo and Navego log event.
      this.klaviyoService.trackEvent(KLAVIYOEVENTS.ProductAddedToCart, this.setupProductForKlaviyo(productFromCard));
      this.trackAddedOrDeletedProductLog(productFromCard, false, deliveryDate);
    }

    this.saveOrUpdateFirebaseOrder(firebaseOrder, productKeyType, sessionStored, deliveryDate);
    if (showSucceddMessage)
      this.notificationService.show({ text: 'Product added successfully', type: 'success' });
  }

  getSession(): Session | null {
    const session: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION);
    if (!session || !Object.keys(session).length) {
      throw new Error("ERROR: SESSION NOT FOUNDED");
    }
    return session;
  }

  updateProductFromFirebase(firebaseOrder: any, productKeyType: string, mapKey: number | string, productFromCard: any) {

    const firebaseProductsMap = arrayToMapMultiKey(['variant.id', 'package.id'], firebaseOrder.products[productKeyType]);
    // If the product has packages, let's search by package id:
    if (productFromCard.package?.id)
      mapKey = `${mapKey}_${productFromCard.package?.id}`
    if (firebaseProductsMap && firebaseProductsMap.has(mapKey)) {
      const orderProduct = firebaseProductsMap.get(mapKey);
      orderProduct.quantity += productFromCard.quantity;
      orderProduct.subscription = productFromCard.subscription;
      firebaseOrder.products[productKeyType] = mapToArray(firebaseProductsMap);
      return true;
    }


    return false;
  }

  updateProductFromOdoo(odooOrder: any, firebaseOrder: any, productKeyType: string, mapKey: number | string, productFromCard: any, overWriteOdooQuantity: boolean = false) {

    if (odooOrder?.products?.[productKeyType]) {
      const odooProductsMap = arrayToMapMultiKey(['variant.id', 'package.id'], odooOrder.products[productKeyType]);
      // If the product has packages, let's search by package id:
      if (productFromCard.package?.id)
        mapKey = `${mapKey}_${productFromCard.package?.id}`
      if (odooProductsMap && odooProductsMap.has(mapKey)) {
        const orderProduct = JSON.parse(JSON.stringify(odooProductsMap.get(mapKey)));
        if (overWriteOdooQuantity)
          orderProduct.quantity = productFromCard.quantity;
        else
          orderProduct.quantity += productFromCard.quantity;
        firebaseOrder.products[productKeyType].push(orderProduct);
        return true;
      }
    }
    return false;
  }

  saveOrUpdateFirebaseOrder(firebaseOrder: any, productKeyType: string, sessionStored: any, deliveryDate: string) {

    const config = this.configByOrder().get(deliveryDate);

    if (!config?.notSavedOrder) {

      let updateData: any = {
        orderProducts: {
          ...firebaseOrder,
          deliveryDate
        }
      };

      // Setup cutoff date:
      updateData = { ...updateData, ...this.setUpDateForFirebase(), eventCreated: false }
      this.firebaseCrudService.add(
        FIREBASE_COLLECTIONS.ORDERS,
        `${sessionStored.accountInfo.id}-${deliveryDate.replaceAll('-', '')}`,
        updateData
      );
    } else {
      const payload = {
        subKeyPath: `orderProducts.products.${productKeyType}`,
        newValue: firebaseOrder.products[productKeyType]
      };
      this.editSubKey(payload, deliveryDate);
    }
  }

  deleteProductOrder(orderId: number, lineId: number, deliveryDate: string) {
    const sessionStored: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION);
    if (!sessionStored || !Object.keys(sessionStored).length)
      throw new Error("ERROR: SESSION NOT FOUNDED");

    const params: RequestHandlerParams = {
      endpoint: this.endpoints.order + `/${orderId}/line/${lineId}`,
      method: 'DELETE',
      apiV3: true
    }

    return this.apiService.handleRequest<ApiResponse<any>>(params).pipe(
      tap((response: any) => {
        if (response?.data?.previousOrder?.isLockedByCutOffDate)
          return this.openModalPastCutOff(deliveryDate)

        this.notificationService.show({ text: 'Product removed from order successfully', type: 'success' });

        this.odooOrder.update(val => {

          const odooIndex = val.findIndex(x => x.id === response?.data?.order.id);

          if (odooIndex !== -1)
            val[odooIndex] = response?.data?.order;

          return [...val];
        });

        this.getStock(deliveryDate);
      }),
    )
  }

  applyCouponWithOrder(coupon: WritableSignal<string>, deliveryDate: string, orderId: number) {
    const sessionStored: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION);
    if (!sessionStored || !Object.keys(sessionStored).length)
      throw new Error("ERROR: SESSION NOT FOUNDED");

    const params: RequestHandlerParams = {
      endpoint: this.endpoints.coupon,
      method: 'POST',
      body: { coupon: coupon(), orderId },
      apiV3: true
    }

    return this.apiService.handleRequest<ApiResponse<any>>(params).pipe(
      tap((response: any) => {
        coupon.set('');
        this.notificationService.show({ text: response?.message, type: 'success' });

        this.odooOrder.update(val => {

          const odooIndex = val.findIndex(x => x.id === response?.data.id);

          if (odooIndex !== -1)
            val[odooIndex] = response?.data;

          return [...val];
        });

        this.getStock(deliveryDate);
      }),
    ).subscribe()
  }

  skipOrder(args: {
    orderId: number;
    isSkipping: boolean;
    donationAmount: number | any
  }, getStock: boolean,
    deliveryDate: string) {
    const sessionStored: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION);
    if (!sessionStored || !Object.keys(sessionStored).length)
      throw new Error("ERROR: SESSION NOT FOUNDED");

    const { orderId, isSkipping, donationAmount } = args;
    const donation = !donationAmount ? null : {
      amount: donationAmount,
      isRecurrent: false,
    }
    const params: RequestHandlerParams = {
      endpoint: this.endpoints.order + `/${orderId}/skip`,
      method: 'POST',
      body: {
        isSkipping,
        donation
      },
      apiV3: true
    }

    return this.apiService.handleRequest<ApiResponse<any>>(params).pipe(
      tap((response: any) => {

        if (response?.data?.previousOrder?.isLockedByCutOffDate)
          return this.openModalPastCutOff(deliveryDate)

        const payload = {
          subKeyPath: `orderProducts`,
          newValue: null
        }
        const actionPhrase = isSkipping ? 'applied' : 'removed';
        this.editSubKey(payload, deliveryDate, true, true);
        this.notificationService.show({ text: `Order skip ${actionPhrase} successfully`, type: 'success' });

        this.odooOrder.update(val => {

          const odooIndex = val.findIndex(x => x.id === response?.data?.order.id);

          if (odooIndex !== -1)
            val[odooIndex] = response?.data?.order;

          return [...val];
        });

        if (getStock)
          this.getStock(deliveryDate)

        this.deliveriesService.getAvailableDeliveryDates();
      }),

      tap(() => (this.deliveriesService.getDeliveryZoneInfo().subscribe())),
    )
  }

  private openModalPastCutOff(deliveryDate: string) {
    this.modalContentService.openModal(ModalContentTypes.PAST_CUT_OFF, { closeable: true })
      .closed
      .subscribe((res) => {
        if (res.understoodClicked) {
          const payload = {
            subKeyPath: `orderProducts`,
            newValue: null
          }
          this.editSubKey(payload, deliveryDate, true, true);
          window.location.reload()
        }
      })
  }

  private updateProductInFirebase(product: any, productList: any[]): any[] {
    let isProductInList: boolean = false;
    const newProductList = productList.map((productItem) => {
      if (productItem.id === product.id) {
        isProductInList = true;
        return product;
      }
      return productItem;
    });

    if (!isProductInList)
      newProductList.push(product)

    return newProductList
  }

  // updateFirebaseProduct(productKey: string, product: any, firebaseOrder: any) {
  //   const firebaseProductsOrder = firebaseOrder?.products?.[productKey] ?? [];
  //   const newValue = this.updateProductInFirebase(product, firebaseProductsOrder);
  //   const payload = {
  //     subKeyPath: `orderProducts.products.${productKey}`,
  //     newValue
  //   }

  //   this.editSubKey(payload);
  // }

  // removeFirebaseProduct(productKey: string, product: any, firebaseOrder: any) {
  //   const firebaseProductsOrder = firebaseOrder?.products?.[productKey] || [];
  //   if (!firebaseProductsOrder?.length)
  //     return

  //   const newValue = this.removeProductFromArray(product, firebaseProductsOrder);
  //   const payload = {
  //     subKeyPath: `orderProducts.products.${productKey}`,
  //     newValue
  //   }

  //   this.editSubKey(payload);
  //   this.trackAddedOrDeletedProductLog(product, true);
  // }

  trackAddedOrDeletedProductLog(product: any, removed: boolean, deliveryDate: string) {

    const currentOdooOrder = this.odooOrder().find(x => x.deliveryInfo.deliveryDate === deliveryDate);

    const content = this.generateProductHtmlTableFromJson(product);
    const params: RequestHandlerParams = {
      endpoint: this.endpoints.ecommerceLogs,
      method: 'POST',
      apiV3: true,
      body: {
        name: removed ? 'Product Deleted (from unsubmitted order)' : 'Product Added to Order (not yet saved)',
        content,
        orderId: currentOdooOrder?.id || null
      },
      showErrorMessage: false
    };

    this.apiService.handleRequest(params).subscribe();
  }

  private removeProductFromArray(product: any, productList: any[]): any[] {
    return productList.filter((productItem) => productItem.package?.id && product.package?.id ? product.package.id !== productItem.package.id : productItem.variant.id !== product.variant.id);
  }

  checkIfExistsProductInFirebaseOrder(product: any, firebaseOrder: any, bundleEditionType: BundleEditionType) {
    const mapKey = product?.variant?.id;
    if (!firebaseOrder || !firebaseOrder?.products?.[bundleEditionType])
      return null

    const firebaseProductsMap = arrayToMap('variant.id', firebaseOrder.products[bundleEditionType]);
    if (firebaseProductsMap.has(mapKey))
      return firebaseProductsMap.get(mapKey);

    return null;
  }

  // addBundleToFirebaseOrder(firebaseOrder: any, productFromCard: any, bundleEditionType: BundleEditionType) {
  //   if (!productFromCard)
  //     return throwError(new Error(`ERROR: product(${productFromCard}) not received`));

  //   const mapKey = productFromCard.variant.id;
  //   const updatedFirebaseOrder = this.updateBundleFromFirebase(firebaseOrder, mapKey, productFromCard, bundleEditionType)

  //   const sessionStored = this.getSession();
  //   const productKeyType = productFromCard?.isASubscription ? 'subscription' : 'common';
  //   const res = this.saveOrUpdateFirebaseOrder2(updatedFirebaseOrder, productKeyType, sessionStored);

  //   return res;
  // }

  // updateBundleFromFirebase(firebaseOrder: any, mapKey: number, product: any, bundleEditionType: BundleEditionType) {
  //   // const productKeyType = product?.isSubscription || product?.isASubscription ? 'subscription' : 'common';

  //   if (!firebaseOrder)
  //     firebaseOrder = {}

  //   if (!firebaseOrder?.products)
  //     firebaseOrder['products'] = {};

  //   if (!Array.isArray(firebaseOrder.products?.[bundleEditionType]))
  //     firebaseOrder.products[bundleEditionType] = [];

  //   const firebaseProductsMap = arrayToMap('variant.id', firebaseOrder.products[bundleEditionType]);

  //   if (firebaseProductsMap?.has(mapKey)) {
  //     firebaseProductsMap.set(mapKey, product);
  //     firebaseOrder.products[bundleEditionType] = mapToArray(firebaseProductsMap);
  //   } else {
  //     firebaseOrder.products[bundleEditionType].push(product);
  //     this.klaviyoService.trackEvent(KLAVIYOEVENTS.ProductAddedToCart, this.setupProductForKlaviyo(product));
  //     this.trackAddedOrDeletedProductLog(product, false, );
  //   }

  //   return firebaseOrder;
  // }

  // saveOrUpdateFirebaseOrder2(firebaseOrder: any, productKeyType: string, sessionStored: any) {
  //   let response: any;
  //   if (!this.notSavedOrder()) {
  //     let updateData: any = { orderProducts: firebaseOrder }
  //     // Setup cutoff date:
  //     updateData = { ...updateData, ...this.setUpDateForFirebase(), eventCreated: false }
  //     response = this.firebaseCrudService.add(
  //       FIREBASE_COLLECTIONS.ORDERS,
  //       sessionStored.accountInfo.id,
  //       updateData
  //     )
  //   } else {
  //     const payload = {
  //       subKeyPath: `orderProducts.products.${productKeyType}`,
  //       newValue: firebaseOrder.products[productKeyType]
  //     };
  //     response = this.editSubKey(payload);
  //   }

  //   return from(response)
  // }

  setupProductForKlaviyo(product: any) {
    return {
      bundle: product.bundle?.items || [],
      category: product.category,
      id: product.id,
      name: product.name,
      price: product.price,
      productUrl: product.productUrl,
      size: product.size || '',
      subcategory: product.subCategory,
      originalPrice: product.originalPrice,
      producer: product.producer,
      specialCategory: product.specialCategory,
      tags: product.tags,
      variant: product.variant,
      package: product.package
    }
  }

  // getSubTotal(products: any) {

  //   const allProducts = [
  //     ...products?.common ?? [],
  //     ...products?.subscription ?? []
  //   ];

  //   if (!allProducts.length)
  //     return 0.00

  //   // Calculate the subtotal of the unsaved products in the current order
  //   const subtotalFromCurrentOrder = allProducts.reduce((total: number, product: any) => {
  //     return total + this.calculateProductTotalPrice(product);
  //   }, 0);

  //   const couponAmount = this.checkPendingChanges(products) ? 0 : this.odooOrder()?.paymentDetails?.coupons?.[0]?.amount || 0;
  //   const subTotal = +subtotalFromCurrentOrder - (-couponAmount)
  //   return subTotal > 0 ? subTotal : 0;
  // }

  // private calculateProductTotalPrice(product: any): number {
  //   let totalPrice = 0;
  //   if (product?.bundle?.items) {
  //     if (product.bundle.isFixed) {
  //       totalPrice = +product.price;
  //       const premiumItems = product?.bundle?.items?.filter((pi: any) => pi.isPremiumAddon);
  //       if (premiumItems?.length) {
  //         totalPrice += premiumItems.reduce((total: number, bundleItem: any) => {
  //           const bundleItemPrice = (+bundleItem?.price * +bundleItem?.quantity) || 0
  //           return total + bundleItemPrice
  //         }, 0);
  //       }
  //     } else if (product?.bundle?.items?.length)
  //       totalPrice = product.bundle.items.reduce((total: number, bundleItem: any) => {
  //         const bundleItemPrice = (bundleItem?.isRemoved ? 0 : (+bundleItem?.price * +bundleItem?.quantity) || 0)
  //         return total + bundleItemPrice
  //       }, 0);
  //   }
  //   if (totalPrice === 0)
  //     totalPrice = product.price

  //   return totalPrice * product.quantity;
  // }

  // private checkPendingChanges(products: any) {
  //   return !!(products &&
  //     [...products?.common, ...products?.subscription].some(product => product.hasPendingChanges));
  // }

  changeDeliveryDate(orderId: string, deliveryDate: string) {

    const params: RequestHandlerParams = {
      endpoint: `${this.endpoints.order}/${orderId}/delivery-date`,
      method: 'PATCH',
      body: {
        deliveryDate
      },
      apiV3: true
    };

    return this.apiService
      .handleRequest<ApiResponse<any>>(params)
      .pipe(
        tap(response => this.notificationService.show({ text: response.message, type: 'success' })),
        tap(() => this.getOrder(true, deliveryDate))
      )
      .subscribe();
  }

  verifyBundlesDeliveryDate(orderId: number, deliveryDate: string) {

    const params: RequestHandlerParams = {
      endpoint: `${this.endpoints.order}/${orderId}/delivery-date?deliveryDate=${deliveryDate}`,
      method: 'GET',
      apiV3: true
    };

    return this.apiService
      .handleRequest<ApiResponse<any>>(params);
  }

  savePreOrderProduct(preOrderData: PreOrderPayloadProduct) {
    const params: RequestHandlerParams = {
      endpoint: `${this.endpoints.order}${this.endpoints.preOrder}`,
      method: 'POST',
      apiV3: true,
      body: preOrderData
    };

    return this.apiService.handleRequest(params);
  }

  getPreOrders() {
    const params: RequestHandlerParams = {
      endpoint: `${this.endpoints.order}${this.endpoints.preOrder}`,
      method: 'GET',
      apiV3: true,
      showErrorMessage: false
    };

    return this.apiService.handleRequest<ApiResponse<PreOrderedProduct[]>>(params).pipe(
      filter((data) => !!data.data.length),
      map((data) => {
        const res = data.data.map(p => {
          p.preOrder = this.#setUpPreorderProductData(p.preOrder, p.quantity);
          return p;
        })

        return res;
      })
    )
  }

  #setUpPreorderProductData(preOrder: PreOrderData | null, quantity: number): PreOrderData | null {
    if (!preOrder) return null;
    const currentDate = DateTime.local().toUTC();
    const currentStringDate = `${currentDate.year}-${currentDate.month.toString().padStart(2, '0')}-${currentDate.day.toString().padStart(2, '0')}`;
    const currentReadableDate = formatDateToReadableString(currentStringDate);
    const readableEndDate = formatDateToReadableString(preOrder.endDate);
    return {
      ...preOrder,
      _readableEndDate: readableEndDate,
      _readableStartDeliveryDate: formatDateToReadableString(preOrder.startDeliveryDate),
      _readableEndDeliveryDate: formatDateToReadableString(preOrder.endDeliveryDate),
      deposit: preOrder.deposit * quantity,
      canUpdateProduct: (readableEndDate?.timestamp || (currentReadableDate.timestamp + 1)) > currentReadableDate.timestamp
    } as PreOrderData
  }

  cancelOrderById(orderId: number) {
    const params: RequestHandlerParams = {
      endpoint: `${this.endpoints.order}/${orderId}`,
      method: 'DELETE',
      apiV3: true,
      isVoid: true
    };

    return this.apiService.handleRequest<void>(params).pipe(
      tap(() => this.getOrder()),
      tap(() => this.deliveriesService.getDeliveryZoneInfo().subscribe()),
      tap(() => this.notificationService.show({ text: 'Your order has been successfully cancelled', type: 'success' }))
    )
  }

  updatePreOrderLineQuantity(data: { lineId: number, quantity: number }) {
    const { lineId, quantity } = data;
    const params: RequestHandlerParams = {
      endpoint: `${this.endpoints.order}${this.endpoints.preOrder}/${lineId}`,
      method: 'PATCH',
      apiV3: true,
      body: {
        quantity
      },
    };

    return this.apiService.handleRequest<ApiResponse<void>>(params).pipe(
      tap((response) => this.notificationService.show({ text: response.message, type: 'success' }))
    );
  }
}
