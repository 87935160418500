import { environment } from "../../environments/environment";

export const depositConfig = {
    fee_deposit: {
        title: 'Reservation Fee Deposit',
        message: (deposit: number) => `An additional fee deposit of <b>$${deposit.toFixed(2)}</b> is required for each unit of this seasonal product to secure your reservation. Please note that this deposit is <b>non-refundable</b> if you cancel your order.`,
        additionalInfo: 'This deposit secures your pre-order and will <b>not</b> be deducted from your final payment.'
    },
    advance_deposit: {
        title: 'Advanced Payment Deposit',
        message: (deposit: number) => `An advanced deposit of <b>$${deposit.toFixed(2)}</b> is required per unit, and <b>will be deducted from your total order amount on the week of delivery.`,
        additionalInfo: 'This deposit <b>will be deducted from your final payment</b> but will not be refunded if the order is canceled.'
    },
    preorderedProductsDisclaimer: `
        <p>Please note that pre-orders cannot be canceled through the website, and quantities can only be increased. Increasing the quantity may also raise the deposit amount. Decreasing the quantity is not allowed. If you need assistance or have any questions, please contact us at <a class="fw-semibold" href="mailto:${environment.config.contactEmail}">${environment.config.contactEmail}</a>.</p>
        <p class="m-0"><strong>Pre-order products can only be modified until their respective deadlines. Please review the modification dates carefully.</strong></p>
    `,
    productAvailabilityDisclaimer: (endDate: string) => `This product is only available for pre-order until <b>${endDate}</b>`,
    increaseQuantityModal: {
        title: 'Confirm Quantity Increase',
        message: (product: { name: string, prevQuantity: number, newQuantity: number }) => `You are about to increase the quantity of <b>${product.name}</b> from <b>${product.prevQuantity}</b> to <b>${product.newQuantity}</b>. Please note that once this change is made, you will not be able to decrease the quantity. This update may also increase the required deposit for your pre-order. Do you want to proceed?`
    },
    deadlinePassedMessage: 'Update not allowed; the deadline has passed.'
}
