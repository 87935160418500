<div>
    <section>
        <h5>When should we deliver your order?</h5>
        <div class="col-12 animated fadeInDown d-grid mx-auto">
            <mat-form-field class="w-100">
            <mat-label>Choose a day</mat-label>
            <mat-select
                name="selectedDeliveryDay"
                [value]="selectedDeliveryDay()?.id || null"
                (selectionChange)="setSelectedDeliveryDay($event.value)">
                @for (item of deliveryOptions(); track $index) {
                    <mat-option [value]="item.id">{{ item.name }}</mat-option>
                }
            </mat-select>
            </mat-form-field>
        </div>
    </section>
    <div class="form-row-action my-5">
        <button
            type="button"
            class="btn btn-primary"
            [disabled]="!selectedDeliveryDay()?.id"
            (click)="saveDaliveryDay()">
            Confirm Delivery Day
        </button>
      </div>
  </div>
