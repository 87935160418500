export const DEFAULT_IMAGE = 'assets/images/default.svg'

export enum HTTP_STATUS_CODES {
    NOT_FOUND = 404,
    TOO_MANY_REQUESTS = 429,
    SESSION_EXPIRED = 403,
    SUSPENDE_USER = 444,
    I_AM_A_TEAPOT = 418,
    UNKNOWN_ERROR = 0,
    SERVER_ERROR = 500,
}