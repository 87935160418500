export const ORDER_STATUS_CLASSES: any = {
    'active': 'bg-secondary border border-secondary text-secondary w-max-content',
    'processed': 'bg-light text-black-50 border w-max-content',
    'skipped': 'alert alert-warning p-1 m-0 px-2 w-max-content',
}
export const PAYMENT_STATUS_CLASSES: any = {
    'paid': 'bg-secondary border border-secondary text-secondary w-max-content',
    'paid with credits': 'bg-secondary border border-secondary text-secondary w-max-content',
    'partially paid': 'bg-secondary border border-secondary text-secondary w-max-content',
    'pending invoice': 'alert alert-warning p-1 m-0 px-2 w-max-content',
    'unpaid': 'alert alert-warning p-1 m-0 px-2 w-max-content',
    'not paid': 'alert alert-warning p-1 m-0 px-2 w-max-content',
}