import { Injectable, inject } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalContentComponent } from './modal-content.component';
import { ModalContentTypes } from '../constants/modal-content-types';
import { ModalContentData } from './modal-content';
import { SignalsStoreService } from '../signals-store.service';
import { ResolutionService } from '../resolution.service';

@Injectable({
  providedIn: 'root'
})
export class ModalContentService {

  private modalService = inject(NgbModal);
  private resolutionService = inject(ResolutionService);

  private defaultOptions: NgbModalOptions = {
    centered: true,
    keyboard: false,
    size: 'lg'
  }

  type: string = ModalContentTypes.CHECK_ADDRESS;

  options: NgbModalOptions = { ...this.defaultOptions }
  modalContentData: any;

  constructor() { }

  openModal(type: ModalContentTypes, data?: ModalContentData, customOptions?: NgbModalOptions): NgbModalRef {
    if (data) {
      data.closeable = data && typeof data.closeable === 'boolean' ? data.closeable : true
      if (data.closeOthers) this.modalService.dismissAll();

      this.options.fullscreen = false;
      this.options.scrollable = false;
      if (data.fullScreenOnMobile && this.resolutionService.isMobile()) {
        this.options.fullscreen = true;
        this.options.scrollable = true;
      }

    } else
      data = { closeable: true };

    this.setUp(type, customOptions, data);
    const modalRef = this.modalService.open(ModalContentComponent, this.options);
    modalRef.componentInstance.modalContentType = type;
    modalRef.componentInstance.modalContentData = this.modalContentData;

    return modalRef;
  }

  private setUp(type: ModalContentTypes, customOptions?: NgbModalOptions, data?: any) {
    this.type = type;
    this.modalContentData = data;
    if (customOptions) {

      this.options = { ...this.options, ...customOptions };
      return;
    } else {
      this.options = { ...this.defaultOptions };
    }

    this.setupTypeOptions();
  }

  private setupTypeOptions() {
    switch (this.type) {
      case ModalContentTypes.HOW_IT_WORKS:
      case ModalContentTypes.WELCOME_MESSAGE:
      case ModalContentTypes.BOX_DETAIL:
        this.options.size = 'xl';
        break;
      case ModalContentTypes.BECOME_A_MEMBER:
      case ModalContentTypes.CHECK_SUBMIT_ORDER:
        this.options.size = 'md';
        break;
      case ModalContentTypes.DONATION:
        this.options.size = 'md';
        break;
      case ModalContentTypes.DONATION_SKIPPABLE:
        this.options.size = 'md';
        break;
      case ModalContentTypes.TIP:
        this.options.size = 'md';
        break;
      case ModalContentTypes.DELETE_TIP_AND_DONATION:
        this.options.size = 'md';
        break;
      default:
        this.options.size = 'lg';
        break;
    }
  }
}
