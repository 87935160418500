<section class="d-flex">
  <div class="d-flex gap-3 flex-grow-1 justify-content-center align-items-center">
    <i class="material-symbols-outlined">
      local_shipping
    </i>
    <span>Delivery date</span>
  </div>
  <div class="flex-grow-1">
    <mat-form-field class="w-100">
      <mat-label>Delivery Date</mat-label>
      <mat-select [(ngModel)]="selectedDeliveryDate" (ngModelChange)="verifyIsRequireConfirmation($event)">
        @for (item of deliveryDates(); track $index) {
        <mat-option [value]="item.originalDate">
          {{ item.date }}
        </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
</section>

@if (requireConfirm()) {
<section class="mt-5 d-flex flex-column align-items-center">

  <app-empty-message
    [message]="'The following Farm Boxes will be removed for the order on: ' + date()"></app-empty-message>

  <div>
    <h4 class="h4-semibold">Farm Boxes</h4>
    <ul>
      @for (product of bundleProducts(); track product) {
      <li>{{product}}</li>
      }
    </ul>
  </div>

</section>
}

<section class="modal-action-row has-btn-2">
  <button type="button" class="btn btn-outline-primary" (click)="close()">
    Cancel
  </button>
  <button type="button" class="btn btn-secondary" (click)="confirm()"
    [disabled]="!selectedDeliveryDate() || verifying()">
    {{verifying() ? 'Confirming...' : 'Confirm' }}
  </button>
</section>
