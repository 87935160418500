import { Injectable, WritableSignal, inject, signal } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { arrayToMapMultiKey, generateMapKeyFromValues, mapToArray } from './common/utils';
import { FirebaseCrudService } from './firebase-crud.service';
import { FIREBASE_COLLECTIONS, LOCALSTORAGE_KEYS } from './constants/databases';
import { Session } from './types/session.type';
import { FireBaseProductOrder } from './types/order.type';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  localStorageService = inject(LocalStorageService)
  firebaseCrudService = inject(FirebaseCrudService)

  notSavedOrder: WritableSignal<any> = signal(null)
  firebaseCartProducts: WritableSignal<any> = signal(null)
  productsSignal: WritableSignal<any> = signal(null)
  productCart: WritableSignal<FireBaseProductOrder | any> = signal(null)
  hasProductInCart: WritableSignal<any> = signal(false)

  constructor() { }

  addProduct(productCart: Partial<FireBaseProductOrder> | undefined): void | null {
    if (!productCart) return null;
    // In order to let the user add the same product as "subscription" and "a la carte", we'll mark this difference with "_1" if it's a subscription or "_0" if it's "a la carte".
    const mapKey: any = generateMapKeyFromValues([productCart?.variant?.id, productCart.isASubscription]);
    if (!this.productsSignal()) {
      this.productsSignal.set({ array: [productCart], map: new Map([[mapKey, productCart]]) });
      this.updateCart([productCart]);
      return
    }

    let cartProductsArray = [];
    const currentProduct = this.productsSignal().map.has(mapKey) ? this.productsSignal().map.get(mapKey) : null;
    if (currentProduct) {
      // Should modify de quantity because the product already exists in cart preview:
      const current = this.productsSignal().map.get(mapKey);
      current.quantity += productCart.quantity;
      cartProductsArray = mapToArray(this.productsSignal().map);
    } else {
      cartProductsArray = this.productsSignal().array
      cartProductsArray.push(productCart)
    }

    this.updateCart(cartProductsArray);
  }

  edit(productId: number | string, newValues: any): void | null {
    const sessionStored: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION) || null;
    if (!sessionStored?.accountInfo?.id) return

    const cartProductsMap = this.productsSignal().map;
    if (!cartProductsMap.has(productId)) return null  // because product exists

    const product = cartProductsMap.get(productId);
    const productUpdated = { ...product, ...newValues }
    cartProductsMap.set(productId, productUpdated);
    const cartProductsArray = mapToArray(cartProductsMap);

    this.firebaseCrudService.update(FIREBASE_COLLECTIONS.ORDERS, sessionStored.accountInfo.id, { cartProducts: cartProductsArray });
  }

  remove(productId: number | string): void | null {
    const sessionStored: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION) || null;
    if (!sessionStored?.accountInfo?.id) return

    const cartProductsMap = this.productsSignal().map;
    if (!cartProductsMap.has(productId)) return null  // because product exists

    cartProductsMap.delete(productId);
    const cartProductsArray = mapToArray(cartProductsMap);

    this.firebaseCrudService.update(FIREBASE_COLLECTIONS.ORDERS, sessionStored.accountInfo.id, { cartProducts: cartProductsArray });
  }

  checkProductInCart(productId: number | undefined, cartProductsMap: Map<number, any>): any | null {
    if (!productId || !cartProductsMap) return null;
    if (!cartProductsMap.has(productId)) return null;

    return cartProductsMap.get(productId);
  }

  private updateCart(cartProducts: any[]): void {
    const sessionStored: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION) || null;
    if (!sessionStored?.accountInfo?.id) return

    const cartProductsMap = arrayToMapMultiKey(['variant.id', 'isASubscription'], cartProducts);

    //NEED CHANGE NOT SAVED ORDER BY CART 
    if (!this.firebaseCartProducts()) {
      // const orderProducts = this.notSavedOrder()
      this.firebaseCrudService.add(FIREBASE_COLLECTIONS.ORDERS, sessionStored.accountInfo.id, { cartProducts });
    }
    else {
      const firebasePayload = {
        collection: FIREBASE_COLLECTIONS.ORDERS,
        docId: sessionStored.accountInfo.id.toString(),
        subKeyPath: `cartProducts`,
        newValue: cartProducts,
      }

      this.firebaseCrudService.updateSubkey(firebasePayload);
    }

    this.productsSignal.set({ array: cartProducts, map: cartProductsMap });
    this.localStorageService.set(LOCALSTORAGE_KEYS.CART, cartProducts);
  }
}
