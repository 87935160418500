import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appNumberRestriction]',
  standalone: true
})
export class NumberRestrictionDirective {
  @Input() minNumber: number = 1;
  @Input() maxNumber: number = 99;

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const pastedText = event.clipboardData?.getData('text/plain') || null;
    if (!this.isNumericValue(pastedText)) {
      event.preventDefault();
    }
  }

  private isNumericValue(value: string | null): boolean {
    if (value === null) {
      return false;
    }
    const numericValue = +value;
    return !isNaN(numericValue) && numericValue >= this.minNumber && numericValue <= this.maxNumber;
  }

  @HostListener('input', ['$event']) onInput(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    const validInput = new RegExp(`^(${this.minNumber}|[1-9][0-9]?|${this.maxNumber})$`).test(inputValue);
    if (!validInput) {
      input.value = inputValue.slice(0, -1);
    }
  }
}