import { Component, ElementRef, NgZone, OnInit, ViewChild, WritableSignal, computed, inject, signal } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddressService, NavegoAddressRequest } from '../../address.service';
import { Loader } from '@googlemaps/js-api-loader';
import { GoogleMapsUtils } from '../../common/utils';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { environment } from '../../../../environments/environment';
import { SignalsStoreService } from '../../signals-store.service';
import { LocalStorageService } from '../../local-storage.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LOCALSTORAGE_KEYS } from '../../constants/databases';
import { MatRadioButton } from '@angular/material/radio';
import { CustomerType, PickUpOption } from '../../types/account.types';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { AccountService } from '../../account.service';
import { tap } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-check-address',
  standalone: true,
  imports: [
    MatProgressBarModule,
    FormsModule,
    MatRadioButton,
    MatFormFieldModule,
    MatSelectModule,
    RouterLink,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [{
    provide: Loader,
    useValue: new Loader({
      apiKey: environment.apis.google.places,
      libraries: ['places'],
      region: 'US',
    })
  }],
  templateUrl: './check-address.component.html',
  styleUrl: './check-address.component.scss'
})
export class CheckAddressComponent implements OnInit {

  @ViewChild('autocompleteInput') autocompleteInput!: ElementRef<HTMLInputElement>;
  localStorageService = inject(LocalStorageService)

  private router = inject(Router);
  private activeModal = inject(NgbActiveModal)
  private addressService = inject(AddressService);
  private zone = inject(NgZone);
  private loader = inject(Loader);
  private signalsStoreService = inject(SignalsStoreService);
  private accountService = inject(AccountService);

  isGoogleAddressFilled = signal(false);
  isLoadingContent: any = computed(() => this.addressService.isLoading());
  hasDeliveryAddressCoverage: any = signal(false);

  membershipList: WritableSignal<CustomerType[]> = signal([]);
  selectedMembership: WritableSignal<CustomerType> = signal({ id: 0, name: '' });

  pickUpOptions: WritableSignal<PickUpOption[]> = signal([]);
  selectedPickUpOption: WritableSignal<PickUpOption> = signal({ id: 0, name: '', deliveryDays: [], locationAddress: '' })

  isContentLoaded: WritableSignal<boolean> = signal(false);

  selectedPlace: google.maps.places.PlaceResult | null = null;

  queryString = ''

  ngOnInit(): void {
    this.getCustomerTypes();
    this.addressService.setFirstPickUpMembershipSelected$.pipe(tap(() => this.selectFirstPickUpMembership())).subscribe();
  }

  loginClickHandler() {
    this.activeModal.close();
    this.router.navigate(['/login']);
  }

  private onPlaceSelected(place: google.maps.places.PlaceResult) {
    this.zone.run(() => {
      const {
        city,
        stateCode,
        zipCode,
        name: street,
        latitude,
        longitude,
        formattedAddress,
        placeId
      } = this.addressService.setupDeliveryData(place);

      this.signalsStoreService.googleAddress.set({
        street,
        city,
        stateCode,
        zipCode,
        latitude,
        longitude,
        placeId,
      });

      this.selectedPlace = place;
    });


  }

  checkDeliveryAddressCoverage() {
    this.isGoogleAddressFilled.set(true);
    const { street, city, stateCode, latitude, longitude } = this.signalsStoreService.googleAddress();
    const navegoAddressRequest = {
      street,
      city,
      state: stateCode,
      latitude,
      longitude,
    } as NavegoAddressRequest;

    this.addressService.checkDeliveryAddress(navegoAddressRequest, true, true);
    this.hasDeliveryAddressCoverage = computed(() => {
      if (!!this.addressService.navegoAddress()) {
        this.localStorageService.set(LOCALSTORAGE_KEYS.GOOGLEPLACERESULT, this.selectedPlace);
        setTimeout(() => {
          this.closeModal();
          this.router.navigate(['/signup'])
        }, 1000);
      }
      return !!this.addressService.navegoAddress()
    });
  }

  changeAddress() {
    this.hasDeliveryAddressCoverage = signal(false);
    this.isGoogleAddressFilled.set(false);
  }

  closeModal() {
    this.activeModal.close();
  }

  setSelectedPickUpOption(event: MatSelectChange) {
    const option = this.pickUpOptions().find(o => o.id === event.value);
    if (option) {
      this.selectedPickUpOption.set(option);
      this.localStorageService.set(LOCALSTORAGE_KEYS.SELECTED_PICKUP_OPTION, option);
    }
  }

  private getCustomerTypes() {
    this.accountService.getAllCustomerTypes().pipe(
      tap((response) => {
        if (!response?.data?.length) return;
        this.membershipList.set(JSON.parse(JSON.stringify(response.data)));
        const storedMembership: any = this.localStorageService.get(LOCALSTORAGE_KEYS.SELECTED_DELIVERY_PREFERENCE)
        let selectedMembership = response.data[0];
        if (storedMembership?.id) {
          selectedMembership = response.data.find((m: any) => m.id === storedMembership.id)
          if (!selectedMembership?.configurations?.requireAddress)
            this.getPickupLocations(storedMembership.id)
        }
        this.selectedMembership.set(JSON.parse(JSON.stringify(selectedMembership)));
        this.localStorageService.set(LOCALSTORAGE_KEYS.SELECTED_DELIVERY_PREFERENCE, selectedMembership);
        this.isContentLoaded.set(true);
        this.setUpAutocomplete();
      })
    ).subscribe()
  }

  setSelectedMembership(id: any) {
    if (!id) return;
    const selected = this.membershipList().find(m => m.id == id);
    if (!selected) return;
    this.getPickupLocations(id);
    this.selectedMembership.set(JSON.parse(JSON.stringify(selected)));
    this.localStorageService.set(LOCALSTORAGE_KEYS.SELECTED_DELIVERY_PREFERENCE, selected);
    this.setUpAutocomplete();
  }

  private setUpAutocomplete() {
    if (this.selectedMembership().configuration?.requireAddress) {
      setTimeout(() => {
        GoogleMapsUtils.initializeAutocomplete(this.loader, this.autocompleteInput.nativeElement, this.onPlaceSelected.bind(this));
        const locationText: string | null =
          this.signalsStoreService.anonymousUserAddress()?.street ?? '';
        if (locationText) this.queryString = locationText;

        GoogleMapsUtils.initializeAutocomplete(this.loader, this.autocompleteInput.nativeElement, this.onPlaceSelected.bind(this));
        GoogleMapsUtils.loadAutoCompleteIfAny(
          this.loader,
          this.onPlaceSelected.bind(this),
          this.localStorageService
        );
      }, 1)
    }
  }

  private getPickupLocations(id: number) {
    this.accountService.getPickUpOption(id).pipe(
      tap((response) => {
        if (response?.length) {
          const r = response.map((l: { composedName: string; name: any; locationAddress: any; }) => {
            l.composedName = `${(l.name ? `${l.name} - ` : '') || ''}${l.locationAddress}`;
            return l;
          })
          this.pickUpOptions.set(r);
          this.selectedPickUpOption.set(r[0]);
          this.localStorageService.set(LOCALSTORAGE_KEYS.SELECTED_PICKUP_OPTION, r[0]);
        }
      })
    ).subscribe()
  }

  goToSignup() {
    this.router.navigate(['/signup']);
    this.closeModal();
  }


  selectFirstPickUpMembership() {
    const selected = this.membershipList().find(m => !m.configuration?.requireAddress);
    if (!selected?.id) return;
    this.getPickupLocations(+selected.id);
    this.selectedMembership.set(JSON.parse(JSON.stringify(selected)));
    this.localStorageService.set(LOCALSTORAGE_KEYS.SELECTED_DELIVERY_PREFERENCE, selected);
  }

}
