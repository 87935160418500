<div class="mt-3 mt-sm-5 text-center text-sm-start">
    @if (!modalContentData.hasSession) {
      <h3 class="h3-semibold">Great news!</h3>
      <p class="h3-regular">We deliver to your area, set up a free account to start shopping!</p>
    } @else {
      <p class="h2-regular text-center">Our market is open today, so go ahead and get shopping!</p>
    }
    <section class="text-center mt-5 mb-3 nb-sm-5">
      <h3 class="h2-semibold">How It Works</h3>
    </section>

    <div class="row d-flex justify-content-center text-center">
      <div class="col-lg-4 col-xl-3 mb-3 mb-sm-0">
        <div class="card border-0">
          <div class="card-body">
            <h4><i class="text-success-emphasis fas fa-bag-shopping"></i></h4>
            <p class="card-text">Start shopping when the market opens on <b>Friday morning</b></p>
          </div>
        </div>
      </div>
      <div class="col-sm-1 arrow-container d-none d-xl-flex">
        <section class="arrow"></section>
      </div>
      <div class="col-lg-4 col-xl-3">
        <div class="card border-0">
          <div class="card-body">
            <h4><i class="text-success-emphasis fas fa-cart-shopping"></i></h4>
            <p class="card-text">
              Complete your cart by the designated shopping cut-off day <b>{{modalContentData.howItWorks?.cutoffDay ? modalContentData.howItWorks?.cutoffDay + ', ' + modalContentData.howItWorks?.cutoffTime : '(based on your location)'}}</b>
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-1 arrow-container d-none d-xl-flex">
        <section class="arrow"></section>
      </div>
      <div class="col-lg-4 col-xl-3">
        <div class="card border-0">
          <div class="card-body">
            <h4><i class="text-success-emphasis fas fa-calendar"></i></h4>
            <p class="card-text">
              Get excited for your home delivery<b>{{modalContentData.howItWorks?.deliveryDay ? (', which will arrive on ' + modalContentData.howItWorks?.deliveryDay) : ''}}</b>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row-action">
        @if (modalContentData.hasSession) {
                <button
                    type="button"
                    class="btn btn-primary"
                    (click)="goTo()">
                    Continue shopping
                </button>
        } @else {
                <button
                    type="button"
                    class="btn btn-outline-primary"
                    (click)="goTo()">
                    Browse Products
                </button>
                <button
                    type="button"
                    class="btn btn-primary"
                    (click)="goTo('signup')">
                    Sign Up
                </button>
        }
      </div>
  </div>
