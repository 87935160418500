<div class="d-grid mx-auto mb-3">
  <div class="col-md-auto">
    <h2 class="modal-title text-center">
      Tip the driver
    </h2>
  </div>
</div>

<div class="form-check form-check__highlight">
  <div class="check-1">
    <input 
      class="form-check-input" 
      type="radio" 
      name="flexRadioDefault1" 
      id="flexRadioDefault1"
      [(ngModel)]="selectedOption"
      value="RECURRENT">
    <label class="form-check-label" for="flexRadioDefault1">
      Recurring tip
    </label>
  </div>
  @if (!isMobile() || (isMobile() && selectedOption() == 'RECURRENT')) {
    <div class="value-1">
      <input 
      type="text" 
      class="form-control" 
      id="fullAmount" 
      placeholder="Text"
      [disabled]="selectedOption() !== 'RECURRENT'"
      appCurrencyFormat
      [(ngModel)]="recurrentAmount">
    </div>
  }
  <div class="check-2">
    <input 
      class="form-check-input" 
      type="radio" 
      name="flexRadioDefault1" 
      id="flexRadioDefault2"     
      [(ngModel)]="selectedOption"
      value="ONE_TIME">
    <label class="form-check-label" for="flexRadioDefault2">
      One-time tip
    </label>
  </div>
  @if (!isMobile() || (isMobile() && selectedOption() == 'ONE_TIME')) {
  <div class="value-2">
    <input 
        type="text" 
        class="form-control" 
        id="anotherAmount" 
        placeholder="Text"
        [disabled]="selectedOption() !== 'ONE_TIME'"
        appCurrencyFormat
        [(ngModel)]="oneTimeAmount">
    </div>
  }
</div>

@if (showApplyToAll() && selectedOption() === 'RECURRENT') {
  <div class="text-center mt-4 animated fadeIn">
    <mat-checkbox
    [(ngModel)]="applyToAllOrders"
    >
      Apply to all future orders
    </mat-checkbox>
  </div>
}

<div class="modal-action-row has-btn-2">
    <button
      type="button"
      class="btn btn-outline-primary"
      (click)="closeModal()">
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="execute()"
      [disabled]="!selectedOption()">
      Tip
    </button>
</div>
