import { Injectable, WritableSignal, inject, signal } from "@angular/core";
import { ApiService } from "../../../shared/api.service";
import { delay, filter, map, of, tap } from "rxjs";
import { Membership, MembershipData, MembershipResponse, membershipMOCK, userCancelledMembershipMOCK, userMembershipMOCK } from './membership.types';
import { formatDateToReadableString } from "../../../shared/utils/formatting";
import { NotificationService } from "../../../shared/notification/notification.service";
import { RequestHandlerParams } from "../../../shared/types/api-service.types";
import { Session } from "../../../shared/types/session.type";
import { LocalStorageService } from "../../../shared/local-storage.service";
import { LOCALSTORAGE_KEYS } from "../../../shared/constants/databases";


@Injectable()
export class MembershipService {
    private apiService = inject(ApiService);
    private notificationService = inject(NotificationService);
    private localStorageService = inject(LocalStorageService);

    membership: WritableSignal<Membership | null> = signal(null);
    membershipList: WritableSignal<MembershipData[]> = signal([]);
    loading: WritableSignal<boolean> = signal(false);


    private endpoints = {
        base: '/account/membership',
    }

    subscribe(deposit: number = 0, renew: boolean = false) {
        const params: RequestHandlerParams = {
            method: 'POST',
            endpoint: `${this.endpoints.base}`,
            body: { deposit, renew },
            apiV3: true
        };

        this.apiService.handleRequest(params).pipe(
            filter((response: any) => !!response),
            tap((response: any) => this.notificationService.show({ text: response.message, type: 'success' })),
            tap(() => {
                const sessionStored: Session = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION) as any;
                let settings = sessionStored.settings;
                settings.hasMembership = true;
                const newSessionStored: Session = { ...sessionStored, settings };
                this.localStorageService.set(LOCALSTORAGE_KEYS.SESSION, newSessionStored);
            }),
            tap((response: any) => this.setUpMembershipDetailsResponse(response)),
            tap(() => this.get())
        ).subscribe();
    }

    unsubscribe() {
        const params: RequestHandlerParams = {
            method: 'DELETE',
            endpoint: `${this.endpoints.base}`,
            apiV3: true
        };

        this.apiService.handleRequest(params).pipe(
            filter((response: any) => !!response),
            tap(() => this.notificationService.show({ text: 'We are sad to see you go but your membership cancelation has been saved', type: 'success' })),
            tap(() => this.membership.set(null)),
            tap(() => this.get())
        ).subscribe();
    }

    get() {
        this.loading.set(true);
        const params: RequestHandlerParams = {
            method: 'GET',
            endpoint: `${this.endpoints.base}`,
            apiV3: true
        };

        this.apiService.handleRequest(params).pipe(
            filter((response: any) => !!response),
            tap((response: MembershipResponse) => this.setUpMembershipDetailsResponse(response)),
            tap(() => this.loading.set(false))
        ).subscribe();
    }

    private setUpMembershipDetailsResponse(response: MembershipResponse, validateList: boolean = true): void {
        const { list, userMembership } = response;
        if (list && validateList) {
            this.membershipList.set(list ? list.sort((a, b) => a.order - b.order).map((membership: MembershipData, index: number) => {
                membership.order = (index + 1);
                membership.cardCost = signal(+membership.cost);
                membership.cardStandard = membership.cost === 0;
                this.setUpCardBenefits(membership);
                return membership;
            }) : []);
        }

        if (userMembership?.id) {
            userMembership.nextBillingDate = userMembership.nextBillingDate ? formatDateToReadableString(userMembership.nextBillingDate).readableString : '';
            userMembership.since = userMembership.since ? formatDateToReadableString(userMembership.since).readableString : '';
            userMembership.endDate = userMembership.endDate ? formatDateToReadableString(userMembership.endDate).readableString : '';
            const userMembershipId = userMembership.id;
            // TODO: FIIX
            const matchingMembership = this.membershipList().find(membership => membership.order === 1);
            // const matchingMembership = this.membershipList().find(membership => membership.order === userMembershipId);
            if (matchingMembership) {
                userMembership.cardBenefits = matchingMembership.benefits;
                userMembership.cardStandard = matchingMembership.cardStandard;
                userMembership.cardCost = matchingMembership.cardCost;
                userMembership.cardImage = matchingMembership.image;
            }
            this.membership.set(userMembership);
        }
    }

    private setUpCardBenefits(membership: MembershipData): void {
        const splitSize = 4;
        const { benefits } = membership;

        membership.cardBenefits = benefits.length > splitSize
            ? Array.from({ length: Math.ceil(benefits.length / splitSize) }, (_, i) =>
                benefits.slice(i * splitSize, (i + 1) * splitSize))
            : [benefits];
    }
}