import { Injectable, WritableSignal, signal } from "@angular/core";
import { Sidebar, SidebarCheckList, SidebarFilterTypes } from './sidebar.types';
import { splitByUpperCase } from "../common/utils";

@Injectable({
    providedIn: 'root'
})
export class SidebarService {

    sidebar: WritableSignal<SidebarCheckList[]> = signal([]);

    private filterTypes: any = {
        [SidebarFilterTypes.categories]: { property: 'category', isArray: false },
        [SidebarFilterTypes.subcategories]: { property: 'subcategory', isArray: false },
        [SidebarFilterTypes.producers]: { property: 'producer', isArray: false },
        [SidebarFilterTypes.dietRestrictions]: { property: 'dietRestrictions', isArray: true },
        [SidebarFilterTypes.tags]: { property: 'tags', isArray: true },
    }

    setUpSidebarData(data: Sidebar | any) {
        // This adjustment it's only for ME because they don't want to see the sidebar producers filter
        delete data.producers;
        const sidebar: SidebarCheckList[] = [];
        for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                const element = data[key];
                if (!element?.length) continue;
                sidebar.push({
                    id: key.toLowerCase(),
                    name: splitByUpperCase(key).join(' ').toLowerCase(),
                    checked: false,
                    sublist: element.map((e: any) => {
                        e.name = e.name.trim()
                        e.disabled = false;
                        e.checked = false;
                        return e;
                    })
                });
            }
        }

        this.sidebar.set(sidebar);
    }

    setUpSidebarDataAfterFilter(products: any) {
        this.sidebar().forEach(filter => {
            const filterType = this.filterTypes[filter.id];
            this.matchFilter(filter, products, filterType.property, filterType.isArray)
        });
    }

    private matchFilter(filter: SidebarCheckList, products: any, type: string, isArray: boolean) {
        filter.sublist?.map(item => {
            products.some((product: any) => {
                const match = isArray ? product?.[type]?.map((t: any) => t.id).includes(item.id) : product[type]?.id === item.id;
                item.disabled = !match;
                return match
            });
        });
    }



}