<div class="d-grid mx-auto">
  <div class="col-md-auto">
    <h2 class="modal-title text-center">
      Want to make a donation instead?
    </h2>
    <p class="text-center">
      Support access to healthy food for all
    </p>
  </div>
</div>

<div class="form-check form-check__highlight">
  <div class="check-1">
    <input class="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault1"
           [(ngModel)]="selectedOption"
           value="DONATE_FULL_AMOUNT"
    >
    <label class="form-check-label" for="flexRadioDefault1">
      Full cart amount
    </label>
  </div>
  @if (!isMobile() || (isMobile() && selectedOption == 'DONATE_FULL_AMOUNT')) {
    <div class="value-1">
      <input type="text" class="form-control" id="fullAmount" placeholder="Text"
            disabled
            [ngModel]="'$' + currentDonationAmount"
            appCurrencyFormat
      >
    </div>
  }

  <div class="check-2">
    <input class="form-check-input" type="radio" name="flexRadioDefault1" id="flexRadioDefault2"
           [(ngModel)]="selectedOption"
           value="DONATE_ANOTHER_AMOUNT"
    >
    <label class="form-check-label" for="flexRadioDefault2">
      Another amount
    </label>
  </div>
  @if (!isMobile() || (isMobile() && selectedOption == 'DONATE_ANOTHER_AMOUNT')) {
    <div class="value-2">
      <input type="text" class="form-control" id="anotherAmount" placeholder="Text"
            appCurrencyFormat
            [ngModel]="'$0.00'"
            (ngModelChange)="signalsStoreService.donationAmountSkippable.set($event)"
            [disabled]="selectedOption !== 'DONATE_ANOTHER_AMOUNT'"
      >
    </div>
  }
</div>


<div class="modal-action-row has-btn-2">
    <button
      type="button"
      class="btn btn-outline-primary"
      (click)="cancel()">
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="donate()">
      Donate
    </button>
</div>
<div class="d-flex justify-content-center mt-4">
      <a (click)="closeModal()" href="javascript:void(0)">Skip donation</a>
</div>
