import { Component, computed, inject } from '@angular/core';
import { DeliveriesService } from '../../settings/account/deliveries/deliveries.service';
import { formatDateToReadableString } from '../utils/formatting';
import { Router, RouterModule } from '@angular/router';
import { LocalStorageService } from '../local-storage.service';
import { LOCALSTORAGE_KEYS } from '../constants/databases';
import { ModalContentService } from '../modal-content/modal-content.service';
import { ModalContentTypes } from '../constants/modal-content-types';

@Component({
  selector: 'app-info-message',
  standalone: true,
  imports: [
    RouterModule
  ],
  templateUrl: './info-message.component.html'
})
export class InfoMessageComponent {

  //#region Services

  #deliveriesService = inject(DeliveriesService);

  #localStorageService = inject(LocalStorageService);

  #modalContentService = inject(ModalContentService);

  #router = inject(Router);

  //#endregion

  //#region Properties

  defaultDeliveryDate = computed(() => this.#formatDeliveryDate());

  //#endregion

  //#region Methods

  #formatDeliveryDate() {

    const date = this.#deliveriesService.defaultDeliveryDate();

    if (!date)
      return '';

    const { month, dayName, day } = formatDateToReadableString(date);

    return `${dayName}, ${month} ${day}`;
  }

  goToOrder() {

    this.#localStorageService
      .set(LOCALSTORAGE_KEYS.ORDER_SELECTED, this.#deliveriesService.defaultDeliveryDate());

    this.#router.navigate(['/orders']);
  }

  openModalDetailOrder() {

    this.#modalContentService
      .openModal(ModalContentTypes.RESUME_PENDING_ORDER, {
        title: 'Update or Submir Order',
        deliveryDate: this.#deliveriesService.defaultDeliveryDate()
      })
      .closed
      .subscribe((resp: { redirect: boolean } | undefined) => {

        if (resp?.redirect)
          this.goToOrder();
      })
  }

  //#endregion
}
