<section class="row">

  <!-- Products -->
  <div class="col-md-8">
    @if (products().common && products().common.length) {
    <h2 class="h5-regular">
      Commons
    </h2>

    <section class="d-flex gap-4 flex-column">
      @for (product of products().common; track product) {
      <div class="d-flex justify-content-between align-items-center">

        <div class="d-flex gap-3 align-items-center">

          <img [src]="product?.img || 'assets/images/product-placeholder.webp'" class="thumb-img" alt="{{product.name}}"
            (error)="handleImageError($event)" />

          <div class="d-flex flex-column">
            <span class="h6-semibold">
              {{product.name}}
            </span>
            <span class="h7-regular">
              Quantity: {{product.quantity}}
            </span>
            <span class="h5-bold">
              {{product.totalPrice | currency}}
            </span>
          </div>

        </div>

        <div>
          @if (product.hasPendingChanges) {
          <span class="badge bg-warning">Not yet saved</span>
          } @else {
          <span class="badge bg-success">In your order</span>
          }
        </div>

      </div>
      }
    </section>
    }

    @if (products().subscription && products().subscription.length) {
    <h2 class="h5-regular mt-3">
      Subscriptions
    </h2>

    <section class="d-flex gap-4 flex-column">
      @for (product of products().subscription; track product) {
      <div class="d-flex justify-content-between align-items-center">

        <div class="d-flex gap-3 align-items-center">
          <img [src]="product?.img || 'assets/images/product-placeholder.webp'" class="thumb-img" alt="{{product.name}}"
            (error)="handleImageError($event)">

          <div class="d-flex flex-column">
            <span class="h6-semibold">
              {{product.name}}
            </span>
            <span class="h7-regular">
              Quantity: {{product.quantity}}
            </span>
            <span class="h7-regular">
              {{ ((product?.package?.name || product?.variant?.attribute?.name) ? ' | ' : '') + 'Frequency: ' +
              product.subscription?.frequency?.name }}
            </span>
            <span class="h5-bold">
              {{product.totalPrice | currency}}
            </span>
          </div>
        </div>

        <div>
          @if (product.hasPendingChanges) {
          <span class="badge bg-warning">Not yet saved</span>
          } @else {
          <span class="badge bg-success">In your order</span>
          }
        </div>

      </div>
      }
    </section>
    }
  </div>

  <!-- Order Summary -->
  <div class="col-md-4 mt-3">

    <h2 class="h4-bold mb-4 text-center">
      Order Summary
    </h2>

    <!-- Subtotal -->
    <div class="row row-cols-2 justify-content-between">
      <div class="col h6-semibold">
        Subtotal
      </div>
      <div class="col text-end animated fadeIn h6-semibold">
        {{subTotal().toFixed(2) | currency}}
      </div>
    </div>

    <!-- Taxes -->
    <div class="row row-cols-2 justify-content-between">
      <div class="col h6-semibold">
        Taxes
      </div>
      <div class="col text-end animated fadeIn h6-semibold">
        {{taxes().toFixed(2) | currency}}
      </div>
    </div>

    <!-- Delivery -->
    <div class="row row-cols-2 justify-content-between">
      <div class="col h6-semibold">
        Delivery
      </div>
      <div class="col text-end animated fadeIn h6-semibold">
        @if (deliveryFee()) {
        {{ deliveryFee() | currency }}
        } @else {
        FREE
        }
      </div>
    </div>

    <hr>

    <!-- Tip -->
    @if (shouldShowTipsAndDonations()) {
    <div class="row row-cols-2 justify-content-between">
      <div class="col h6-semibold">
        Tip
      </div>
      <div class="col text-end animated fadeIn h6-semibold">
        {{ tipAmount() | currency }}
      </div>
    </div>
    }

    <hr>

    <!-- Total -->
    <div class="row row-cols-2 justify-content-between">
      <div class="col h4-semibold">
        Total
      </div>
      <div class="col text-end animated fadeIn h4-bold">
        {{total() | currency}}
      </div>
    </div>

  </div>

</section>

<section class="modal-action-row has-btn-2">

  <button type="button" class="btn btn-outline-secondary" (click)="goToOrder()">
    Go to Order
  </button>

  @if (odooOrder()?.id) {
  <button type="button" class="btn btn-secondary" [disabled]="!isAllowedOrder() || !hasPendingChanges()"
    (click)="updateOrder(odooOrder()?.id)">
    Update/Submit Order
  </button>
  } @else {
  <button type="button" class="btn btn-secondary" [disabled]="!isAllowedOrder()" (click)="updateOrder()">
    Update/Submit Order
  </button>
  }
</section>