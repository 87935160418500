import { WritableSignal } from "@angular/core";
import { FirebaseOrder } from "../signals-store.service";
import { Item } from "./common.types";
import { Bundle, ProductPresentation } from "../../product/product.types";

export enum RELATED_ORDER_PRODUCTS {
  FAVORITES = 'favorites',
  BUY_AGAIN = 'buyAgain',
  SUGGESTED = 'suggested',
}

export interface Attribute {
  id: number;
  type: string;
  values: Flavor[];
}

export interface Category extends Item {
  minSpend: number;
}

export interface Coupon extends Item {
  code: string;
  amount: number;
  benefit: string;
  isApplied: boolean;
}

export interface DeliveryFee {
  amount: number;
  minSpend: number;
  minOrder: number;
}

export interface Description {
  large: string;
  short: string;
}

export interface Flavor extends Item { }

export interface Order extends Item {
  paymentDetails: PaymentDetails;
  products: ProductOrder[];
  status: Status;
  suggestedProducts: SuggestedProduct[];
}

export interface OrderResponse extends Item {
  id: number;
  invoiceDetails?: any;
  marketStatus?: {
    isOpen: boolean;
    openingDay: any;
    openingHour: any;
  };
  paymentDetails: PaymentDetails;
  products: {
    common: ProductOrder[];
    subscription: ProductOrder[];
  };
  name: string;
  status: Status;
  suggestedProducts: SuggestedProduct[];
  outOfStockProducts?: any[];
  isSkipped?: boolean;
  relatedProducts?: any;
  deliveryInfo: {
    cutoffDate: string;
    cutoffDay: string;
    cutoffTime: string;
    deliveryDate: string;
    deliveryDay: string;
    deliveryDayIn: number;
    deliveryTimeInWindowId: number;
    deliveryWindow: string;
    zoneId: number;
  };
}

export interface Package extends Item { }

export interface PaymentDetails {
  credits: number;
  deposits: number;
  deliveryFee: DeliveryFee;
  coupons: Coupon[];
  tip: Tip;
  subTotal: number;
  taxes: number;
  total: number;
  donation?: any;
  minOrder?: number;
}

export interface Producer {
  name: string;
}

export interface Product {
  attributes: Attribute[];
  bundle: Bundle;
  category: Category;
  deposit: number;
  description: Description;
  fav: boolean;
  frequency: Item;
  id: number;
  img: string;
  isSubscription: boolean;
  name: string;
  packages: Package[];
  price: number;
  producer: Producer;
  quantity: number;
  subCategory: Category;
  tags: Tag[];
  tax: number;
  totalPrice?: string | number;
}

export interface ProductOrder {
  bundle?: Bundle;
  category: Category;
  deposit: number;
  id: number;
  img: string;
  isSubscription: boolean;
  isASubscription?: boolean;
  name: string;
  presentation: ProductPresentation;
  price: number;
  quantity: number;
  subCategory: Category;
  tax: number;
  totalPrice?: string | number;
}

export interface FireBaseProductOrder {
  bundle?: Bundle;
  category: Category;
  deposit: number;
  id: number;
  img: string;
  isSubscription: boolean;
  isASubscription?: boolean;
  name: string;
  package: {
    id: number;
    name: string;
    quantity: number;
  };
  presentation: ProductPresentation;
  price: number;
  productUrl: string;
  quantity: number;
  subCategory: Category;
  subscription: {
    frequency: {
      id: number,
      name: string
    },
    startDate: string | null,
  } | null,
  tax: number;
  totalPrice?: string | number;
  variant: {
    attribute: {
      id: number,
      name: string,
      value: Item
    },
    id: number,
    productId: number,
    stock: number,
  },
  deliveryDate?: string
}

export interface Status {
  deliveryDate: string;
  id: number;
  isInProgress: boolean;
  isOnWay: boolean;
  isSaved?: boolean;
}

export interface SuggestedProduct extends Partial<Product> { }

export interface Tag extends Item { }

export interface Tip {
  amount: number;
  isRecurrent: boolean;
}

export interface TipsAndDonationsPayload {
  amount: number;
  isRecurrent: boolean;
  applyToAllOrders?: boolean;
}

export enum BundleEditionTypes {
  subscription = 'subscription',
  aLaCarte = 'common'
}

export type BundleEditionType = BundleEditionTypes.aLaCarte | BundleEditionTypes.subscription;

export type UpdateOrderParams = {
  order: FirebaseOrder;
  firebaseOrderSignal?: WritableSignal<FirebaseOrder[]>;
  orderId?: number;
  getStock?: boolean;
  showDefaultMessage?: boolean;
  coupon?: WritableSignal<string>;
  deliveryInfo?: { deliveryDateText: string, cutoffDateText: string, thanksMessage: string };
  isUpdatingBundle?: boolean;
};
