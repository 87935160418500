<div class="container">
    <div class="mt-4">
        <section class="mt-3">
           <div class="d-flex mx-auto align-items-center col-lg-10 col">
            <div class="p-2">
                <p class="card-text text-center">
                    We'll promptly inform you once your delivery address falls within our designated delivery zones.
                </p> 
            </div>
           </div>
        </section>
        <div class="form-row-action mt-4 mb-4">
            <button
                type="button"
                class="btn btn-primary"
                (click)="closeModal()">
                Thank you
            </button>
          </div>
      </div>    
</div>