import { Component, Input, Signal, WritableSignal, computed, inject, signal } from '@angular/core';
import { OrderHistoryService } from '../../../settings/billing/order-and-billing/order-and-billing.service';
import { BuyAgainProducts, OrderHistoryProductResponse } from '../../../settings/billing/order-and-billing/order-and-billing.types';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '../../notification/notification.service';
import { ResolutionService } from '../../resolution.service';

@Component({
  selector: 'app-buy-again-order-history',
  standalone: true,
  imports: [MatCheckboxModule, FormsModule, CommonModule],
  providers: [OrderHistoryService],
  templateUrl: './buy-again-order-history.component.html',
  styleUrl: './buy-again-order-history.component.scss'
})
export class BuyAgainOrderHistoryComponent {

  private orderHistoryService = inject(OrderHistoryService);
  private activeModal = inject(NgbActiveModal);
  private notificationService = inject(NotificationService);
  private resolutionService = inject(ResolutionService);

  products: WritableSignal<OrderHistoryProductResponse[]> = this.orderHistoryService.products
  isMobile = computed(() => this.resolutionService.isMobile());

  @Input()
  set orderHistoryId(value: number | undefined) {
    if (value && value > 0) {
      this.orderHistoryService.getOrderHistoryDetail(value);
    }
  }

  allSelected = true;

  updateSelection() {
    this.allSelected = this.products().filter(e => e.hasStock && !e.isSubscription).every(e => e.selected);
  }

  // someSelected() {
  //   return this.products().some(e => e.selected);
  // }

  setAll(selected: boolean) {
    this.products.update((current: OrderHistoryProductResponse[]) => {
      // Set all products "a la carte" with stock as checked:
      current.forEach(p => p.hasStock && !p.isSubscription ? p.selected = selected : null);
      return current;
    });
  }

  sendItems() {
    const buyAgainProducts: OrderHistoryProductResponse[] = this.products().filter(e => e.selected);
    if (!buyAgainProducts.length) return this.notificationService.show({text: 'Please, select at least one product from the list.', type: 'warning'});
    this.activeModal.close(buyAgainProducts);
  }
}
