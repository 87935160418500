import { Injectable } from "@angular/core";
import { PreOrderPayloadProduct, Product } from "../../product/product.types";

@Injectable({
    providedIn: 'root'
})
export class ProductCardService {


    setUpProductForPreOrder(data: { product: Partial<Product>, variantId: number, packageId?: number, quantity: number }): PreOrderPayloadProduct {
        const { product, variantId, packageId, quantity } = data;
        if (!product.id) throw new Error('');
        return {
            id: product.id,
            quantity,
            variantId,
            packageId
        }
    }
}