<p class="text-center mt-2">
    <mat-checkbox class="col" (change)="handleCheckboxChange($event.checked)">
        Don't show me this message again
    </mat-checkbox>
<div class="row mt-5">
    <div class="col col-md-6 d-grid">
        <button type="button" class="btn btn-outline-primary"
            (click)="confirmModalButtonsAction(false)">Discard changes</button>
    </div>
    <div class="col col-md-6 d-grid">
        <button type="button" class="btn btn-primary"
            (click)="confirmModalButtonsAction(true)">I understand</button>
    </div>
</div>