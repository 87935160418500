<div class="row row-cols-1 row-gap-2 mt-3">
    <div class="col">
        <p>Support your local food system with fully refundable CSA deposits, available in the increments below. </p>
    </div>

    <div class="col">
        <p>These optional deposits provide crucial working capital for our small producers and Philly Foodworks. By decreasing
            the need for traditional debt you are alleviating the financial burden on farmers and small businesses.
            <a href="javascript:void(0)" class="link-primary fw-bold">Click here to explore how we utilize these deposits.</a></p>
    </div>

    <div class="col">
        <p>Should you ever need your deposit returned, simply reach out. Thank you for your invaluable support in
            nurturing our local food system!</p>
    </div>

    <div class="col">
        <h4 class="text-center">CSA Deposit Amount</h4>
        <mat-radio-group class="row row-cols-4 row-gap-1" [(ngModel)]="csaDepositSelected">
            @for (deposit of csaDeposits; track $index) {
                <mat-radio-button class="col" [value]="deposit">{{deposit | currency}}</mat-radio-button>
            }
        </mat-radio-group>
    </div>
</div>

<div class="row justify-content-center mt-4">
    <div class="col-4">
      <button
          type="button"
          class="btn btn-primary w-100"
          (click)="closeModal(true)">
          Subscribe
      </button>
    </div>
</div>
