import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, take, filter } from 'rxjs/operators';
import { ErrorHandlerService } from './error-handler/error-handler.service';
import { environment } from '../../environments/environment';
import { LocalStorageService } from './local-storage.service';
import { RequestHandlerParams } from './types/api-service.types';
import { LOCALSTORAGE_KEYS } from './constants/databases';
import { Session } from './types/session.type';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    private http = inject(HttpClient);
    private errorHandlerService = inject(ErrorHandlerService);
    private localStorageService = inject(LocalStorageService);

    handleRequest<T>(params: RequestHandlerParams): Observable<T> {

        params.apiVersion1 = !!params.apiVersion1;
        params.showErrorMessage = params.showErrorMessage === undefined ? true : params.showErrorMessage;
        params.body = params.body || (['POST', 'PATCH', 'PUT'].includes(params.method) ? {} : null);

        const { method, endpoint, body, showErrorMessage, apiVersion1, apiV3, returnError, isVoid } = params;

        const apiUrl = this.getFullUrl(endpoint, apiVersion1, apiV3);
        const headers = this.setUpHeaders(endpoint);
        return this.http.request<T>(method, apiUrl, { body, headers })
            .pipe(
                catchError((error) => this.errorHandlerService.handleHttpError(error, !returnError, showErrorMessage, returnError)),
                filter((result): result is T => isVoid ? true : result !== null),
                take(1),
            );
    }

    private getFullUrl(endpoint: string, apiVersion1: boolean = false, apiV3: boolean = false): string {
        const api_url = apiVersion1 ? environment.apis.v1 : apiV3 ? environment.apis.v3 : environment.apis.v2;

        return `${api_url}${endpoint}`;
    }

    private setUpHeaders(ep?: string): HttpHeaders {
        const session: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION) || null
        const accountInfo = session?.accountInfo || null;

        return new HttpHeaders({
            'Content-Type': 'application/json',
            ...(accountInfo?.token ? { 'Authorization': `Bearer ${accountInfo?.token}` } : {})
        });
    }


}
