<div class="mt-4">
  <h5 class="text-center fw-normal">
    We are thrilled to have you on board! <br />
    Here's a quick reminder of your delivery information
  </h5>
  <section class="mt-4">
    <div class="d-flex bg-reminder mx-auto col-lg-6 col">
      <div class="px-3 py-2">
        <h4></h4>
        <i class="text-success-emphasis fas fa-truck fs-3"></i>
        <h4></h4>
      </div>
      <div class="p-2">
        <p class="card-text">
          <b
            >Delivery Day: {{ modalContentData.welcomeMessage?.deliveryDay }}</b
          >
          <br />
          Edit before {{ modalContentData.welcomeMessage?.cutoffDay }} at
          {{ modalContentData.welcomeMessage?.cutoffTime }}
        </p>
      </div>
    </div>
    @if (isLimitedUser()) {
      <div class="text-center mt-4">
        <p>
          Make sure you
          <a [routerLink]="['/settings/account/profile']" (click)="close()"
            >reset your password</a
          >
          on your profile settings
        </p>
      </div>
    }
  </section>
  <div class="form-row-action mt-4 mb-4">
    <button type="button" class="btn btn-primary" (click)="goTo()">
      {{ isLimitedUser() ? "Customize box" : "Start shopping" }}
    </button>
  </div>
</div>
