import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { formatCurrency } from '../utils/formatting';

@Directive({
  selector: '[appCurrencyFormat]',
  standalone: true,
})
export class CurrencyFormatDirective implements OnInit {

  constructor(private el: ElementRef) { }

  ngOnInit() {
    // Ejecutar la lógica de formateo al cargar la página
    this.onBlur(new Event('load'));
  } 

  @HostListener('blur', ['$event']) onBlur(event: Event) {
    if (!event.target) return
    const inputElement = event.target as HTMLInputElement;
    let value: string = inputElement.value.replace(/[^\d]/g, ''); // Eliminar caracteres no numéricos

    // Formatear el valor como moneda
    const formattedValue = formatCurrency(value);

    // Asignar el valor al input
    inputElement.value = formattedValue;

    // Emitir el evento de cambio
    // inputElement.dispatchEvent(new Event('input'));
  }

  @HostListener('input', ['$event']) onInput(event: Event) {
    if (!event.target) return
    const inputElement = event.target as HTMLInputElement;
    let value: string = inputElement.value.replace(/[^\d]/g, ''); // Eliminar caracteres no numéricos

    // Formatear el valor como moneda
    const formattedValue = formatCurrency(value);

    // Asignar el valor al input
    inputElement.value = formattedValue;
  }

}