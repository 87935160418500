<section class="d-flex flex-column gap-4">

  <!-- <div class="row px-4">
    <div [ngClass]="{'col-4 offset-8': !isMobile(), 'col-12': isMobile()}">
      <mat-form-field class="w-100 normalized-mat-control">
        <mat-label>Next Delivery</mat-label>
        <mat-select [(ngModel)]="nextDeliveryDate" (ngModelChange)="changeNextDeliveryGlobal($event)">
          @for (date of availableDates(); track $index) {
          <mat-option [value]="date.originalDate">
            {{ date.date }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  </div> -->

  @for (group of subscription(); track group) {
  <div class="subscription-group">

    <header class="row m-0 pb-2">

      <div class="col-{{isMobile() ? 6 : 8}} align-content-center">
        <h3 class="h4-bold text-primary m-0">
          {{group.frequency}}
        </h3>
      </div>
      <!--
      <div [ngClass]="{'col-4': !isMobile(), 'col-6': isMobile()}">
        <mat-form-field class="normalized-mat-control w-100">
          <mat-label>Next Delivery</mat-label>
          <mat-select [(ngModel)]="group.nextDeliveryDate"
            (valueChange)="changeNextDeliveryFrequency($event, group.subscriptions)">
            @for (date of availableDates(); track $index) {
            <mat-option [value]="date.originalDate">
              {{ date.date }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div> -->

    </header>

    <div>

      @for (subs of group.subscriptions; track subs) {
      <div class="row m-0 row-subscriptions">
        <div class="col-6">
          <span class="h6-semibold">
            {{subs.product.name}}
          </span>
        </div>
        @if (!isMobile()) {
        <div class="col-2">
          <div class="form-floating has-validation">
            <input type="text" class="form-control" readonly [value]="subs.product.quantity" placeholder="Quantity" />
            <label for="zipCode">Quantity</label>
          </div>
        </div>
        }
        <div [ngClass]="{'col-4': !isMobile(), 'col-6': isMobile()}">
          <mat-form-field class="w-100 normalized-mat-control m-0">
            <mat-label>Next Delivery</mat-label>
            <mat-select [formControl]="subs.newDeliveryDate" required [ngModel]="subs.nextDeliveryBeforePause"
              (ngModelChange)="changeNextDeliveryProduct(group)">
              @for (date of availableDates(); track $index) {
              <mat-option [value]="date.originalDate">
                {{ date.date }}
              </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      }

    </div>

  </div>
  }

</section>

<section class="d-flex justify-content-center gap-4 animated fadeInUp mt-4">

  <button type="button" class="btn btn-outline-primary" (click)="cerrar()">
    Cancel
  </button>

  <button type="button" class="btn btn-primary" (click)="resumeSubscription()">
    Resume Subscription
  </button>

</section>
