import { ANetResponse } from "../purchase/authorize/authorize.types";
import { MarketingReferral } from "./common.types";

interface AccountInfo {
    email: string;
    dietaryPreferences: ArrayOptionsItem[];
    firstName: string;
    lastName: string;
    noteDriver?: string;
    password: string;
    phone: string;
    birthday?: string;
}

interface Address {
    additional?: string;
    city?: string;
    latitude?: number;
    longitude?: number;
    stateCode?: string;
    street?: string;
    zip?: string;
    noteDriver?: string;
    zoneId?: number;
}

interface Referral {
    source: string;
    additionalInfo: string;
}

interface Settings {
    receiveNewsletter: boolean;
    receiveText: boolean;
    receiveTextDelivery: boolean;
    referral?: Referral;
    delivery: AccountDeliveryInfo
}

export interface AccountDeliveryInfo {
    type: number;
    dayId: number;
    timeWindowId?: number
}

interface Contact {
    firstName: string,
    lastName: string,
    email: string,
    phone: string
}

export interface NewAccountPayload {
    accountInfo: AccountInfo;
    address?: Address;
    settings: Settings;
    contact?: Contact;
    marketingReferralData?: MarketingReferral;
}


// DELIVERY OPTIONS
export interface DeliveryOptionsResponseItem extends ArrayOptionsItem {
    allowTimeWindow: boolean,
    timeSet: ArrayOptionsItem[];
    isThisWeek: boolean;
}

// ACCOUNT SETTINGS:
export interface ArrayOptionsItem {
    id: string | number | null;
    name: string;
}

// PICKUP OPTION
export interface PickUpOption {
    id: number;
    name: string | false;
    deliveryDays: DeliveryDay[];
    locationAddress: string;
    composedName?: string;
    additionalInfo?: string;
}

export interface DeliveryDay {
    id: number;
    name: string;
    deliveryWindow: string;
    cutOffDate: string;
}

export interface PeopleShoppingFor extends ArrayOptionsItem {
    img: string;
}

export interface AccountSettings {
    references: Reference[];
    dietaryPreferences: ArrayOptionsItem[];
    peopleShoppingFor?: PeopleShoppingFor[];
}

export interface Reference extends ArrayOptionsItem {
    hasAdditionalInfo: boolean;
}

export interface DetailedCard extends Card {
    securityCode?: string;
    expirationDate?: string;
}

interface BillingAddress {
    street?: string;
    additional?: string;
    city?: string;
    stateCode?: string;
    zip?: string;
}

export interface PaymentDataPayload {
    card: DetailedCard;
    billingAddress?: BillingAddress;
    opaqueData?: ANetResponse
}

interface Card {
    firstName: string
    lastName: string,
    number: string
}

export interface PaymentMethodResponse {
    card: Card,
    settings: {
        hasMembership: boolean;
        hasPaymentMethod: boolean;
        isSuspended: boolean;
        isLimited: boolean;
        requireAddress: boolean;
        requirePaymentMethod: boolean;
        firstLogin: boolean;
    }
}

export interface CustomerType extends ArrayOptionsItem {
    configuration?: {
        requireAddress: boolean;
        requirePaymentMethod: boolean;
    }
}


//#region Memebserhip permissions for eCommerce:
export enum PermissionNames {
    order = 'order',
    shop = 'shop',
    settings = 'settings',
    settingsFriednReferrals = 'settings.friendReferral',
    settingsProfile = 'settings.profile',
    settingsSubscription = 'settings.subscriptions',
    settingsDeliveries = 'settings.deliveries',
    settingsPaymentMethod = 'settings.paymentMethod',
    settingsMemebership = 'settings.membership',
    settingsOrderHistory = 'settings.orderHistory',
    settingsInvoiceHistory = 'settings.invoiceHistory',
};

export interface permission {
    allowed: boolean;
    eCommRoute: string;
}

export interface eCommercePermissions {
    pages: permission;
    home: permission;
    buyAgain: permission;
    favorites: permission;
    cart: permission;
    order: permission;
    shop: permission;
    settings: {
        friendReferral: permission;
        profile: permission;
        subscriptions: permission;
        deliveries: permission;
        paymentMethod: permission;
        membership: permission;
        orderHistory: permission;
        invoiceHistory: permission;
    }
}

export const normalizeECommercePermissionsResponse = (data?: any): eCommercePermissions => {
    return {
        pages: {
            allowed: true,
            eCommRoute: '/pages'
        },
        home: {
            allowed: true,
            eCommRoute: '/'
        },
        buyAgain: {
            allowed: data?.shop ?? false,
            eCommRoute: '/buy-again'
        },
        cart: {
            allowed: data?.shop ?? false,
            eCommRoute: '/cart'
        },
        favorites: {
            allowed: data?.shop ?? false,
            eCommRoute: '/favorites'
        },
        order: {
            allowed: data?.order ?? false,
            eCommRoute: '/order'
        },
        shop: {
            allowed: data?.shop ?? false,
            eCommRoute: '/shop'
        },
        settings: {
            friendReferral: {
                allowed: data?.settings?.friendReferral ?? false,
                eCommRoute: '/settings/account/friend-referrals'
            },
            profile: {
                allowed: data?.settings?.profile ?? false,
                eCommRoute: '/settings/account/profile'
            },
            subscriptions: {
                allowed: data?.settings?.subscriptions ?? false,
                eCommRoute: '/settings/account/subscriptions'
            },
            deliveries: {
                allowed: data?.settings?.deliveries ?? false,
                eCommRoute: '/settings/account/deliveries'
            },
            paymentMethod: {
                allowed: data?.settings?.paymentMethod ?? false,
                eCommRoute: '/settings/billing/payment-method'
            },
            membership: {
                allowed: data?.settings?.membership ?? false,
                eCommRoute: '/settings/billing/membership'
            },
            orderHistory: {
                allowed: data?.settings?.orderHistory ?? false,
                eCommRoute: '/settings/billing/order-and-billing'
            },
            invoiceHistory: {
                allowed: data?.settings?.invoiceHistory ?? false,
                eCommRoute: '/settings/billing/invoice-history'
            },
        }
    }
}
//#endregion