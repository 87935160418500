import { Component, Input, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ModalContentData } from '../modal-content';

@Component({
  selector: 'app-send-information',
  standalone: true,
  imports: [],
  templateUrl: './send-information.component.html',
  styleUrl: './send-information.component.scss'
})
export class SendInformationComponent {

  activeModal = inject(NgbActiveModal);
  router = inject(Router);

  @Input() modalContentData!: ModalContentData;

  closeModal() {
    this.activeModal.close(false);
  }
}
