import { Component, Input, inject } from '@angular/core';
import { ModalContentData } from '../modal-content';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-how-it-works',
  standalone: true,
  imports: [],
  templateUrl: './how-it-works.component.html',
  styleUrl: './how-it-works.component.scss'
})
export class HowItWorksComponent {

  activeModal = inject(NgbActiveModal);
  router = inject(Router);

  @Input() modalContentData!: ModalContentData;

  goTo(route?: string) {
    if (route)
      this.router.navigate([`/${route}`]);
    this.activeModal.close();
  }

}
