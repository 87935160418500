import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appMaskDate]',
    standalone: true
})
export class MaskDateDirective {
    constructor(private el: ElementRef) { }

    @HostListener('input', ['$event'])
    onInput(event: KeyboardEvent): void {
        const input = this.el.nativeElement as HTMLInputElement;
        let value = input.value.replace(/\D/g, ''); // Eliminar cualquier carácter no numérico

        if (value.length > 4) {
            value = value.slice(0, 6); // Limitar el valor a MMYYYY
        }

        if (value.length >= 3) {
            // Formato MM/YYYY
            value = `${value.slice(0, 2)}/${value.slice(2)}`;
        }

        input.value = value;
    }
}
