import { afterNextRender, ChangeDetectorRef, Component, inject, input, InputSignal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs';

@Component({
    selector: 'app-success-animated-icon',
    standalone: true,
    imports: [],
    templateUrl: './success-animated-icon.html',
    styleUrl: './success-animated-icon.scss'
})
export class SuccessAnimatedIcon {

    #cdr = inject(ChangeDetectorRef);

    isFinished: InputSignal<boolean> = input(false);
    #isFinished$ = toObservable(this.isFinished);
    isSuccess: InputSignal<boolean> = input(false);
    #isSuccess$ = toObservable(this.isSuccess);
    isError: InputSignal<boolean> = input(false);
    #isError$ = toObservable(this.isError);


    constructor() {
        afterNextRender(() => {
            this.#isFinished$.pipe(tap(res => this.#cdr.detectChanges()));
            this.#isSuccess$.pipe(tap(res => this.#cdr.detectChanges()));
            this.#isError$.pipe(tap(res => this.#cdr.detectChanges()));
        })
    }

}
