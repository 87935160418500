import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appMaskCardNumber]',
    standalone: true
})
export class MaskCardNumberDirective {
    constructor(private el: ElementRef) { }

    @HostListener('input', ['$event'])
    onInput(event: KeyboardEvent): void {
        const input = this.el.nativeElement as HTMLInputElement;

        // Eliminar cualquier carácter que no sea un número
        let value = input.value.replace(/\D/g, '');

        // Dividir en grupos de 4 dígitos, pero sin agregar un espacio al final
        const formattedValue = value.match(/.{1,4}/g)?.join('-') || '';

        // Actualizar el valor del input
        input.value = formattedValue;
    }
}
