/**
 *  This enumeration defines the different types of modifications that can be applied to a Bundle.
 */
export enum BundleModifyTypes {
  /**
   *  The default modification type: indicates that the modification is performed inside the standard order flow.
   */
  default = 'default',
  /**
   *  Indicates that the modification is performed outside of the standard order flow.
   */
  outOfOrder = 'outOfOrder',
}

/**
 *  This type alias is a shorthand for a member of the BundleModifyTypes enumeration.
 *  It allows you to use the enumeration values directly without needing to specify the
 *  enumeration itself.
 */
export type BundleModifyType = BundleModifyTypes.default | BundleModifyTypes.outOfOrder;

/**
 *  This enumeration defines the different ways to authorize purchases from the ecommerce.
 */
export enum PurchaseTypes {
  /**
   *  Authorize Net
   */
  anet = 'authorizeNet',
  /**
   *  NMI
   */
  nmi = 'nmi',
}

/**
 *  This type alias is a shorthand for a member of the PurchaseTypes enumeration.
 *  It allows you to use the enumeration values directly without needing to specify the
 *  enumeration itself.
 */
export type PurchaseType = PurchaseTypes.anet | PurchaseTypes.nmi;
