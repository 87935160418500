import { ModalContentService } from './../modal-content.service';
import { Component, computed, inject, input, Signal } from '@angular/core';
import { SignalsStoreService } from '../../signals-store.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Products } from './resume-pending-order.types';
import { CommonModule } from '@angular/common';
import { OrderService } from '../../order.service';
import { generateMapKeyFromValues, isANoDeliveryDayUser, isANoPaymentMethodUser, isNoAddressUser } from '../../common/utils';
import { LocalStorageService } from '../../local-storage.service';
import { Router } from '@angular/router';
import { DeliveryInformation } from '../../../settings/account/deliveries/intarfaces';
import { DeliveriesService } from '../../../settings/account/deliveries/deliveries.service';

@Component({
  selector: 'app-resume-pending-order',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './resume-pending-order.component.html',
  styleUrl: './resume-pending-order.component.scss'
})
export class ResumePendingOrderComponent {

  //#region Services

  #activeModal = inject(NgbActiveModal);

  #modal = inject(NgbModal);

  #signalsStoreService = inject(SignalsStoreService);

  #orderService = inject(OrderService);

  #localStorageService = inject(LocalStorageService);

  #modalContentService = inject(ModalContentService);

  #deliveriesService = inject(DeliveriesService);

  #router = inject(Router);

  //#endregion

  //#region Inputs / Outputs

  deliveryDate = input.required<string>();

  //#endregion

  //#region Properties

  products: Signal<any | null> = computed(() => this.#setUpProducts());

  odooOrder = computed(() => this.#getDefaultOdooOrder());

  firebaseOrder: Signal<any> = computed(() => this.#getDefaultFirebaseOrder());

  total = computed(() => this.#getTotal());

  deliveryFee = computed(() => +this.#calculateDeliveryFee());

  subTotal = computed(() => +this.#getSubTotal());

  taxes = computed(() => +this.getTaxes());

  tipAmount = computed(() => this.getTipAmount());

  donationAmountVoluntary = computed(() => this.getDonationVoluntaryAmount());

  shouldShowTipsAndDonations = computed(() => {
    const p = this.products();
    return p?.subscription?.length || p?.common?.length
  });

  creditsAmount = computed(() => +this.#getCredits());

  hasPendingChanges = computed(() => this.checkPendingChanges());

  isAllowedOrder = computed(() => this.#hasMinOrder());

  orderHasUnpublishedBundle = computed(() => {
    const products = this.products();
    const existsUnpublishedSubscriptionBundle = products?.subscription.some((p: any) => p?.bundle?.items && p.totalPrice === 0) || false;
    const existsUnpublishedAlaCarteBundle = products?.common.some((p: any) => p?.bundle?.items && p.totalPrice === 0) || false;
    return existsUnpublishedAlaCarteBundle || existsUnpublishedSubscriptionBundle;
  });

  deliveryInfo: Signal<any | null> = computed(() => this.#setUpDeliveryInfo());

  //#endregion

  //#region Methods

  #getDefaultOdooOrder() {

    const odooOrder = this.#orderService
      .odooOrder()
      .find(x => x.deliveryInfo.deliveryDate === this.deliveryDate());

    if (!odooOrder || !odooOrder?.products?.common || !odooOrder?.products?.subscription)
      return odooOrder

    odooOrder.products.common = this.#processProducts(odooOrder?.products?.common || []);
    odooOrder.products.subscription = this.#processProducts(odooOrder?.products?.subscription || []);

    return odooOrder
  }

  #processProducts(products: any[]): any[] {
    return products.map(product => {
      const totalPrice = this.#calculateProductTotalPrice(product);
      return { ...product, totalPrice };
    });
  }

  #getDefaultFirebaseOrder() {
    return this.#signalsStoreService
      .firebaseOrder()
      .find(x => x.deliveryDate === this.deliveryDate());
  }

  close() {
    this.#activeModal.close();
  }

  goToOrder() {
    this.#activeModal
      .close({ redirect: true });
  }

  handleImageError(event: Event) {
    (event.target as HTMLImageElement).src = 'assets/images/product-placeholder.png';
  }

  #getTotal() {
    const prevTotalAmount = this.subTotal() + this.deliveryFee() + this.taxes() + this.tipAmount() + this.donationAmountVoluntary()
    const totalAmount = (+prevTotalAmount - this.creditsAmount()) || 0

    return totalAmount >= 0 ? totalAmount : 0
  }

  #getSubTotal() {
    const allProducts = [
      ...this.products()?.common ?? [],
      ...this.products()?.subscription ?? []
    ]

    if (!allProducts.length)
      return 0.00
    // Calculate the subtotal of the unsaved products in the current order
    const subtotalFromCurrentOrder = allProducts.reduce((total: number, product: any) => {
      return total + this.#calculateProductTotalPrice(product);
    }, 0);
    const couponAmount = this.checkPendingChanges() ? 0 : this.odooOrder()?.paymentDetails?.coupons?.[0]?.amount || 0
    const subTotal = +subtotalFromCurrentOrder - (-couponAmount)
    return subTotal > 0 ? subTotal : 0;
  }

  #calculateProductTotalPrice(product: any): number {
    let totalPrice = 0;
    if (product?.bundle?.items) {
      if (product.bundle.isFixed) {
        totalPrice = +product.price;
        const premiumItems = product?.bundle?.items?.filter((pi: any) => pi.isPremiumAddon);
        if (premiumItems?.length) {
          totalPrice += premiumItems.reduce((total: number, bundleItem: any) => {
            const bundleItemPrice = (+bundleItem?.price * +bundleItem?.quantity) || 0
            return total + bundleItemPrice
          }, 0);
        }
      } else if (product?.bundle?.items?.length)
        totalPrice = product.bundle.items.reduce((total: number, bundleItem: any) => {
          const bundleItemPrice = (bundleItem?.isRemoved ? 0 : (+bundleItem?.price * +bundleItem?.quantity) || 0)
          return total + bundleItemPrice
        }, 0);
    }
    if (totalPrice === 0)
      totalPrice = product.price

    return totalPrice * product.quantity;
  }

  checkPendingChanges() {
    return !!(this.products() &&
      [...this.products()?.common, ...this.products()?.subscription].some(product => product.hasPendingChanges));
  }

  #calculateDeliveryFee(): number {
    // TODO If the client doesnt have a order created, with this logic , it will show FREE, and after the SUBMIT ORDER, then we have the specific amount.
    if (!this.odooOrder()?.paymentDetails)
      return 0

    const { amount, minSpend } = this.odooOrder()?.paymentDetails?.deliveryFee ?? {}

    if (this.subTotal() >= (minSpend ?? 0))
      return 0

    return amount ?? 0;
  }

  private getTaxes() {
    if (!this.products())
      return 0

    const allProducts = [...this.products().common, ...this.products().subscription]
    const totalTaxes = allProducts.reduce((total: number, product: any) => {
      let bundleTotalPriceWithoutTax = 0;
      let bundleTotalTax = 0;

      if (product?.bundle?.items?.length) {
        product.bundle.items.forEach((bundleItem: any) => {
          const itemPrice = (+bundleItem.price * +bundleItem.quantity);
          const itemTax = !bundleItem?.taxes ? 0 : (bundleItem.taxes?.reduce((total: number, item: any) =>
            total + +(item?.percentage)
            , 0) / 100)

          bundleTotalPriceWithoutTax += itemPrice;
          bundleTotalTax += (itemPrice * (itemTax));
        });
      }

      let productTaxPercent = 0

      if (!product?.bundle?.items?.length) {
        productTaxPercent = product.taxes?.reduce((total: number, item: any) =>
          total + +(item?.percentage)
          , 0) || 0
      }

      const productPrice = bundleTotalPriceWithoutTax
        ? bundleTotalPriceWithoutTax * +product.quantity
        : product.price
      const productTotalPrice = +productPrice * +product.quantity
      const productTaxAmount = (+productTaxPercent * +productTotalPrice / 100)
      const finalPrice = +(total + productTaxAmount + (bundleTotalTax * +product.quantity)).toFixed(2);

      return finalPrice;
    }, 0);

    return totalTaxes;
  }

  getTipAmount() {
    const odooTipAmount = this.odooOrder()?.paymentDetails?.tip?.amount || 0
    const firebaseTipAmount = this.firebaseOrder()?.paymentDetails?.tip?.amount || 0
    const showTipOrDonation = this.shouldShowTipsAndDonations();
    if (!showTipOrDonation) return 0
    return firebaseTipAmount || odooTipAmount || 0
  }

  getDonationVoluntaryAmount() {
    const odooDonationAmount = this.odooOrder()?.paymentDetails?.donation?.amount || 0
    const firebaseDonationAmount = this.firebaseOrder()?.paymentDetails?.donation?.amount || 0
    const showTipOrDonation = this.shouldShowTipsAndDonations();
    if (!showTipOrDonation) return 0
    return firebaseDonationAmount || odooDonationAmount || 0
  }

  #getCredits() {
    return this.odooOrder()?.paymentDetails?.credits || 0;
  }

  #hasMinOrder(): boolean {

    const couponAmount = (this.checkPendingChanges() ? 0 : this.odooOrder()?.paymentDetails?.coupons?.[0]?.amount || 0) * -1
    return ((this.subTotal() + couponAmount) >= (this.odooOrder()?.paymentDetails?.minOrder ?? 0)) || this.orderHasUnpublishedBundle();
  }

  updateOrder(odooOrderId?: number) {
    if (isANoDeliveryDayUser(this.#localStorageService, this.#modalContentService)) return;
    if (isANoPaymentMethodUser(this.#localStorageService, this.#modalContentService, this.#modal)) return;
    if (isNoAddressUser(this.#localStorageService, this.#modalContentService, this.#modal, this.#router)) return;

    this.#orderService
      .updateOrder({
        order: this.firebaseOrder(),
        firebaseOrderSignal: this.#signalsStoreService.firebaseOrder,
        orderId: odooOrderId,
        deliveryInfo: this.deliveryInfo()
      }, this.deliveryDate())
      .subscribe()

    this.close();
  }

  #setUpDeliveryInfo() {
    if (!this.#deliveriesService.deliveryZoneInfo()) return null

    const { deliveryWindow } = (this.#deliveriesService.deliveryZoneInfo()?.order || this.#deliveriesService.deliveryZoneInfo()) as DeliveryInformation;
    const deliveryPickupDateText = this.#signalsStoreService.isAddressRequired() ? 'Delivery Date: ' : 'Pick Up Date: '
    return {
      deliveryWindow,
      deliveryDateText: `${this.odooOrder()?.isSkipped ? 'Next ' : ''}${deliveryPickupDateText}${this.deliveryDate()}`,
      thanksMessage: this.#signalsStoreService.isAddressRequired() ? 'Get excited for your delivery, which will arrive on:' : 'Your order will be ready for pick-up on:'
    }
  }

  #setUpProducts(): any {

    const odooOrder = this.odooOrder();
    const firebaseOrder = this.firebaseOrder();

    const { common: odooCommonProducts = [], subscription: odooSubscriptionProducts = [] } = odooOrder?.products || {};
    const { common: fbCommonProducts = [], subscription: fbSubscriptionProducts = [] } = firebaseOrder?.products || {};

    const commonProducts = this.#combineArrays(fbCommonProducts, odooCommonProducts, false)
    const subscriptionProducts = this.#combineArrays(fbSubscriptionProducts, odooSubscriptionProducts, true)

    return {
      common: commonProducts.sort((a, b) => (b.updatedAt || 0) - (a.updatedAt || 0)),
      subscription: subscriptionProducts.sort((a, b) => (b.updatedAt || 0) - (a.updatedAt || 0)),
    }
  }

  #combineArrays(fbProducts: any[], odooProducts: any[], isSubscription: boolean): any[] {
    // Create a map of fbProducts based on ID for efficient lookup
    const fbProductsMap = new Map();
    fbProducts.forEach(product => {
      product.isSubscription = isSubscription
      let key = product.variant.id;
      if (product.package?.id) {
        key += `_${product.package.id}`;
      }
      fbProductsMap.set(key.toString(), product);
    });

    // Combine the products
    const combinedProducts: any[] = [];
    odooProducts.forEach(product => {
      product.isSubscription = isSubscription
      const variantId = product.variant.id;
      const packageId = product.package?.id || null;
      const fbMapKey = `${variantId}${packageId ? `_${packageId}` : ''}`;
      product['isInOdooOrder'] = true;
      const productData = {
        previousValue: product.quantity,
        quantity: product.quantity,
        validations: {
          isInputValid: false,
          isValueChanged: false,
          wasValueHigher10: product.quantity >= 10,
        },
      }
      const quantityChangesMapKey = generateMapKeyFromValues([fbMapKey, isSubscription]);
      product['firebaseMapKey'] = fbMapKey;
      if (fbProductsMap.has(fbMapKey)) {
        // If there's a match, take quantity from fbProducts
        const fbProduct = fbProductsMap.get(fbMapKey);
        product.quantity = fbProduct.quantity;
        productData.quantity = fbProduct.quantity;
        productData.validations.wasValueHigher10 = fbProduct.quantity >= 10
        product.bundle = fbProduct.bundle
        product.totalPrice = fbProduct.totalPrice
        product['hasPendingChanges'] = true;
        product.updatedAt = fbProduct.updatedAt;
        combinedProducts.push(product);
      } else {
        // If there's no match, take information from odooProducts
        product['hasPendingChanges'] = false;
        combinedProducts.push(product);
      }
    });

    // Add elements from fbProducts if includeFromFb is true
    fbProducts.forEach(product => {
      if (!odooProducts.some(odooProduct => product.package?.id && odooProduct.package?.id ? (product.package.id === odooProduct.package.id) : (odooProduct.variant.id === product.variant.id))) {
        product['hasPendingChanges'] = true;
        product['isInOdooOrder'] = false;

        const productData = {
          previousValue: product.quantity,
          quantity: product.quantity,
          validations: {
            isInputValid: false,
            isValueChanged: false,
            wasValueHigher10: product.quantity >= 10,
          },
        }
        const variantId = product.variant.id;
        const packageId = product.package?.id || null;
        const fbMapKey = `${variantId}${packageId ? `_${packageId}` : ''}`;
        product['firebaseMapKey'] = fbMapKey;
        combinedProducts.push(product);
      }
    });

    return combinedProducts;
  }

  //#endregion
}
