<div class="d-grid gap-2 col-8 mx-auto">
    <div class="col-md-auto">
        <h2 class="modal-title text-center">
            Oops!
        </h2>
    </div>
</div>

<div class="row row-cols-1 justify-content-md-center mt-3">
    <div class="col-12">
        <div class="form-check d-flex align-items-center justify-content-between mb-4">
            <p>Sorry, but you've exceeded the cut-off time for this order. Any modifications made won't be saved. Please, add those changes to your new order</p>
        </div>
    </div>
</div>

<div class="d-grid gap-2 col-4 mx-auto mt-2">
    <button 
      type="button"
      (click)="closeModal()"
      class="btn btn-primary d-flex gap-2 align-items-center justify-content-center">
      I understand
    </button>
</div>

<!-- <div class="row justify-content-md-center mt-4">
    <div class="col-4">
      <button
          type="button"
          class="btn btn-outline-secondary w-100"
          (click)="closeModal()">
          Cancel
      </button>
    </div>
    <div class="col-4">
      <button
          type="button"
          class="btn btn-primary w-100"
          (click)="execute()">
          Tip
      </button>
    </div>
</div> -->