<div class="d-grid gap-2 col-8 mx-auto">
    <div class="col-md-auto">
        <h2 class="modal-title text-center">
          Welcome!
        </h2>
    </div>
</div>

<div class="row row-cols-1 justify-content-md-center mt-3">
    <div class="col-12">
        <div class="form-check d-flex align-items-center justify-content-between mb-4">
            <p>Thanks for logging in. We need you to update your payment information in order to add items to your shopping cart. Please update your payment information by clicking the button below. Thank you for supporting our local producers!</p>
        </div>
    </div>
</div>

<div class="d-grid gap-2 col-4 mx-auto mt-2">
    <button
      type="button"
      (click)="goToPaymentMethod()"
      class="btn btn-primary d-flex gap-2 align-items-center justify-content-center">
      Update payment method
    </button>
</div>
