import { Pipe } from "@angular/core";

@Pipe({
  name: "phone",
  standalone: true
})
export class PhonePipe {
  transform(number: string) {
    let value = number.replaceAll('-', '').replaceAll(' ', '');
    if (value.toString().includes('+')) {
      value = value.substring(value.length - 10);
    }
    value = value.replace(/\D/g, '');
    value = value.substring(0, 10);
    return value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2 $3');
  }
}