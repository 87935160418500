<div class="row row-cols-1 mt-4 row-gap-3">
    @for (input of inputConfig; track $index) {
        <div class="col">
            <div class="form-floating has-validation">
                <input
                    class="form-control custom-validation-control"
                    [class.is-invalid]="input.isInvalid"
                    [class.is-valid]="!input.isInvalid && input.ngModel()"
                    [type]="!!email ? 'text': input.hidePassword() ? 'password' : 'text'"
                    placeholder="{{input.label}}"
                    [(ngModel)]="input.ngModel"
                    [disabled]="input.disabled"
                    (input)="!!!email ? validatePassword($event, input) : validateEmail($event, input)">
                <label>{{input.label}}</label>
                @if(!!!email ) {
                    @if (input.hidePassword()) {
                        <span class="material-symbols-outlined text-body-tertiary show-password-icon" (click)="togglePasswordVisibility(input)">
                            visibility
                        </span>
                    } @else {
                        <span class="material-symbols-outlined text-body-tertiary show-password-icon" (click)="togglePasswordVisibility(input)">
                            visibility_off
                        </span>
                    }
                    @if (input.ngModel()) {
                        <div class="row">
                            @for(validation of input.passwordValidations; track $index) {
                                <div
                                    class="col-6 d-flex gap-2 align-items-center"
                                    [ngClass]="{'text-danger': validation.error, 'text-success': !validation.error}">
                                    <i
                                        [ngClass]="{
                                            'fas': true,
                                            'fa-xmark': validation.error,
                                            'fa-check': !validation.error
                                        }">
                                    </i>
                                    {{validation.label}}
                                </div>
                            }
                        </div>
                    }
                }
                @if(!!email && input.ngModel() && input.isInvalid) {
                    <div class="row">
                        @for(validation of input.emailValidations; track $index) {
                            <div
                                class="col-6 d-flex gap-2 align-items-center"
                                [ngClass]="{'text-danger': validation.error, 'text-success': !validation.error}">
                                <i
                                    [ngClass]="{
                                        'fas': true,
                                        'fa-xmark': validation.error,
                                        'fa-check': !validation.error
                                    }">
                                </i>
                                {{validation.label}}
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    }
</div>

<div class="modal-action-row has-btn-2">
        <button class="btn btn-outline-primary" (click)="cancelChangesClickHandler()">
            CANCEL
        </button>
        <button class="btn btn-primary" (click)="confirmChangesClickHandler()">
            CONFIRM
        </button>
</div>
