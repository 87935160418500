<section class="d-flex">

  <div class="alert alert-warning w-auto d-flex gap-3">

    <span class="material-symbols-outlined text-primary">
      info
    </span>

    <div class="d-flex flex-column">

      <span>
        It is not possible to make changes to the delivery information because there are changes to submit in order to
        <span class="fw-bold">{{defaultDeliveryDate()}}</span>.
      </span>

      <div class="text-end">
        <a class="badge text-black" (click)="goToOrder()">
          Go to order
        </a>
        <a class="badge text-black" (click)="openModalDetailOrder()">
          Update/Submit Order
        </a>
      </div>

    </div>

  </div>

</section>