import { computed, Injectable, Signal, signal, WritableSignal } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {

    #isWaitingForAPIResponse: WritableSignal<boolean> = signal(false);
    isWaitingForAPIResponse: Signal<boolean> = computed(() => this.#isWaitingForAPIResponse());

    setIsWaitingForAPIResponse(waiting: boolean) {
        this.#isWaitingForAPIResponse.set(waiting);
    }

}
