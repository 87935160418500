import { Component, computed, inject, input, InputSignal } from '@angular/core';
import { ResolutionService } from '../resolution.service';

@Component({
  selector: 'app-empty-message',
  standalone: true,
  imports: [],
  template: `
    <div class="d-grid" [class.mt-4]="!mt0">
      <div class="alert alert-warning w-auto d-flex align-items-center gap-2 animated fadeIn flex-nowrap" role="alert"
          [class.w-max-content]="!isMobile() && !width100()">
          @if (showIcon()) {
            <i class="fa fa-info-circle text-primary"></i>
          }
          <div class="h7-regular text-wrap" [innerHTML]="message()">
          </div>
      </div>
  </div>`,
})
export class EmptyMessageComponent {

  #resolutionService = inject(ResolutionService);

  isMobile = computed(() => this.#resolutionService.isMobile());

  message: InputSignal<string> = input('No results found');
  width100: InputSignal<boolean> = input(false);
  mt0: InputSignal<boolean> = input(false);
  showIcon: InputSignal<boolean> = input(true);
}
