<div class="d-grid col-md-6 col-10 mx-auto ps-4 py-3 rounded-3 fw-medium text-primary">
    <mat-radio-group aria-label="Select a delivery date" formControlName="deliveryDate"
        [value]="availableDates()[0].originalDate" (change)="selectedDate.set($event.value)">
        @for (dates of availableDates(); track $index; let first = $first; let last = $last) {
        <section class="hstack flex-row gap-2">
            <div class="w-75">
                <span class="m-0">
                    {{dates.date}}
                </span>
            </div>
            <div class="w-25 text-end">
                <mat-radio-button [value]="dates.originalDate">
                </mat-radio-button>
            </div>
        </section>
        <hr class="ms-n4">
        }
    </mat-radio-group>
</div>
<div class="row mt-5">
    <div class="col col-md-6 d-grid">
        <button type="button" class="btn btn-outline-primary" (click)="closeModal(false)">Discard changes</button>
    </div>
    <div class="col col-md-6 d-grid">
        <button type="button" class="btn btn-primary" (click)="closeModal(true)">Confirm</button>
    </div>
</div>