@if (wasRequestTriggered()) {
<div class="animated-icon-container d-flex justify-content-center align-items-center">
    <app-success-animated-icon [isFinished]="isResponseFinished()" [isSuccess]="isResponseSucces()"
        [isError]="isResponseError()"></app-success-animated-icon>
</div>
@if (responseMessage()) {
<app-empty-message [message]="responseMessage()"></app-empty-message>
}
} @else {
<p class="h4-semibold mt-3">Your credit card</p>
<div class="row mt-2">
    @if(hasSession() && sessionCardNumber()) {
    <mat-radio-button class="example-radio-button" [checked]="true"
        [value]="sessionCardNumber()">{{formattedSessionCardNumber()}}</mat-radio-button>
    } @else {
    <form action="" [formGroup]="purchaseForm">
        <div class="row row-gap-4">
            <!-- First Name -->
            <div class="col-lg-6 col-md-12">
                <div class="form-floating has-validation">
                    <input class="form-control" [class.is-invalid]="validateErrors('firstName')" id="firstName"
                        placeholder formControlName="firstName" required>
                    <label for="firstName">First Name</label>
                    <div *ngIf="validateErrors('firstName')" class="invalid-feedback">
                        <i class="fas fa-xmark"></i>
                        {{validateErrors('firstName')}}
                    </div>
                </div>
            </div>
            <!-- Last Name -->
            <div class="col-lg-6 col-md-12">
                <div class="form-floating has-validation">
                    <input class="form-control" [class.is-invalid]="validateErrors('lastName')" id="lastName"
                        placeholder formControlName="lastName" required>
                    <label for="lastName">Last Name</label>
                    <div *ngIf="validateErrors('lastName')" class="invalid-feedback">
                        <i class="fas fa-xmark"></i>
                        {{validateErrors('lastName')}}
                    </div>
                </div>
            </div>
            <!-- Card Number -->
            <div class="col-12">
                <div class="form-floating has-validation">
                    <input class="form-control" [class.is-invalid]="validateErrors('cardNumber')" id="cardNumber"
                        placeholder formControlName="cardNumber" maxlength="25" appMaskCardNumber required>
                    <label for="cardNumber">Card Number</label>
                    <div *ngIf="validateErrors('cardNumber')" class="invalid-feedback">
                        <i class="fas fa-xmark"></i>
                        {{validateErrors('cardNumber')}}
                    </div>
                </div>
            </div>
            <!-- Card Expiration Date -->
            <div class="col-lg-6 col-md-12">
                <div class="form-floating has-validation">
                    <input class="form-control" [class.is-invalid]="validateErrors('expDate')" id="expDate" placeholder
                        formControlName="expDate" maxlength="7" appMaskDate required>
                    <label for="expDate">Expiration Date</label>
                    <div *ngIf="validateErrors('expDate')" class="invalid-feedback">
                        <i class="fas fa-xmark"></i>
                        {{validateErrors('expDate')}}
                    </div>
                </div>
            </div>
            <!-- Card Code -->
            <div class="col-lg-6 col-md-12">
                <div class="form-floating has-validation">
                    <input class="form-control" [class.is-invalid]="validateErrors('cardCode')" id="cardCode"
                        placeholder formControlName="cardCode" maxlength="4" required appOnlyNumber>
                    <label for="cardCode">Card Code</label>
                    <div *ngIf="validateErrors('cardCode')" class="invalid-feedback">
                        <i class="fas fa-xmark"></i>
                        {{validateErrors('cardCode')}}
                    </div>
                </div>
            </div>

        </div>
    </form>
    }
</div>

<div class="vstack mt-4 px-5">
    @if (hasSession() && sessionCardNumber()) {
    <hr>
    }
    <small>Summary</small>
    <p class="h6-semibold">{{purchaseReqAddFields()?.variantName}}</p>
    <div class="hstack mt-3">
        <section class="w-25">From:</section>
        <section>{{purchaseReqAddFields()?.senderName}}</section>
    </div>
    <div class="hstack mt-3">
        <section class="w-25">To:</section>
        <section>{{purchaseReqAddFields()?.recipientName}}</section>
    </div>

    <hr>
    <div class="d-grid mx-auto">
        <p class="h5-semibold">Total: {{(purchaseReqAddFields()?.variantPrice || 0) | currency }}</p>
    </div>
</div>

<div class="d-grid col-12 mt-4">
    <button class="btn btn-primary" (click)="confirmPurchase()">Purchase</button>
</div>
}

<div class="d-grid col-12 mt-4">
    <button class="btn btn-outline-secondary" (click)="closeModal()">{{isResponseFinished() ? 'Close' :
        'Cancel'}}</button>
</div>