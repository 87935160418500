import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyFormatDirective } from '../../directives/currency-format.directive';

@Component({
  selector: 'app-past-cut-off',
  standalone: true,
  imports: [MatRadioModule, CommonModule, FormsModule, MatFormField, MatLabel, CurrencyFormatDirective],
  templateUrl: './past-cut-off.component.html',
  styleUrl: './past-cut-off.component.scss',
})
export class PastCutOffComponent {
  private activeModal = inject(NgbActiveModal);

  // execute(){
  //   const isRecurrent = this.selectedOption() === 'RECURRENT'
  //   const amount = formatCurrencyToAmount((isRecurrent ? this.recurrentAmount : this.oneTimeAmount))
    
    
  // }
  
  closeModal() {
    this.activeModal.close({
      understoodClicked: true
    });
  }
}
