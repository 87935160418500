import { Injectable, TemplateRef, WritableSignal, signal } from '@angular/core';

export type NotificationTypes = 'success' | 'warning' | 'error' | 'info';

export interface Toast {
	text: string;
	type?: NotificationTypes
	delay?: number;
}

@Injectable({
	providedIn: 'root',
})
export class NotificationService {
	toasts: WritableSignal<any> = signal([])

	show(toast: Toast) {
		this.toasts.update((toasts: Toast[]) => !toast ? [toast] : [...toasts, toast]);
	}

	remove(toast: Toast) {
		this.toasts.update((toasts: Toast[]) => toasts.filter((t: Toast) => t !== toast));
	}

	clear() {
		this.toasts.set([]);
	}

}