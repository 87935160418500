import { Injectable, WritableSignal, inject, signal } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { Session } from './types/session.type';
import { filter, finalize, tap } from 'rxjs';
import { CartService } from './cart.service';
import { arrayToMapMultiKey } from './common/utils';
import { LOCALSTORAGE_KEYS, FIREBASE_COLLECTIONS } from './constants/databases';
import { OrderService } from './order.service';
import { FirebaseCrudService } from './firebase-crud.service';
import { Frequencies, MarketStatus } from './types/common.types';
import { Subscription } from '../settings/account/subscriptions/subscriptions.types';
import { SidebarCheckList } from './sidebar/sidebar.types';
import { ModalContentService } from './modal-content/modal-content.service';
import { ModalContentTypes } from './constants/modal-content-types';
import { DateTime } from 'luxon';
import { environment } from '../../environments/environment';
import { eCommercePermissions } from './types/account.types';
import { DeliveryInformation } from '../settings/account/deliveries/intarfaces';

@Injectable({
  providedIn: 'root'
})
export class SignalsStoreService {
  private cartService = inject(CartService);
  private firebaseCrudService = inject(FirebaseCrudService);
  private localStorageService = inject(LocalStorageService);
  private orderService = inject(OrderService);
  private modalContentService = inject(ModalContentService);

  anonymousUserAddress: WritableSignal<any> = signal(null);
  donationAmountSkippable: WritableSignal<number> = signal(0);
  donationVoluntary: WritableSignal<any | null> = signal(null);
  tip: WritableSignal<any | null> = signal(null);
  odooDonationAmountVoluntary: WritableSignal<number | null> = signal(null);
  odooTipAmount: WritableSignal<number | null> = signal(null);
  totalOrderAmount: WritableSignal<number> = signal(0);
  googleAddress: WritableSignal<any> = signal(null)
  hasSession: WritableSignal<any> = signal(false)
  hasSkippedWeekDelivery: WritableSignal<boolean> = signal(false);
  isContentLoaded: WritableSignal<any> = signal(false);
  isSessionLoaded: WritableSignal<any> = signal(false);
  isSidebarOpen: WritableSignal<any> = signal(true);
  sessionSignal: WritableSignal<Session | null> = signal(null);
  signUpStep: WritableSignal<any> = signal(0)
  selectedTerritory = signal<any>(null);
  selectedCategory: WritableSignal<{ id: number, name: string }> = signal({ id: -1, name: '' });
  firebaseOrder = signal<FirebaseOrder[]>([]);

  frequencies: WritableSignal<Frequencies[]> = signal([]);
  subscriptions: WritableSignal<Subscription[]> = signal([]);
  marketStatus: WritableSignal<MarketStatus> = signal({ isOpen: true, openingDay: '', openingHour: '' });
  filterByProducer: WritableSignal<any> = signal(null);

  showCartPreviewForBusiness = signal(false);
  showCartPreview: WritableSignal<boolean> = signal(false);

  isFiltering: WritableSignal<boolean> = signal(false);
  sidebarFilterChanged: WritableSignal<SidebarCheckList[]> = signal([])

  justAddedProduct: WritableSignal<any> = signal(null);

  shouldShowCategoriesBar: WritableSignal<boolean> = signal(true);

  isLimitedUser: WritableSignal<boolean> = signal(true);

  isAddressRequired: WritableSignal<boolean> = signal(true);

  permissions: WritableSignal<eCommercePermissions | null> = signal(null);

  isCustomBoxSignupFlow: WritableSignal<boolean> = signal(false);

  isShowingMobileFooter: WritableSignal<boolean> = signal(true);

  // This variable is set preventing for logo.svg caching in some browsers:
  // TODO: There is a better solution?
  logoVersionNumber: WritableSignal<number> = signal(0);

  // Products to remove from carousels for order and cart preview components:
  hiddenCarouselProducts: WritableSignal<number[]> = signal([]);
  hasGetFirebaseOrders = signal(false);

  constructor() {
    this.sessionSignal.set(this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION) || null)
    const hasSession = !!(this.sessionSignal())?.accountInfo?.id;
    this.hasSession.set(hasSession);
    this.isLimitedUser.set(!!(this.sessionSignal()?.settings.isLimited));

    // Require address:
    if (this.sessionSignal()?.settings.requireAddress === false)
      this.isAddressRequired.set(false)
    else
      this.isAddressRequired.set(true);


    this.isSessionLoaded.set(true);
    this.setUpAnonymousAddress();

    this.logoVersionNumber.set(environment.config.filesVersionNumber.logo);
  }

  private setUpAnonymousAddress() {
    const storedAnonymousAddress: any = this.localStorageService.get(LOCALSTORAGE_KEYS.ANONYMOUS_ADDRESS);
    if (!!(storedAnonymousAddress?.street)) {
      this.anonymousUserAddress.set(storedAnonymousAddress);
    }
  }

  getAndSyncCart(deliveryInformation: DeliveryInformation) {
    const sessionStored: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION) || null;
    if (!sessionStored?.accountInfo?.id) return

    const accountId = sessionStored.accountInfo.id.toString();

    this.firebaseCrudService
      .getById(FIREBASE_COLLECTIONS.ORDERS, accountId)
      .pipe(
        tap(() => this.hasGetFirebaseOrders.set(true)),
        filter((res) => !!res),
        tap((response: FirebaseResponse[]) => {

          const oldOrder = response
            .filter(x => x.orderProducts && !x.orderProducts?.deliveryDate);

          for (const old of oldOrder) {

            this.firebaseCrudService
              .delete(
                FIREBASE_COLLECTIONS.ORDERS,
                sessionStored.accountInfo.id.toString()
              );

            if (old.orderProducts && !old.orderProducts?.deliveryDate)
              old.orderProducts.deliveryDate = deliveryInformation.deliveryDate;

            this.firebaseCrudService
              .add(
                FIREBASE_COLLECTIONS.ORDERS,
                `${sessionStored.accountInfo.id}-${deliveryInformation.deliveryDate.replaceAll('-', '')}`,
                old
              );
          }
        }),
        tap((response: FirebaseResponse[]) => {

          const firebaseOrder: FirebaseOrder[] = [];
          const config = new Map<string, any>();

          for (const order of response) {

            if (this.validateExpiredFirebaseOrder(order)) {
              this.clearFBExpiredOrder();
              break;
            }

            if (!order.orderProducts)
              continue;

            // set the id order that exist in firebase by the delivery date.
            const deliveryDate = order.orderProducts.deliveryDate || deliveryInformation.deliveryDate;
            order.orderProducts.id = `${accountId}-${deliveryDate.replaceAll('-', '')}`;

            if (!order.orderProducts.deliveryDate)
              order.orderProducts.deliveryDate = deliveryDate;

            if (order.cutoffDate)
              order.orderProducts.cutoffDate = order.cutoffDate;

            firebaseOrder.push(order.orderProducts);

            // Adding the config by order.

            let orderProducts = null;
            // TODO changes and possible bug here related with addToOrder method
            if (order.orderProducts?.products?.common?.length || order.orderProducts?.products?.subscription?.length || order.orderProducts?.paymentDetails) {
              if (!order.orderProducts?.products?.common?.length) {
                order.orderProducts.products = {
                  ...order.orderProducts.products,
                  common: []
                }
              }

              if (!order.orderProducts?.products?.subscription?.length) {
                order.orderProducts.products = {
                  ...order.orderProducts.products,
                  subscription: []
                }
              }

              orderProducts = order.orderProducts;
            }

            config.set(deliveryDate, {
              notSavedCartEventCreated: !!order.eventCreated,
              notSavedOrder: orderProducts
            });
          }

          this.firebaseOrder.set(firebaseOrder);
          this.orderService.configByOrder.set(config);

          // // todo: validar
          // if (!res?.cartProducts)
          //   res.cartProducts = [];

          // const { cartProducts } = res
          // // const cartProductsMap: Map<any, any> = arrayToMap('variant.id', cartProducts);
          // const cartProductsMap: Map<any, any> = arrayToMapMultiKey(['variant.id', 'isASubscription'], cartProducts);
          // this.cartService.firebaseCartProducts.set(cartProducts);
          // this.cartService.productsSignal.set({ array: cartProducts, map: cartProductsMap });
          // this.localStorageService.set(LOCALSTORAGE_KEYS.CART, cartProducts);
        }),
        finalize(() => this.hasGetFirebaseOrders.set(true))
      ).subscribe();
  }

  private validateExpiredFirebaseOrder(res: FirebaseResponse): boolean {
    if (res.expired && res.cutoffDate) {
      if (res.orderProducts?.products?.common?.length || res.orderProducts?.products?.subscription?.length) {
        const cutoffDate = DateTime.fromMillis(res.cutoffDate).toUTC().toFormat('MM/dd/yyyy hh:mm a');
        const commonProductsLostList = this.setUpProductsLostList(res.orderProducts?.products?.common || [], 'A la carte');
        const subscriptionProductsLostList = this.setUpProductsLostList(res.orderProducts?.products?.subscription || [], 'Subscription');

        const productsLostContainer = subscriptionProductsLostList.length || commonProductsLostList.length ? `
        <ul class="list-group list-group-flush">
          ${subscriptionProductsLostList.join('')}
          ${commonProductsLostList.join('')}
        </ul>` : ''

        this.modalContentService.openModal(ModalContentTypes.CONFIRMATION, {
          title: 'Order Expired: Unsaved Changes Lost',
          textContent: `
        <span>
          Your order has expired because the cutoff date has passed (${cutoffDate}). Unfortunately, any unsaved changes have been lost. The following items have been removed from your delivery order shopping cart:
        </span>
        <div class="col-md-12 mt-4">
          ${productsLostContainer}
        </div>
        `,
          confirmButtonText: 'I understand',
          closeable: false
        });
      }

      return true;
    }

    return false;
  }

  private setUpProductsLostList(list: any[], type: string): string[] {
    return list.map(p => {
      return `
        <li class="list-group-item d-flex justify-content-between align-items-start  text-start">
          <div class="ms-2 me-auto">
            <div class="fw-medium">${p.name}</div>
            Quantity: ${p.quantity} - Price: $${p.price}
          </div>
          <span class="badge text-bg-primary rounded-pill align-self-center">${type}</span>
        </li>
        `
    })
  }

  private clearFBExpiredOrder(): void | null {
    const sessionStored: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION) || null;
    if (!sessionStored?.accountInfo?.id) return

    const updateData = { expired: false, cutoffDate: null, orderProducts: null, eventCreated: false }

    const firebasePayload = {
      collection: FIREBASE_COLLECTIONS.ORDERS,
      docId: sessionStored.accountInfo.id.toString(),
      updateData
    }

    this.firebaseCrudService.updateSubkeys(firebasePayload);
  }
}

export interface FirebaseResponse {
  cutoffDate?: number;
  expired?: boolean;
  eventCreated?: boolean;
  cartProducts?: any[];
  orderProducts?: FirebaseOrder;
}
export interface FirebaseOrder {
  products: {
    common: any[];
    subscription: any[];
  },
  paymentDetails?: {
    tip: {
      amount?: number;
      isRecurrent?: boolean;
      isDeleted?: boolean;
    };
    donation: {
      amount?: number;
      isRecurrent?: boolean;
      isDeleted?: boolean;
    };
  },
  deliveryDate: string,
  cutoffDate?: number,
  id: string
};
