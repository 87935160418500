import { Component, computed, inject, input, model, signal } from '@angular/core';
import { DeliveriesService } from '../../../settings/account/deliveries/deliveries.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOption, MatSelect } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderService } from '../../order.service';
import { finalize, tap } from 'rxjs';
import { InfoMessageComponent } from "../../info-message/info-message.component";
import { EmptyMessageComponent } from "../../empty-message/empty-message.component";

@Component({
  selector: 'app-change-delivery-date',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatSelect,
    MatOption,
    FormsModule,
    InfoMessageComponent,
    EmptyMessageComponent
  ],
  templateUrl: './change-delivery-date.component.html',
  styleUrl: './change-delivery-date.component.scss'
})
export class ChangeDeliveryDateComponent {

  //#region Services

  #activeModal = inject(NgbActiveModal);

  #orderService = inject(OrderService);

  #deliveriesService = inject(DeliveriesService);

  //#endregion

  //#region Inputs / Outputs

  deliveryDate = input.required<string>();

  orderId = input.required<number>();

  //#endregion

  //#region Properties

  deliveryDates = computed(() => this.#deliveriesService.availableDeliveryDates().filter(x => !x.isSkipped && x.originalDate !== this.deliveryDate()));

  date = computed(() => this.#getDeliveryDate());

  selectedDeliveryDate = model('');

  bundleProducts = signal<string[]>([]);

  requireConfirm = signal(false);

  verifying = signal(false);

  //#endregion

  //#region Methods

  confirm() {

    if (!this.selectedDeliveryDate())
      return;

    this.#activeModal
      .close({
        deliveryDate: this.selectedDeliveryDate()
      });
  }

  close() {
    this.#activeModal.close();
  }

  verifyIsRequireConfirmation(event: any) {

    // Clear the form
    this.requireConfirm.set(false);
    this.bundleProducts.set([]);

    if (!event || this.verifying())
      return;

    this.verifying.set(true);

    this.#orderService
      .verifyBundlesDeliveryDate(this.orderId(), event)
      .pipe(
        tap(({ data }) => {
          this.requireConfirm.set(data.toConfirm);
          this.bundleProducts.set(data.products);
        }),
        finalize(() => this.verifying.set(false))
      )
      .subscribe();
  }

  #getDeliveryDate() {

    const index = this.deliveryDates()
      .findIndex(x => x.originalDate === this.selectedDeliveryDate());

    if (index === -1)
      return '';

    return this.deliveryDates()[index].date;
  }

  //#endregion
}
