import { Component, input, InputSignal } from '@angular/core';

@Component({
  selector: 'app-nmi-purchase',
  standalone: true,
  imports: [],
  templateUrl: './nmi-purchase.component.html',
  styleUrl: './nmi-purchase.component.scss'
})
export class NmiPurchaseComponent {
  purchaseReqAddFields: InputSignal<any> = input<any>(null);
}
