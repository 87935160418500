import { Item } from "../types/common.types";

export interface SidebarCheckList extends ItemCheck {
    id: number | string;
    sublist?: SidebarCheckList[];
}

export interface Sidebar {
    categories?: ItemCheck[];
    subCategories?: ItemCheck[];
    producers?: ItemCheck[];
    dietRestrictions?: ItemCheck[];
}

export interface ItemCheck extends Item {
    checked?: boolean
    disabled?: boolean
}

export enum SidebarFilterTypes {
    categories = 'categories',
    subcategories = 'subcategories',
    producers = 'producers',
    dietRestrictions = 'dietrestrictions',
    tags = 'tags'
};
