import { Component, computed, inject, input, output } from '@angular/core';
import { SignalsStoreService } from '../../signals-store.service';
import { CardTypes } from '../product-card.types';

@Component({
  selector: 'app-out-of-stock',
  standalone: true,
  imports: [],
  templateUrl: './out-of-stock.component.html',
  styleUrl: './out-of-stock.component.scss'
})
export class OutOfStockComponent {

  //#region Services

  #signalsStoreService = inject(SignalsStoreService);

  //#endregion

  //#region Inputs / Outputs

  isOutOfStockPerQuantity = input.required<boolean>();

  isOutOfStock = input.required<boolean>();

  hasNotify = input.required<boolean>();

  isFromSummary = input.required<boolean>();

  outFlipCardType = output<CardTypes>();

  outModalCheckAddress = output();

  //#endregion

  //#region Properties

  hasSession = computed(() => this.#signalsStoreService.hasSession());

  //#endregion

  //#region Methods

  flipCardType() {
    this.outFlipCardType.emit(CardTypes.notify)
  }

  openModalCheckAddress() {
    this.outModalCheckAddress.emit();
  }

  //#endregion
}
